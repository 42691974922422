import React from "react";
import { Navigate, Outlet } from "react-router-dom";

interface PrivateRouteProps {
  redirectPath: string;
  children: React.ReactNode;
}

const ProtectedRoute = ({
  redirectPath,
  children,
}: PrivateRouteProps): JSX.Element | null => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  if (!user.token) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default ProtectedRoute;
