import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import Confirm from "./Confirm";
import AccountInfo from "./AccountInfo";
import Success from "./Success";
import P2PAmount from "./P2PAmount";
import {
  useInitiateOrderMutation,
  useCheckPendingOrderQuery,
  useLazySendP2POTPQuery
} from "../../features/api/walletSlice";
import ArrowRightYellow from "../../assets/icons/arrow-right-yellow.svg";
import ArrowRightGray from "../../assets/icons/wallet/arrow-right-gray.svg";
import { TableLoader } from "../reusables";
import { WithdrawVerifyEmail } from "./WithdrawInfo";

const P2P = () => {
  const navigate = useNavigate();
  const { id: userId } = useAppSelector((state) => state.auth);
  const [otp, setOtp] = useState<string>("");

  //inbound currency: This is the currecy the user receives
  //outbound currency: This is the currency the user sends
  const { selectedCurrency: inboundCurrency, outboundCurrency } =
    useAppSelector((state) => state.wallet);

  //Conditionally render withdrawal OTP
  const [withdrawalOTP, setWithdrawalOTP] = useState<boolean>(false);

  //Amount the user receives
  const [inBoundValue, setInboundValue] = useState<string>("");
  //Amount the user sends
  const [outBoundValue, setOutBoundValue] = useState<string>("");
  const [errMsg, setErr] = useState<string>("");
  //bank that the user is proposing to send from
  const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
  const [step, setStep] = useState<number>(1);
  //User's phone number
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [initateOrder, { data, isLoading: initiatigOrder }] =
    useInitiateOrderMutation();

  //Check if the user has a pending order
  const { data: pendingOrder, isLoading: loadingPendingOrder } =
    useCheckPendingOrderQuery(userId);

  const handleAmountConfirm = () => {
    setStep(2);
  };

  const [trigger] = useLazySendP2POTPQuery();

  const handleSubmit = () => {
    if(otp.length !== 6 ){
      setErr("Please enter the OTP that was sent to your email account");
    }else{
    setErr("");
    setWithdrawalOTP(false)
    let data = {
      payload: {
        inboundCurrencyId: inboundCurrency.id,
        outboundCurrencyId: outboundCurrency.id,
        inboundAmount: Number(inBoundValue),
        outboundAmount: Number(outBoundValue),
        bankRecordId: selectedBank?.id + "",
        phoneNumber: phoneNumber,
        otp: otp,
        pin: ""
      },
      orderType: "Buy",
      userId: userId,
    };
    initateOrder(data)
      .unwrap()
      .then((res) => {
        if (res.success) {
          setStep(3);
        } else {
          setErr(res.message);
        }
      })
      .catch((error) => setErr(error?.data?.message));
    }
  };

  const handleSuccess = () => {
    setStep(4);
  };

  useEffect(() => {
    if (!inboundCurrency?.name) {
      navigate("/home/wallet");
    }
  }, []);

  useEffect(() => {
    if (pendingOrder?.data.orderStatus === "ProcessingRequest") {
      setStep(5);
    }
  }, [pendingOrder]);

   // Send OTP
   const handleSendOTP = () => {
    setErr("");
    trigger({
      currencyId: outboundCurrency.id,
      channel: "XendBridge",
    });
    setWithdrawalOTP(true)
  };

  const handleOtp = (res: string) => {
    setOtp(res);
  };

  //Function to handle rendered page for p2p
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <P2PAmount
            handleAmountConfirm={handleAmountConfirm}
            setOutBoundValue={setOutBoundValue}
            outBoundValue={Number(outBoundValue)}
            inBoundValue={Number(inBoundValue)}
            setInboundValue={setInboundValue}
            setSelectedBank={setSelectedBank}
            selectedBank={selectedBank}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
            inboundCurrency={inboundCurrency}
            outboundCurrency={outboundCurrency}
            type={"FUND"}
          />
        );
      case 2:
        return (
          <Confirm
            handleSubmit={handleSendOTP}
            outBoundValue={Number(outBoundValue)}
            inBoundValue={Number(inBoundValue)}
            selectedBank={selectedBank}
            isLoading={initiatigOrder}
            type={"FUND"}
            errMsg={errMsg}
            inboundCurrency={inboundCurrency}
            outboundCurrency={outboundCurrency}
          />
        );
      case 3:
        return <AccountInfo handleSuccess={handleSuccess} data={data} />;
      case 4:
        return <Success data={data?.data as OrderResponse} type={"FUND"} />;
      case 5:
        return (
          <Success
            data={pendingOrder?.data?.orderResponse as OrderResponse}
            type={"FUND"}
          />
        );
      default:
        return;
    }
  };

  return (
    <section className="bg-clrBg dark:bg-clrDarkBg2 w-[91%] max-w-[36rem] mx-auto pb-6 overflow-y-auto">
      <div className="flex flex-wrap items-center my-2 md:justify-center md:px-8 md:my-5 gap-y-2 gap-x-3 md:gap-x-9">
        <div
          className="flex items-center justify-between cursor-pointer gap-x-3 md:gap-x-9"
          onClick={() => step > 1 && setStep(1)}
        >
          <h3 className="text-x10 font-semibold text-[#1a1a1a] dark:text-white">
            Amount
          </h3>
          <img
            src={step >= 1 ? ArrowRightYellow : ArrowRightGray}
            alt="more options"
            width={20}
            height={20}
          />
        </div>
        <div
          className="flex items-center justify-between cursor-pointer gap-x-3 md:gap-x-9"
          onClick={() => step > 2 && setStep(2)}
        >
          <h3
            className={`font-medium text-x10 ${
              step >= 2
                ? "text-black dark:text-white font-semibold"
                : "text-clrGray dark:text-clrPlaceholder"
            }`}
          >
            Confirm
          </h3>
          <img
            src={step >= 2 ? ArrowRightYellow : ArrowRightGray}
            alt="more options"
            width={20}
            height={20}
          />
        </div>

        <div
          className="flex items-center justify-between cursor-pointer gap-x-3 md:gap-x-9"
          onClick={() => step > 3 && setStep(3)}
        >
          <h3
            className={`font-medium text-x10 ${
              step >= 3
                ? "text-black dark:text-white font-semibold"
                : "text-clrGray dark:text-clrPlaceholder"
            }`}
          >
            Amount Information
          </h3>
          <img
            src={step >= 3 ? ArrowRightYellow : ArrowRightGray}
            alt="more options"
            width={20}
            height={20}
          />
        </div>
        <div className="flex items-center justify-between cursor-pointer gap-x-3 md:gap-x-9">
          <h3 className="font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
            Success
          </h3>
        </div>
      </div>
      {loadingPendingOrder ? <TableLoader /> : renderStep()}
      {withdrawalOTP && <WithdrawVerifyEmail 
      handleModal={()=>setWithdrawalOTP(false)} 
      handleContinue={handleSubmit} 
      handleChange={handleOtp} 
      errMsg={errMsg} 
      resendOtp={handleSendOTP} 
      loading={initiatigOrder} 
      />}
    </section>
  );
};

export default P2P;
