import SecondaryNav from "../reusables/SecondaryNav";

const ProfilePage = () => {
 
  return (
      <SecondaryNav
        paths={[
          { path: "/home/profile/info", title: "My Profile" },
          { path: "/home/profile/settings", title: "Settings" },
        ]}
      />
  );
};

export default ProfilePage;
