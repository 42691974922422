import { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { Button, ErrorMsg, ButtonOutline } from "../reusables";
import { rewardDuration, lockDuration } from "./data";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right-yellow.svg";
import { ReactComponent as WNTIcon } from "../../assets/icons/wallet/wallet-wicrypt-token-coin.svg";
import {
  useGetAprQuery,
  useGetEstimatedInterestMutation,
  useGetRewardBoosterDetailsQuery,
  useToggleRewardBoosterParticipationMutation,
} from "../../features/api/rewardSlice";
import { numberWithCommas } from "../../Helper";
import { ReactComponent as WelcomeBooster } from "../../assets/icons/boost-returns.svg";
import { Modal } from "../reusables/Modal";

//Opt in to boost reward
export const BoostReward: React.FC<BoosterProps> = ({
  modalRef,
  handleCloseModal,
  handleSubmit,
}) => {
  const { id: userId } = useAppSelector((state) => state.auth);
  const [isError, setError] = useState<string>("");
  const [isTermsAccepted, setTermsStatus] = useState<boolean>(false);
  const [duration, setDuration] = useState<string>("ThreeMonths");
  const [boostStep, setBoostStep] = useState<number>(1);
  const { data, isLoading: gettingApr } = useGetAprQuery(duration);
  const [boostReward, { isLoading }] =
    useToggleRewardBoosterParticipationMutation();

  //function to opt in and allow someone boot their reward
  const handleBoost = () => {
    boostReward({
      userId: userId,
      payload: {
        boosterStatus: "LockReward",
        lockDuration: duration,
      },
    })
      .unwrap()
      .then((res) => {
        if (res.success) {
          handleSubmit("opt_in");
        } else {
          setError(res.message);
        }
      })
      .catch((err) => {
        if (err) setError(err.data.message);
      });
  };

  return (
    <>
      {boostStep === 1 ? (
        <WelcomeBoost handleProceed={setBoostStep} />
      ) : boostStep === 2 ? (
        <BoostEstimator
          handleGoBack={setBoostStep}
          handleCloseModal={handleCloseModal}
        />
      ) : (
        <Modal
          close={true}
          title={"Boost Rewards"}
          description={""}
          goBack={false}
          handleCloseModal={handleCloseModal}
        >
          <>
            <div className="px-3 py-4 bg-white rounded dark:bg-clrDarkBg">
              <div className="mt-3 mb-5">
                <div className="duration">
                  <p className="mb-1 mr-auto font-medium text-x9 text-clrGray dark:text-clrPlaceholder">
                    Duration
                  </p>
                  <div className="flex flex-wrap gap-2">
                    {rewardDuration.map((each) => {
                      return (
                        <p
                          onClick={() => setDuration(each.value)}
                          className={`text-x9 font-medium cursor-pointer text-clrGray dark:text-clrPlaceholder bg-white dark:bg-clrDarkBg px-2.5 py-1 border-[1px] border-solid rounded-[4px] ${
                            each.value === duration
                              ? "border-clrYellow"
                              : "border-clrBorder2 dark:border-clrPlaceholder"
                          } `}
                        >
                          {each.text}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="mt-2.5">
                <p className="flex justify-between mb-2">
                  <span className="font-medium opacity-50 text-x9 text-clrGray dark:text-clrPlaceholder">
                    Best Deal Reward (APR)
                  </span>
                  <span className="text-x9 font-medium text-[#16AB5B] ">
                    {gettingApr ? "..." : data?.data[0].apr}% APR
                  </span>
                </p>
              </div>
              <div className="flex items-center mt-4 mb-2">
                <input
                  type="radio"
                  className="cursor-pointer"
                  name="agreement"
                  id=""
                  checked={isTermsAccepted}
                  onClick={() => setTermsStatus(!isTermsAccepted)}
                />
                <label
                  htmlFor="agreement"
                  className="ml-2 font-normal text-black cursor-pointer dark:text-clrPlaceholder text-x8"
                >
                  I have read and agreed to{" "}
                  <span className="text-clrYellow2">
                    <a
                      href="https://wicrypt.com/terms-condition.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Wicrypt User Agreement)
                    </a>
                  </span>
                </label>
              </div>

              <div className="p-2 mt-6 rounded-lg md:px-4 md:py-2 bg-clrBg2 dark:bg-clrDarkBg2">
                <p className="font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
                  Note: Your weekly future rewards will be locked for the
                  duration chosen in the reward period
                </p>
              </div>

              <div
                className="flex items-center bg-clrBg2 dark:bg-clrDarkBg2 rounded-lg p-2 mt-6 cursor-pointer border-[1px] border-solid border-"
                onClick={() => setBoostStep(2)}
              >
                <div className="mr-auto">
                  <p className="font-semibold text-x10 text-clrTextLightGray dark:text-clrPlaceholder">
                    Estimate Booster Returns
                  </p>
                </div>
                <ArrowRight height="12px" width="12px" />
              </div>
              <ErrorMsg errMsg={isError} />
            </div>
            <div className="md:w-[80%] mt-6 mx-auto ">
              <Button
                loading={isLoading}
                type="button"
                disabled={!isTermsAccepted}
                text="Confirm and Save"
                handleClick={handleBoost}
              />
            </div>
          </>
        </Modal>
      )}
    </>
  );
};

//Cancel boost reward
export const CancelBoost: React.FC<BoosterProps> = ({
  handleCloseModal,
  handleSubmit,
}) => {
  const { id: userId } = useAppSelector((state) => state.auth);
  const [isError, setError] = useState<string>("");

  //Get details for the current booster
  const { data: boosterDetail, isLoading } =
    useGetRewardBoosterDetailsQuery(userId);

  //opt out of boost reward
  const [boostReward, { isLoading: loadingCancelBoost }] =
    useToggleRewardBoosterParticipationMutation();

  //Handle Opt out of boosting reward
  const handleBoost = () => {
    let periodInMonths = boosterDetail?.data.lockPeriodInMonths;

    // Provide a default value in case periodInMonths is undefined
    const lockDurationValue = periodInMonths
      ? lockDuration[periodInMonths]
      : "ThreeMonths";

    boostReward({
      userId: userId,
      payload: {
        boosterStatus: "RemitRewardToWallet",
        lockDuration: lockDurationValue,
      },
    })
      .unwrap()
      .then((res) => {
        if (res.success) {
          handleSubmit("opt_out");
        } else {
          setError(res.message);
        }
      })
      .catch((err) => {
        if (err) setError(err.data.message);
      });
  };

  return (
    <Modal
      close={true}
      title={"Cancel Boost"}
      description={"Confirm action"}
      goBack={false}
      handleCloseModal={handleCloseModal}
    >
      <>
        <div className="px-4 py-8 bg-white rounded dark:bg-clrDarkBg">
          <h3 className="mb-8 text-xs font-semibold text-center text-clrTextBlue dark:text-white w-[50%] mx-auto">
            Are you sure you want to cancel the boost?
          </h3>
          <div className="mb-8 space-y-4">
            <p className="flex justify-between pb-3 border-b-[1px] border-solid border-[#d2cbcb66]">
              <span className="font-medium opacity-50 text-x9 text-clrGray dark:text-clrPlaceholder dark:opacity-100">
                Current Reward Return
              </span>
              <span className="text-x9 font-semibold text-[#16AB5B] ">
                {isLoading
                  ? "..."
                  : numberWithCommas(boosterDetail?.data.rewardReturn || 0)}
                {isLoading ? "..." : boosterDetail?.data.currency}
              </span>
            </p>
            <p className="flex justify-between pb-3 border-b-[1px] border-solid border-[#d2cbcb66]">
              <span className="font-medium opacity-50 text-x9 text-clrGray dark:text-clrPlaceholder dark:opacity-100">
                Interest Loss
              </span>
              <span className="text-x9 font-semibold text-[#DB5F5F]">
                {isLoading
                  ? "..."
                  : numberWithCommas(
                      boosterDetail?.data.interestLoss || 0
                    )}{" "}
                {isLoading ? "..." : boosterDetail?.data.currency}
              </span>
            </p>
            <p className="flex justify-between pb-2">
              <span className="font-medium opacity-50 text-x9 text-clrGray dark:text-clrPlaceholder dark:opacity-100">
                Lock Period
              </span>
              <span className="font-semibold text-x9 text-clrGray dark:text-clrPlaceholder dark:opacity-100">
                {isLoading ? "..." : boosterDetail?.data.daysLeft} days
                remaining
              </span>
            </p>
          </div>
          <div className="px-3 py-2 bg-[#25346A1A] rounded-lg dark:bg-clrDarkBg2">
            <p className="font-normal text-center text-x8 text-clrGray dark:text-clrPlaceholder">
              Note: Cancelling boost before lock duration may result to loss of
              all accrued profits
            </p>
          </div>
          <ErrorMsg errMsg={isError} />
        </div>
        <div className="md:w-[80%] mt-6 mx-auto ">
          <Button
            type="button"
            text="Cancel Now"
            handleClick={handleBoost}
            loading={loadingCancelBoost}
          />
        </div>
      </>
    </Modal>
  );
};

//Welcome boost modal
const WelcomeBoost = ({
  handleProceed,
}: {
  handleProceed: (step: number) => void;
}) => {
  return (
    <Modal
      close={false}
      title={""}
      description={""}
      goBack={false}
      handleCloseModal={() => {}}
    >
      <div className="flex flex-col items-center justify-center text-center dark:bg-clrDarkBg rounded-md">
        <WelcomeBooster className="mt-10 mb-6" />
        <h1 className="mb-2 text-base font-semibold text-black dark:text-white">
          {" "}
          Welcome to the <br /> Reward Booster
        </h1>
        <div className="mt-6">
          <p className="text-xs dark:text-clrPlaceholder text-clrGray">
            {" "}
            Lock-up your weekly rewards to increase <br /> interest within a
            period of time
          </p>
        </div>
        <div className="w-11/12 md:w-[80%] mt-20 mx-auto mb-4">
          <Button
            type="button"
            text="Proceed"
            handleClick={() => handleProceed(3)}
          />
        </div>
      </div>
    </Modal>
  );
};

interface BoosterEstimatorProps {
  handleGoBack: (step: number) => void;
  handleCloseModal: () => void;
}

//Booster estimator
const BoostEstimator: React.FC<BoosterEstimatorProps> = ({
  handleGoBack,
  handleCloseModal,
}) => {
  const [isError, setError] = useState<string>("");
  const [weeklyRewardAmount, setWeeklyReward] = useState<string>("");
  const [duration, setDuration] = useState<string>("ThreeMonths");
  const { data, isLoading: gettingApr } = useGetAprQuery(duration);
  const [
    boostEstimator,
    { data: estimatedRates, isLoading: gettingBoosterEstimator },
  ] = useGetEstimatedInterestMutation();

  //function to check the estimated interest from the reward amount
  const handleEstimateReward = () => {
    if (!weeklyRewardAmount) {
      setError("Please enter a valid amount");
    } else {
      setError("");
      boostEstimator({
        amount: Number(weeklyRewardAmount),
        duration: duration,
      });
    }
  };

  return (
    <Modal
      close={true}
      title={"Booster Estimator"}
      description={""}
      goBack={true}
      handleGoBack={() => handleGoBack(3)}
      handleCloseModal={handleCloseModal}
    >
      <>
        <div className="p-3 bg-white rounded dark:bg-clrDarkBg">
          <div className="mt-3 mb-5">
            <p className="mb-3 text-x10 text-clrGray dark:text-clrPlaceholder">
              The booster estimator gives you an estimate of interest on your
              rewards for the selected duration. Please note that the actual
              value may vary from this estimate because the overall APR and
              weekly rewards changes.
            </p>

            <div className="p-2 mb-3 rounded-lg md:px-4 md:py-2 bg-clrBg2 dark:bg-clrDarkBg2 relative">
              <h3 className="font-semibold text-left text-x9 text-clrTextBlue dark:text-white">
                Enter Average Weekly Reward Amount
              </h3>

              <input
                type="number"
                placeholder="0 WNT"
                value={weeklyRewardAmount}
                onChange={(e) => setWeeklyReward(e.target.value)}
                className="md:mr-auto font-semibold text-xs placeholder:text-xs text-clrGray bg-inherit outline-none w-[60%] dark:text-clrPlaceholder dark:placeholder:!text-clrPlaceholder"
              />
              <WNTIcon className="absolute right-0 top-1/4 h-10" />
            </div>

            <div className="duration">
              <p className="mb-1 mr-auto font-medium text-x9 text-clrGray dark:text-clrPlaceholder">
                Duration
              </p>
              <div className="flex flex-wrap gap-2">
                {rewardDuration.map((each) => {
                  return (
                    <p
                      onClick={() => {
                        setDuration(each.value);
                        setWeeklyReward("");
                      }}
                      className={`text-x9 font-medium cursor-pointer text-clrGray dark:text-clrPlaceholder bg-white dark:bg-clrDarkBg px-2.5 py-1 border-[1px] border-solid rounded-[4px] ${
                        each.value === duration
                          ? "border-clrYellow"
                          : "border-clrBorder2 dark:border-clrPlaceholder"
                      } `}
                    >
                      {each.text}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="mt-2.5">
            <p className="flex justify-between mb-2">
              <span className="font-medium opacity-50 text-x9 text-clrGray dark:text-clrPlaceholder">
                Best Deal Reward (APR)
              </span>
              <span className="text-x9 font-medium text-[#16AB5B] ">
                {gettingApr ? "..." : data?.data[0].apr}% APR
              </span>
            </p>
          </div>
          {estimatedRates && weeklyRewardAmount && (
            <div className="p-2 mb-3 rounded-lg md:px-4 md:py-2 bg-shadowGrey">
              <p className="flex justify-between pb-3 border-b-[1px] border-solid border-[#d2cbcb66]">
                <span className="font-medium opacity-50 text-x9 text-clrGray dark:text-clrPlaceholder">
                  Boost Interest :
                </span>
                <span className="font-bold text-x9 text-clrYellow2 ">
                  {numberWithCommas(
                    estimatedRates?.data.estimatedInterest || 0
                  )}{" "}
                  {estimatedRates?.data.currency}
                </span>
              </p>
              <p className="flex justify-between pb-3 mt-2">
                <div className="font-medium opacity-50 text-x9 text-clrGray dark:text-clrPlaceholder">
                  <p className="font-semibold">Total Amount : </p>
                  <p>This includes your locked rewards and boost Interest </p>
                </div>
                <span className="font-bold text-x9 text-clrYellow2 ">
                  {estimatedRates &&
                    numberWithCommas(
                      estimatedRates?.data.totalEstimatedReturns || 0
                    )}{" "}
                  {estimatedRates?.data.currency}
                </span>
              </p>
            </div>
          )}

          <ErrorMsg errMsg={isError} />
        </div>
        <div className="md:w-[80%] mt-6 mx-auto ">
          <Button
            loading={gettingBoosterEstimator}
            type="button"
            text="Calculate"
            handleClick={handleEstimateReward}
          />
        </div>
        {estimatedRates && weeklyRewardAmount && (
          <div className="md:w-[80%] mt-6 mx-auto ">
            <ButtonOutline
              type="button"
              text="Reset"
              handleClick={() => {
                setWeeklyReward("");
                setDuration("ThreeMonths");
              }}
            />
          </div>
        )}
      </>
    </Modal>
  );
};
