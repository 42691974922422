import React, { useState } from "react";
import { Card, CardTitle, ButtonAuth, ErrorMsg } from "../../reusables";
import AuthFrame from "../../reusables/AuthFrame";
import AuthCode from "react-auth-code-input";
import {
  useGenerateEmailOTPMutation,
  useVerifyEmailOTPMutation,
} from "../../../features/api/authSlice";

interface VerifyProps {
  email: string;
  handleBack: () => void;
  handleNext: (title?: string, value?: string) => void;
}

//Component to verify that user has received authentication otp
const Verify = ({ email, handleNext, handleBack }: VerifyProps) => {
  const [otpCode, setCode] = useState("");
  const [isError, setError] = useState("");

  const [verifyEmailOTP, { isLoading }] = useVerifyEmailOTPMutation();

  const [generateEmailOTP] = useGenerateEmailOTPMutation();

  //function to save the otp code sent
  const handleChange = (res: string) => {
    if (isError) {
      setError("");
    }
    setCode(res);
  };

  const handleResendOTP = () => {
    generateEmailOTP({ emailAddress: email });
  };

  const handleVerify = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (otpCode.length === 6) {
      verifyEmailOTP({ emailAddress: email, otp: otpCode })
        .unwrap()
        .then((res) => {
          if (res.success) {
            if (res.data.isOtpValid) {
              // Redirect to create pin page
              handleNext("otp", otpCode);
            } else {
              setError(res.data.message);
            }
          } else {
            setError(res.message);
          }
        })
        .catch((err) => setError(err?.message));
    } else {
      setError("OTP must be 6 digits long");
    }
  };

  return (
    <div className="flex">
      <Card>
        <div className="flex items-center flex-col mt-2 w-[300px] mx-auto mb-12 md:mb-8 h-full">
          <div className="text-center md:text-left">
            <CardTitle title="Verify Email" />
            <p className="text-clrText font-semibold text-[0.625rem] md:text-sm mb-12">
              Please enter the six digit pin we sent to:{" "}
              <span className="text-clrYellow">{email}</span>
            </p>
          </div>
          <div className="text-center">
            <label
              htmlFor="verify-otp"
              className="block text-[0.625rem] leading-3 text-clrTextBlue md:text-sm font-bold mb-4"
            >
              Enter code
            </label>
            <div className="mb-4">
              <AuthCode
                inputClassName="w-10 h-10 text-center text-lg font-semibold text-clrTextBlue border-clrTextBlue border-solid border-[1px] rounded-md bg-[#f5f5f5]"
                containerClassName="flex justify-center gap-3"
                ariaLabel="verify-otp"
                isPassword={true}
                autoFocus={true}
                length={6}
                allowedCharacters="numeric"
                onChange={handleChange}
              />
              <ErrorMsg errMsg={isError} />
            </div>
          </div>
          <div className="mt-6 mb-8 w-[80%]">
            <ButtonAuth
              type="submit"
              loading={isLoading}
              handleClick={handleVerify}
              text="Submit"
            />
          </div>
          <div className="mt-4 mb-10">
            <p className="text-xs font-normal text-clrGray">
              Didn't receive an OTP?
              <span
                className="underline mt-5 ml-2 text-[10px] font-normal text-clrTextBlue cursor-pointer"
                onClick={handleResendOTP}
              >
                Resend OTP?
              </span>
            </p>
          </div>
          <div className="mt-4 mb-10 cursor-pointer">
            <div onClick={handleBack}> ← Back </div>
          </div>
        </div>
      </Card>
      <AuthFrame />
    </div>
  );
};

export default Verify;
