import { createColumnHelper } from "@tanstack/react-table";
import { filesize } from "filesize";
import moment from "moment";
import { Button } from "../reusables";
import { useState } from "react";
import TokenDetails from "./TokenModals";

export type Token = {
  dataTokenOwner: { id: string; phoneNumber: string, email: string };
  code: string;
  bytesAllocated: number;
  bytesUsed: number;
  secondsAllocated: string;
  dataTokenUsageType: string;
  voucherStatus: string;
  viewMore: string;
};




const ViewMoreButton = ({row}: {row: Token}) => {
  const [selectedToken, setSelectedToken] = useState<Token | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        text="View More"
        type="button"
        handleClick={() => {
          setSelectedToken(row);
          setIsModalOpen(true);
        }}
        textSize="text-[0.5rem]"
        lineHeight="leading-[0]"
        wBtn="md:w-[70%]"
      />

      <TokenDetails
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedToken(null);
        }}
        tokenData={selectedToken}
      />
    </>
  );
};

export const defaultDataTrackerToken: Token[] = [
  {
    dataTokenOwner: { id: "hello", phoneNumber: "676", email: "@tan" },
    code: "string",
    bytesAllocated: 1,
    bytesUsed: 2,
    secondsAllocated: "string",
    dataTokenUsageType: "string",
    voucherStatus: "active",
    viewMore: "",
  },
];

const columnToken = createColumnHelper<Token>();

export const columnsToken = [
  columnToken.accessor("dataTokenOwner", {
    header: "Assigned To",
    cell: (val) => {
      const owner = val.getValue();
      return <p>{owner?.phoneNumber ? owner?.phoneNumber : owner?.email ? owner?.email : "Not Assigned"}</p>;
    },
  }),

  columnToken.accessor("code", {
    header: "Token",
  }),

  columnToken.accessor("bytesAllocated", {
    header: "Data Allocated",
    cell: (val) => {
      const bytesAllocated = val.getValue();
      return filesize(bytesAllocated || 0);
    },
  }),

  columnToken.accessor("bytesUsed", {
    header: "Data Used",
    cell: (val) => {
      const bytesUsed = val.getValue();
      return filesize(bytesUsed || 0);
    },
  }),

  columnToken.accessor("secondsAllocated", {
    header: "Validity Period",
    cell: (val) => {
      const validityPeriod = val.getValue();
      const duration = moment.duration(Number(validityPeriod) || 0, 'seconds');
      return <p>
      {Number(validityPeriod) === 0 ? "--" : duration.days() >31 ? (
        <span>Unlimited</span>
      ) : (
        <span>
          {duration.days() > 0 && (
            <span>
              {duration.days()} Day{duration.days() > 1 ? 's ' : ' '}
            </span>
          )}
          {duration.hours() > 0 && (
            <span>
              {duration.hours()} Hr{duration.hours() > 1 ? 's ' : ' '}
            </span>
          )}
        </span>
        
      )}
    </p>
    },
  }),

  columnToken.accessor("dataTokenUsageType", {
    header: "Usage Type",
  }),
  columnToken.accessor("voucherStatus", {
    header: "Status",
    cell: (info) => {
      const status = info.getValue();
      let color = "";
      if (status.toLowerCase() === "active") {
        color = "#00C087";
      } else if (status.toLowerCase() === "exhausted") {
        color = "#DB5F5F";
      } else if (status.toLowerCase() === "inactive") {
        color = "#CFA809";
      }

      return (
        <p
          style={{
            color,
            fontWeight: "600",
          }}
        >
          {status}
        </p>
      );
    },
  }),
  columnToken.accessor("viewMore", {
    header: "",
    cell: (info) => {
      const row = info.row.original;
      return (
        <ViewMoreButton row={row} />
      );
    },
  }),
];