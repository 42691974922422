import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { BoostReward, CancelBoost } from "./BoostReward";
import { Success } from "../reusables";


interface BoosterFlowProps {
    boosterOption: number;
    setIsBoosterOption: (option: number) => void;
}
//Component with the booster reward overview and modals for handling the booster details
const BoosterFlow: React.FC<BoosterFlowProps> = ({ boosterOption, setIsBoosterOption }) => {
    
    const [boostOption, setBoostOption] = useState<string>("");
    const navigate = useNavigate();
    const boosterRef = useRef<HTMLElement>(null);

    const handleCloseModal = () => {
        setIsBoosterOption(0);
    };

    const handleBoostReward = (type: string) => {
        setIsBoosterOption(3);
        setBoostOption(type);
    }

    const handleViewHistory = () => {
        navigate("/home/booster/history")
    }

    useOutsideClick(boosterRef, handleCloseModal);

    return (
        <>
            {boosterOption === 1 &&
                <BoostReward
                    modalRef={boosterRef}
                    handleCloseModal={handleCloseModal}
                    handleSubmit={handleBoostReward}
                />}
            {boosterOption === 2 &&
                <CancelBoost
                    modalRef={boosterRef}
                    handleCloseModal={handleCloseModal}
                    handleSubmit={handleBoostReward}
                />}
            {boosterOption === 3 &&
                <Success
                    modalRef={boosterRef}
                    handleButton={handleViewHistory}
                    buttonTitle={"View Boost Details"}
                    title={boostOption === "opt_in" ? "Boost Activated" : "Boost Deactivated"}
                    description={`You've successfully ${boostOption === "opt_in" ? "activated " : "deactivated "} the boost! View the details of your reward boost below`}
                />}
        </>
    );
};

export default BoosterFlow;

