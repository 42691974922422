import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { Link } from "react-router-dom";
import { Button } from "../reusables";

type History = {
  id: string;
  lockUpStartDateTimestamp: number;
  lockedReward: number;
  type: string;
  interestEarned: number;
  currency: string;
  lockPeriodInMonths: number;
  status: string;
};

const boosterHistory = createColumnHelper<History>();

export const boosterHistoryColumn = [
  boosterHistory.accessor("lockUpStartDateTimestamp", {
    header: "Time",
    cell: (val) => {
      const time = val.getValue();
      return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
    },
  }),

  boosterHistory.accessor("lockedReward", {
    header: "Locked Reward",
    cell: ({ row }) => {
      return (
        <p> {row?.original.lockedReward + " " + row?.original.currency}</p>
      );
    },
  }),
  boosterHistory.accessor("interestEarned", {
    header: "Boost Reward",
    cell: ({ row }) => {
      return (
        <p> {row?.original.interestEarned + " " + row?.original.currency}</p>
      );
    },
  }),
  boosterHistory.accessor("lockPeriodInMonths", {
    header: "Lock Duration",
    cell: (val) => {
      const duration = val.getValue();
      return <p> {duration + " Months"}</p>;
    },
  }),
  boosterHistory.accessor("status", {
    header: "Status",
    cell: (info) => {
      const status = info.getValue();
      let color = "";
      if (status.toLowerCase() === "active") {
        color = "#00C087";
      } else if (status.toLowerCase() === "closed") {
        color = "#DB5F5F";
      }

      return (
        <p
          style={{
            color,
          }}
          className=""
        >
          {status}
        </p>
      );
    },
  }),
  boosterHistory.accessor("id", {
    header: "",
    cell: (val) => {
      const cycleId = val.getValue();
      return (
        <Link to={`/home/booster/history/${cycleId}`}>
          <Button
            text={"View Reward History"}
            type={"button"}
            handleClick={() => console.log("handle click")}
            textSize={"text-[0.5rem]"}
            lineHeight={"leading-[0]"}
          />
        </Link>
      );
    },
  }),
];

export const boosterFaq = [
  {
    id: 1,
    isView: true,
    question: "What is Reward Booster?",
    answer:
      "Introducing the long-awaited Reward Booster update! Now, hub owners can lock up their earned rewards for a chosen period, generating high-yielding interest returns.",
  },
  {
    id: 2,
    isView: true,
    question: "When can i redeem my Reward?",
    answer:
      "After a exceeding a selected lock period rewards are released to the reward balance",
  },
  {
    id: 3,
    isView: true,
    question: "What are the advantages of the  Reward Booster",
    answer: "50% for 3months, 100% for 6months, 150% for 12months.",
  },
  {
    id: 4,
    isView: true,
    question: "Can i cancel the Reward booster?",
    answer:
      "Yes you can! but note that you will loose a certain percentage interest gained during the lock period",
  },
];

export const rewardDuration = [
  { id: 1, value: "ThreeMonths", text: "3 Months" },
  { id: 2, value: "SixMonths", text: "6 Months" },
  { id: 3, value: "TwelveMonths", text: "12 Months" },
];

interface LockDuration {
  [key: number]: string;
}

export const lockDuration: LockDuration = {
  3: "ThreeMonths",
  6: "SixMonths",
  12: "TwelveMonths",
};

type RewardHistory = {
  createdAtTimestamp: string;
  interestEarned: number;
  currency: string;
};

const rewardHistory = createColumnHelper<RewardHistory>();

export const rewardHistoryColumn = [
  rewardHistory.accessor("createdAtTimestamp", {
    header: "Time",
    cell: (val) => {
      const time = val.getValue();
      return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
    },
  }),

  rewardHistory.accessor("createdAtTimestamp", {
    header: "Type",
    cell: () => {
      return <p> Weekly Reward</p>;
    },
  }),
  rewardHistory.accessor("interestEarned", {
    header: "Boost Reward",
    cell: ({ row }) => {
      return (
        <p> {row?.original.interestEarned + " " + row?.original.currency}</p>
      );
    },
  }),
];
