import { useState } from "react";
import { Button, ErrorMsg } from "../reusables";
import SuccessIcon from "../../assets/icons/wallet/success.svg";
import StakeRewardIcon from "../../assets/icons/wallet/stakereward.svg";
import FailedIcon from "../../assets/icons/wallet/failed.svg";
import WNTIcon from "../../assets/icons/wallet/wallet-wicrypt-token-coin.svg";
import InfoIcon from "../../assets/icons/wallet/stake-info-circle.svg";
import { useGetForceUnlockDetailsQuery } from "../../features/api/stakingSlice";

import { numberWithCommas } from "../../Helper";
import { Modal } from "../reusables/Modal";
import { stakeDuration } from "./data";
import { Link } from "react-router-dom";

export const StakeLockedFlexible: React.FC<StakeProps> = ({
  handleModal,
  handleSuccessModal,
  title,
  balance,
  isLoading,
  apy,
  error,
}) => {
  const [isFlexible, setIsFlexible] = useState<boolean>(true);
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [amtToStake, setAmtToStake] = useState<string>("");
  const [errMsg, setErr] = useState<string>("");
  const [durationToStake, setDuration] = useState<number>(1);

  // Modal to open more info
  const [moreInfo, setMoreInfo] = useState<boolean>(false);

  const handleSubmit = () => {
    if (!amtToStake) {
      setErr("Please enter an amount to stake");
    } else if (Number(amtToStake) > (balance?.balance as number)) {
      setErr("Amount to stake cannot be more than your WNT balance");
    } else if (!isAgree) {
      setErr("Please accept Wicrypt's user agreement to proceed");
    } else {
      setErr("");
      let data = {
        amount: Number(amtToStake),
        duration: durationToStake,
      };
      handleSuccessModal(data);
    }
  };

  return (
    <Modal
      close={true}
      title={title}
      description={"Input desired amount"}
      handleCloseModal={handleModal}
    >
      <>
        <div className="px-3 py-4 bg-white rounded dark:bg-clrDarkBg">
          <div className="p-2 rounded-lg md:px-4 md:py-2 bg-clrBg2 dark:bg-clrDarkBg2">
            <h3 className="mb-2 font-semibold text-left text-x9 text-clrTextBlue dark:text-white">
              WNT Balance
            </h3>

            <p className="mb-2 mr-auto font-medium text-x10 text-clrGray opacity-70 dark:text-clrPlaceholder dark:opacity-100">
              {numberWithCommas(balance?.balance || 0)} WNT
            </p>
          </div>
          <div className="mt-3">
            <div className="mb-2.5">
              <p className="mb-1 mr-auto font-medium text-x9 text-clrGray dark:text-clrPlaceholder">
                Type
              </p>
              <div className="flex gap-2">
                <p
                  className={`text-x9 font-medium text-clrGray dark:text-clrPlaceholder bg-white dark:bg-inherit dark:border-clrGray px-2.5 py-1 border-[1px] border-solid border-clrBorder2 rounded-[4px] cursor-pointer ${
                    isFlexible ? "border-clrYellow" : ""
                  }`}
                  onClick={() => {
                    setIsFlexible(true);
                    setDuration(1);
                  }}
                >
                  Flexible
                </p>
                <p
                  className={`text-x9 font-medium text-clrGray dark:text-clrPlaceholder bg-white dark:bg-inherit dark:border-clrGray px-2.5 py-1 border-[1px] border-solid border-clrBorder2 rounded-[4px] cursor-pointer ${
                    !isFlexible ? "border-clrYellow" : ""
                  }`}
                  onClick={() => {
                    setIsFlexible(false);
                    setDuration(2);
                  }}
                >
                  Locked
                </p>
                <button
                  onClick={() => {
                    setMoreInfo(true);
                  }}
                >
                  <img
                    src={InfoIcon}
                    alt="More information"
                    width={15}
                    height={15}
                  />
                </button>
              </div>
            </div>
            {!isFlexible && (
              <div className="duration">
                <p className="mb-1 mr-auto font-medium text-x9 text-clrGray dark:text-clrPlaceholder">
                  Duration
                </p>
                <div className="flex flex-wrap gap-2">
                  {stakeDuration.map((duration) => {
                    return (
                      <p
                        key={duration.id}
                        onClick={() => setDuration(duration.value)}
                        className={` ${
                          duration.value === durationToStake
                            ? "border-clrYellow"
                            : ""
                        } cursor-pointer text-x9 font-medium text-clrGray dark:text-clrPlaceholder bg-white dark:bg-inherit dark:border-clrGray px-2.5 py-1 border-[1px] border-solid border-clrBorder2 rounded-[4px]`}
                      >
                        {duration.text}
                      </p>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="p-2 mt-6 rounded-lg md:px-4 md:py-2 bg-clrBg2 dark:bg-clrDarkBg2">
            <h3 className="mb-2 font-semibold text-left text-x9 text-clrTextBlue dark:font-medium dark:text-white">
              Amount
            </h3>
            <div className="flex items-center justify-between gap-2">
              <input
                type="number"
                placeholder=""
                onChange={(e) => setAmtToStake(e.target.value)}
                value={amtToStake}
                className="md:mr-auto text-xs placeholder:text-xs dark:placeholder:!text-clrPlaceholder dark:text-clrPlaceholder font-medium text-clrGray bg-inherit outline-none w-[60%]"
              />
              <p
                onClick={() =>
                  setAmtToStake(balance?.balance.toString() as string)
                }
                className="cursor-pointer text-x8 font-semibold text-clrTextGray dark:text-clrPlaceholder dark:border-clrGray dark:bg-clrDarkBg bg-white px-1 border-[1px] border-solid border-clrBorder2 rounded-md"
              >
                Max
              </p>

              <div className="w-[15px] h-[15px] rounded-[50%] bg-white flex justify-center items-center">
                <img src={WNTIcon} alt="WNT" width={15} height={15} />
              </div>
              <p className="font-medium text-x10 text-clrGray opacity-70 dark:text-clrPlaceholder">
                WNT
              </p>
            </div>
          </div>
          <div className="mt-2.5">
            <p className="flex justify-between mb-2">
              <span className="font-medium opacity-50 text-x9 text-clrGray dark:text-clrPlaceholder">
                Best Deal Reward (APR)
              </span>
              <span className="text-x9 font-medium text-[#16AB5B] ">
                {apy} APR
              </span>
            </p>
            {/* <p className="flex justify-between">
              <span className="font-medium opacity-50 text-x9 text-clrGray">
                Estimated Interest
              </span>
              <span className="text-x9 font-medium text-[#16AB5B]">
                0.0000000 WNT
              </span>
            </p> */}
          </div>

          <div className="flex items-center mt-4 mb-2">
            <input
              type="radio"
              name="agreement"
              id="agreement"
              checked={isAgree}
              onChange={() => setIsAgree(!isAgree)}
            />
            <label
              htmlFor="agreement"
              className="ml-2 font-normal text-black text-x8 dark:text-clrPlaceholder"
            >
              I have read and agreed to{" "}
              <a
                href="https://wicrypt.com/terms-condition.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-clrYellow2">
                  (Wicrypt User Agreement)
                </span>
              </a>
            </label>
          </div>
          <ErrorMsg errMsg={errMsg || error || ""} />
        </div>
        <div className="md:w-[80%] mt-6 mx-auto ">
          <Button
            loading={isLoading}
            type="button"
            text="Stake"
            handleClick={handleSubmit}
          />
        </div>
        {moreInfo && (
          <StakeFlexibleLockedInfo handleModal={() => setMoreInfo(false)} />
        )}
      </>
    </Modal>
  );
};

export const StakeSuccess: React.FC<StakeSuccessProps> = ({
  handleModal,
  title,
  description,
}) => {
  return (
    <Modal
      close={false}
      title={""}
      description={""}
      handleCloseModal={handleModal}
    >
      <div>
        <div className="flex flex-col items-center justify-center gap-4 px-3 py-8 mb-8 bg-white rounded dark:bg-clrDarkBg">
          <img
            src={SuccessIcon}
            alt="Success"
            width={100}
            height={100}
            className="w-20 h-20 md:w-24 md:h-24"
          />
          <div className="mb-4 text-center">
            <h2 className="mb-3 text-sm font-semibold text-black dark:text-white md:text-base">
              {title}
            </h2>
            <p className="text-x9 md:text-x10 font-medium text-clrGray66 dark:text-clrPlaceholder w-[90%] md:w-[60%] mx-auto">
              {description}{" "}
            </p>
          </div>
        </div>

        <div className="md:w-[80%] mt-6 mx-auto ">
          <Link to="/home/wallet">
            <Button type="button" text="View Balance" handleClick={() => {}} />
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export const StakeFailed: React.FC<StakeProps> = ({ handleModal }) => {
  return (
    <Modal
      close={false}
      title={""}
      description={""}
      handleCloseModal={handleModal}
    >
      <div>
        <div className="flex flex-col items-center justify-center gap-3 px-3 py-8 space-y-3 bg-white rounded dark:bg-clrDarkBg ">
          <img src={FailedIcon} alt="Success" width={150} height={150} />
          <div className="mb-5 text-center">
            <h2 className="mb-3 text-base font-semibold text-clrGreen2 dark:text-white">
              Insufficient Amount
            </h2>
            <p className="text-[10px] font-medium text-clrGray66 w-1/2 dark:text-clrPlaceholder">
              oops, you were unable to stake due to your insufficient wallet
              balance{" "}
            </p>
          </div>
        </div>

        <div className="w-[80%] mt-6 mx-auto ">
          <Button
            type="button"
            text="Fund my Wallet"
            handleClick={handleModal}
          />
        </div>
      </div>
    </Modal>
  );
};

export const StakeRewardClaim: React.FC<RewardClaimProps> = ({
  handleModal,
  amtToCaim,
  handleClaim,
  isLoading,
  errMsg
}) => {
  return (
    <Modal
      close={false}
      title={""}
      description={""}
      handleCloseModal={handleModal}
    >
      <div>
        <div className="flex flex-col items-center justify-center gap-4 px-3 py-8 mb-8 bg-white rounded dark:bg-clrDarkBg">
          <img src={StakeRewardIcon} alt="Success" width={110} height={110} />
          <div className="mb-4 text-center">
            <h2 className="mb-3 text-sm font-semibold text-black dark:text-white md:text-base">
              You are claiming {amtToCaim} WNT
            </h2>
            <p className="text-x9 md:text-x10 font-medium text-clrGray66 dark:text-clrPlaceholder w-[90%] md:w-[60%] mx-auto">
              You will not be able to claim rewards within 24 hours if you do
              this.{" "}
            </p>
          </div>
        </div>
        <ErrorMsg errMsg={errMsg || ""} />
        <div className="md:w-[80%] mt-6 mx-auto ">
          <Button
            type="button"
            loading={isLoading}
            text="Claim"
            handleClick={handleClaim}
          />
        </div>
      </div>
    </Modal>
  );
};

export const Unstake: React.FC<UnStakeProps> = ({
  handleModal,
  amtToUnstake,
  handleSubmit,
  isLoading,
  errMsg,
  title,
  description,
}) => {
  //Get Details for unstake
  const { data: unlockDetails } = useGetForceUnlockDetailsQuery();

  return (
    <Modal
      close={true}
      title={title}
      description={description}
      handleCloseModal={handleModal}
    >
      <div>
        <div className="px-4 py-8 bg-white rounded dark:bg-clrDarkBg">
          <h3 className="mb-8 text-xs font-semibold text-center text-clrTextBlue dark:font-medium dark:text-white w-[50%] mx-auto">
            Are you sure you want to {title}?
          </h3>
          <div className="mb-8 space-y-4">
            <p className="flex justify-between pb-3 border-b-[1px] border-solid border-[#d2cbcb66] dark:border-clrGray">
              <span className="font-medium opacity-50 text-x9 text-clrGray dark:text-clrPlaceholder">
                Current Unstake Return
              </span>
              <span className="text-x9 font-semibold text-[#16AB5B] ">
                {unlockDetails?.data?.deposit || amtToUnstake || 0} WNT
              </span>
            </p>
            <p className="flex justify-between pb-3 border-b-[1px] border-solid border-[#d2cbcb66] dark:border-clrGray">
              <span className="font-medium opacity-50 text-x9 text-clrGray dark:text-clrPlaceholder">
                Interest Loss
              </span>
              <span className="text-x9 font-semibold text-[#DB5F5F]">
                {unlockDetails?.data?.estimatedLoss || 0} WNT
              </span>
            </p>
            <p className="flex justify-between pb-2">
              <span className="font-medium opacity-50 text-x9 text-clrGray dark:text-clrPlaceholder">
                Interest Loss
              </span>
              <span className="font-semibold text-x9 text-clrGray dark:text-clrPlaceholder">
                {unlockDetails?.data?.numberOfDaysRemaining || 0} days remaining
              </span>
            </p>
          </div>
          <div className="px-3 py-2 bg-[#25346A1A] dark:bg-clrDarkBg2 rounded-lg">
            <p className="font-normal text-center text-x8 text-clrGray dark:text-clrPlaceholder">
              Note: Unstaking before lock duration is open may result to lose of
              all accured profits
            </p>
          </div>
          <ErrorMsg errMsg={errMsg} />
        </div>
        <div className="md:w-[80%] mt-6 mx-auto ">
          <Button
            type="button"
            text={title}
            handleClick={handleSubmit}
            loading={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

interface MoreInfoProps {
  handleModal: () => void;
}

export const StakeFlexibleLockedInfo: React.FC<MoreInfoProps> = ({
  handleModal,
}) => {
  return (
    <Modal
      close={false}
      title={""}
      description={""}
      handleCloseModal={handleModal}
    >
      <div>
        <div className="flex flex-col items-center justify-center gap-4 py-8 mb-8 bg-white rounded px-9 dark:bg-clrDarkBg">
          <img src={StakeRewardIcon} alt="Success" width={110} height={110} />
          <div className="text-center">
            <h2 className="mb-3 text-xs font-semibold text-black dark:text-white md:text-sm">
              Flexible Staking
            </h2>
            <p className="font-medium text-x9 md:text-x10 text-clrGray66 dark:text-clrPlaceholder">
              Flexible Staking allows you to stake any amount of WNT and redeem
              your reward after a 7days period of staking. You can top-up your
              WNT staking.
            </p>
            <h2 className="my-3 text-xs font-semibold text-black md:text-sm dark:text-white">
              Fixed Staking
            </h2>
            <p className="font-medium text-x9 md:text-x10 text-clrGray66 dark:text-clrPlaceholder">
              Fixed staking allows you to lock up your WNT Token for a set
              period of time i.e 1 month, 2 Months, e.t.c and redeem your WNT
              reward in a 7 day period. Note: you can't top up Fixed staking to
              restake, claim your WNT reward after 7 days of staking and Stake
              again with a different wallet. Fixed staking earns more rewards
              than flexible because of the higher staking score.
            </p>
          </div>
        </div>
        <div className="md:w-[80%] mx-auto ">
          <Button type="button" text={"Close"} handleClick={handleModal} />
        </div>
      </div>
    </Modal>
  );
};
