import React, { useState } from "react";
import { Card, ButtonAuth, ErrorMsg, InputBox } from "../../reusables";
import ClosedEye from "../../../assets/auth/eye-closed.svg";
import OpenEye from "../../../assets/auth/eye-open.svg";
import { Link, useNavigate } from "react-router-dom";
import { validateEmail } from "../../../Helper";
import { useLogInMutation } from "../../../features/api/authSlice";
import GoogleAuth from "../googleAuth/GoogleAuth";
import AuthFrame from "../../reusables/AuthFrame";
import { setUser } from "../../../features/store/authReducerSlice";
import { useAppDispatch } from "../../../app/hooks";

const loginDetails = {
  email: "",
  password: "",
};

interface LoginFormProps {
  email: string;
  password: string;
}
const LogIn = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isView, setView] = useState<boolean>(false);
  const [authenticating, setAuthenticating] = useState<boolean>(false);

  const [form, setForm] = useState<LoginFormProps>(loginDetails);
  const [isError, setError] = useState<string>("");
  const [logIn, { isLoading }] = useLogInMutation();

  //handle form inputs
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isError) {
      setError("");
    }
    const value = e.target.value;
    setForm({ ...form, [e.target.id]: value });
  };

  //function to validate user inputs and then send the request to the
  //backend for authentication.
  //if it's successful, we authenticate user and push them to the user dashboard home page
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!validateEmail(form.email)) {
      setError("Please enter a valid email");
    } else if (!form.password) {
      setError("Please enter a valid password");
    } else {
      setError("");
      logIn(form)
        .unwrap()
        .then((data) => {
          dispatch(setUser(data?.data));
          navigate("/home");
        })
        .catch((error) => {
          if (error?.data?.message) {
            setError(error.data.message);
          } else {
            setError("An error occurred.");
          }
        });
    }
  };

  return (
    <div className="flex">
      <Card>
        <div className="flex flex-col min-[426px]:w-[350px] mx-auto mb-12 md:mb-8 h-full">
          <div className="mb-6 text-center md:text-left">
            <h1 className="font-semibold text-black text-lg md:text-3xl md:leading-[54px] mb-5 ">
              Log In
            </h1>
            <p className="text-para">
              Hello dear, Log In to your dashboard and start managing your data.
            </p>
          </div>
          <GoogleAuth
            title={"signin_with"}
            setError={setError}
            setAuthenticating={setAuthenticating}
          />
          <div>
            <p className="my-3 text-center text-para">or</p>
          </div>
          <form className="">
            <div className="mb-4">
              <InputBox
                title="Email address"
                name="email"
                type="email"
                id="email"
                value={form.email}
                handleChange={handleChange}
                placeholder=""
              />
            </div>
            <div className="relative">
              <InputBox
                title="Password"
                type={isView ? "text" : "password"}
                name="password"
                id="password"
                value={form.password}
                handleChange={handleChange}
                placeholder=""
              />
              <span
                className="absolute cursor-pointer top-6 right-4"
                onClick={() => setView(!isView)}
              >
                {isView ? (
                  <img src={OpenEye} alt="open-eye" />
                ) : (
                  <img src={ClosedEye} alt="close-eye" />
                )}
              </span>
            </div>
            <ErrorMsg errMsg={isError ? isError : ""} />
            <div className="mt-2 mb-4 md:mb-8 ">
              <p className="text-[12px] leading-4 font-normal md:font-medium md:text-sm md:leading-[22px] text-clrYellow italic text-right">
                Forgot your pin?{" "}
                <Link to="/reset" className="text-gray-500">
                  Reset
                </Link>
              </p>
            </div>
            <ButtonAuth
              type="submit"
              loading={isLoading || authenticating}
              handleClick={handleSubmit}
              text="Log In"
              testId="login-button"
            />
          </form>
          <div>
            <p className="text-[12px] leading-4 font-normal md:font-medium md:text-[0.9rem] md:leading-[22px] text-[#333333] mt-4 text-center">
              Don't have an account yet?{" "}
              <Link to="/signup" className="text-clrYellow">
                Create Account
              </Link>
            </p>
          </div>
        </div>
      </Card>
      <AuthFrame />
    </div>
  );
};
export default LogIn;
