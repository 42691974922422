import ExplorerFirst from "../../assets/icons/explorer1.svg";
import Sync from "../../assets/e-sync.svg";
import Hub from "../../assets/e-hub2.svg";
import Chart from "../../assets/e-chart.svg";
import Claim from "../../assets/e-claim.svg";
import Boost from "../../assets/e-boost.svg";

export const EXPLORER_ONBOARDING_DATA = [
  {
    title: "Welcome to My  Explorer",
    details:
      "View all your Hub(s) activities on the Explorer",
    image: ExplorerFirst,
  },
  {
    title: "Sync NFts to your Physical Hubs",
    details:
      "Sync all NFTs to your Physical Hubs on your Wicrypt account to explore the SMART decentralized world",
    image: Sync,
  },
  {
    title: "Hub Overview",
    details:
      "Be one of the few originals to enjoy truly decentralized internet and have a preview of all Wicrypt hubs",
    image: Hub,
  },
  {
    title: "View your hubs Statistics",
    details:
      "Enjoy a truly decentralized world to view all your Wicrypt hubs statistics with our explorer",
    image: Chart,
  },
  {
    title: "Claim your mined rewards",
    details: "Claim your mined $WNT into your app wallet.",
    image: Claim,
  },
  {
    title: "Boost Rewards",
    details: "Multiply your weekly rewards with a time-based interest boost!",
    image: Boost,
  },
];

export const TOOLTIP_ONBOARDING_DATA = [
  {
    title: "Total WNT Balance",
    details: "A brief overview of Wicrypt Network Tokens earned by your hubs",
  },
  {
    title: "Boost Reward",
    details: "Lock up your weekly rewards to maximize interest over time.",
  },
  {
    title: "Claim Rewards",
    details: "Claim your earned rewards directly to your existing wallet",
  },
  {
    title: "Earnings",
    details: "A real time view of your earnings",
  },
  {
    title: "Hotspot Hubs",
    details: "An overview of total connections on your hubs",
  },
  {
    title: "Connections",
    details: "Have a brief overview of total connection your hubs",
  },
];
