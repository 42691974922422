import React, { useState, useEffect, ReactNode } from 'react';
import { ReactComponent as CancelIcon }  from "../../assets/general/cancel.svg";


interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  className?: string;
}

export const ModalNew: React.FC<ModalProps> = ({ 
  isOpen, 
  onClose, 
  title, 
  children,
  className = ''
}) => {
  const [animationClass, setAnimationClass] = useState('');
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setMounted(true);
      // Start from right (translate-x-full) and animate to center (translate-x-0)
      requestAnimationFrame(() => {
        setAnimationClass('translate-x-0 opacity-100');
      });
    } else {
      // Animate to left (-translate-x-full) when closing
      setAnimationClass('-translate-x-full opacity-0');
      const timer = setTimeout(() => setMounted(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!mounted) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden">
      {/* Backdrop */}
      <div 
        className={`
          fixed inset-0 bg-black/25 backdrop-blur-sm
          transition-opacity duration-300
          ${isOpen ? 'opacity-100' : 'opacity-0'}
        `}
        onClick={onClose}
        aria-hidden="true"
      />
      
      {/* Modal */}
      <div 
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-title"
        className={`
          relative
          bg-white 
          rounded-lg 
          shadow-lg 
          w-full 
          max-w-md
          mx-4
          p-6
          transform
          transition-all
          duration-300
          ease-in-out
          translate-x-full
          ${animationClass}
          ${className}
        `}
        style={{
          willChange: 'transform, opacity'
        }}
      >
        {/* Header */}
        <div className="flex items-center justify-between mb-4">
          <h2 
            id="modal-title"
            className="text-xl font-semibold"
          >
            {title}
          </h2>
          <button
            onClick={onClose}
            className="p-1 rounded-full hover:bg-gray-100 transition-colors"
            aria-label="Close modal"
          >
            <CancelIcon className="h-5 w-5 text-gray-500" />
          </button>
        </div>

        {/* Content */}
        <div>
          {children}
        </div>
      </div>
    </div>
  );
};
