import { useParams } from "react-router";
import ChevronUpIcon from "../../assets/icons/wallet/chevron-double-up.svg";
import CopyIcon from "../../assets/icons/copy.svg";
import TransactionHashIcon from "../../assets/icons/transaction-hash.svg";
import { useGetTransactionDetailQuery } from "../../features/api/explorerSlice";
import { filesize } from "filesize";
import { ScaleLoader } from "react-spinners";
import { useState } from "react";
import { copyToClipboard } from "../../Helper";

const SessionName = () => {
  const { sessionId } = useParams();
  const [copiedText, setCopied] = useState<string>("");

  const { data: transactionDetail, isLoading: gettingTransactionDetail } =
    useGetTransactionDetailQuery(sessionId as string);

  return (
    <section className="w-[96%] mx-auto pb-6 h-full mt-3 overflow-y-auto">
      <div className="grid grid-cols-2 gap-3 p-4 bg-white rounded-lg dark:bg-clrDarkBg md:pt-8 md:pb-20 md:gap-x-6 md:gap-y-7 md:px-16 md:grid-cols-6">
        <div className="flex flex-col gap-2 md:gap-3 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-3 md:p-4 shadow-wxl h-[6.75rem] md:h-32 col-start-1 col-end-3 md:col-start-1 md:col-end-4">
          <p className="text-sm font-semibold text-clrGray66 dark:text-clrPlaceholder">
            Transaction Hash
          </p>
          <div className="relative flex items-center gap-3">
            <img
              src={TransactionHashIcon}
              alt="Transaction Hash"
              width={30}
              height={30}
            />
            <p className="text-base font-bold text-black dark:text-white md:text-lg ">
              {gettingTransactionDetail ? (
                <ScaleLoader
                  loading={gettingTransactionDetail}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                transactionDetail?.data?.transactionId?.slice(0, 4) +
                "..." +
                transactionDetail?.data?.transactionId?.slice(-4)
              )}
            </p>
            <img
              src={CopyIcon}
              alt="Copy this"
              onClick={() => {
                copyToClipboard(transactionDetail?.data?.transactionId || "");
                setCopied(transactionDetail?.data?.transactionId || "");
              }}
              className="cursor-pointer"
              width={15}
              height={15}
            />
            {transactionDetail?.data?.transactionId === copiedText && (
              <p className="absolute p-1 mt-12 text-white border-solid rounded bg-clrYellow text-x10 right-2/3">
                {" "}
                Copied
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2 md:gap-3 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-3 md:p-4 shadow-wxl h-fit md:h-32 col-start-1 col-end-3 md:col-start-4 md:col-end-7">
          <p className="text-sm font-semibold text-clrGray66 dark:text-clrPlaceholder">
            Hotspot Hub
          </p>
          <p className="text-xl font-bold md:text-2xl text-clrTextGray dark:font-semibold dark:text-white">
            {transactionDetail?.data?.deviceName}
          </p>
          <div className="flex items-center gap-1">
            {gettingTransactionDetail ? (
              <ScaleLoader
                loading={gettingTransactionDetail}
                height={12}
                width={2}
                color={"#3d5170"}
              />
            ) : (
              <img
                src={transactionDetail?.data?.countryFlagUrl}
                alt="Increase"
                width={20}
                height={20}
              />
            )}
            <p className="text-xs font-normal text-clrGray4 ">
              {" "}
              {gettingTransactionDetail ? (
                <ScaleLoader
                  loading={gettingTransactionDetail}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                transactionDetail?.data?.city
              )}
              , {transactionDetail?.data?.country}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-2 md:gap-3 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-3 md:p-4 shadow-wxl h-fit md:h-32 col-start-1 col-end-2 md:col-start-1 md:col-end-3">
          <p className="text-sm font-semibold text-clrGray66 dark:text-clrPlaceholder">
            Data Used
          </p>
          <p className="text-xl font-bold md:text-2xl text-clrTextGray dark:text-white">
            {gettingTransactionDetail ? (
              <ScaleLoader
                loading={gettingTransactionDetail}
                height={12}
                width={2}
                color={"#3d5170"}
              />
            ) : (
              filesize(transactionDetail?.data?.dataUsage || 0).toString()
            )}
          </p>
          <div className="flex items-center gap-1">
            <div className="bg-[#5FBE91] flex items-center justify-center rounded-sm p-0.5">
              <img src={ChevronUpIcon} alt="Increase" width={10} height={10} />
            </div>
            <p className="text-sm font-normal text-clrGreen2 ">
              {gettingTransactionDetail ? (
                <ScaleLoader
                  loading={gettingTransactionDetail}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                transactionDetail?.data?.dataUsedPercentageChange
              )}
            </p>
          </div>
        </div>

        <div className="relative flex flex-col gap-2 md:gap-3 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-3 md:p-4 shadow-wxl h-[6.75rem] md:h-32 col-start-2 col-end-3 md:col-start-3 md:col-end-5">
          <p className="text-sm font-semibold text-clrGray66 dark:text-clrPlaceholder">
            Mac address
          </p>
          <p className="text-xl font-bold md:text-2xl text-clrTextGray dark:text-white">
            {gettingTransactionDetail ? (
              <ScaleLoader
                loading={gettingTransactionDetail}
                height={12}
                width={2}
                color={"#3d5170"}
              />
            ) : (
              transactionDetail?.data?.clientMacHash?.slice(0, 4) +
              "..." +
              transactionDetail?.data?.clientMacHash?.slice(-4)
            )}
          </p>
          <img
            src={CopyIcon}
            alt="Copy this"
            width={15}
            height={15}
            onClick={() => {
              copyToClipboard(transactionDetail?.data?.clientMacHash || "");
              setCopied(transactionDetail?.data?.clientMacHash || "");
            }}
            className="self-center cursor-pointer"
          />
          {transactionDetail?.data?.clientMacHash === copiedText && (
            <p className="absolute p-1 mt-12 text-white border-solid rounded bg-clrYellow text-x10 top-14 right-1/3">
              {" "}
              Copied
            </p>
          )}
        </div>

        <div className="flex flex-col gap-2 md:gap-3 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-3 md:p-4 shadow-wxl h-fit md:h-32 col-start-1 col-end-2 md:col-start-5 md:col-end-7">
          <p className="text-sm font-semibold text-clrGray66 dark:text-clrPlaceholder">
            Uptime
          </p>
          <p className="text-xl font-bold md:text-2xl text-clrTextGray dark:text-white">
            {gettingTransactionDetail ? (
              <ScaleLoader
                loading={gettingTransactionDetail}
                height={12}
                width={2}
                color={"#3d5170"}
              />
            ) : (
              transactionDetail?.data?.upTime
            )}
          </p>
          <div className="flex items-center gap-1">
            <div className="bg-[#5FBE91] flex items-center justify-center rounded-sm p-0.5">
              <img src={ChevronUpIcon} alt="Increase" width={10} height={10} />
            </div>
            <p className="text-sm font-normal text-clrGreen2 ">
              {gettingTransactionDetail ? (
                <ScaleLoader
                  loading={gettingTransactionDetail}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                transactionDetail?.data?.uptimePercentageChange
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SessionName;
