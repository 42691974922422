import SecondaryNav from "../reusables/SecondaryNav";

const HotspotHubs = () => {
  return (
    <SecondaryNav
      paths={[
        { path: "/home/hotspot-hubs/my-hubs", title: "Hotspot Hubs" },
        { path: "/home/hotspot-hubs/live-session", title: "Live Session" },
        { path: "/home/hotspot-hubs/hubs-report", title: "Hubs Report" },
      ]}
    />
  );
};

export default HotspotHubs;
