import React, { useRef, useState } from "react";
import ArrowDown from "../../assets/icons/arrow-down-Yellow.svg";
import { Button, ButtonOutlineWallet } from "../reusables";
import { ReactComponent as SuccessIcon } from "../../assets/icons/wallet/success.svg";
import { ReactComponent as MessageIcon } from "../../assets/icons/wallet/message.svg";
import Appeal from "./Appeal";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { useNavigate } from "react-router";
// import { numberWithCommas } from "../../Helper";
import moment from "moment";

const Success = ({
  data,
  type,
}: {
  data: OrderResponse;
  type: "WITHDRAWAL" | "FUND";
}) => {
  const [isAppeal, setIsAppeal] = useState(false);

  const AppealRef = useRef<HTMLElement>(null);
  const navigate = useNavigate();

  const handleBackToWallet = () => {
    navigate("/home/wallet");
  };

  const handleCloseModal = () => {
    setIsAppeal(false);
  };

  useOutsideClick(AppealRef, handleCloseModal);

  return (
    <>
      <div className="px-4 py-4 bg-white rounded md:px-12 dark:bg-clrDarkBg">
        <div className="flex flex-col items-center justify-center gap-3 px-3 py-8 space-y-3 bg-white rounded dark:bg-clrDarkBg ">
          <SuccessIcon width={80} height={80} />
          <div className="mb-5 text-center">
            <h2 className="mb-3 text-base font-semibold text-clrGreen2 dark:font-medium">
              {data?.p2POrderStatus}
            </h2>
            <p className="text-xl font-bold text-black dark:text-white dark:font-semibold">
              {type === "FUND" ? data?.receivableAmount : data?.payableAmount}{" "}
              {type === "FUND"
                ? data?.receiveInCurrencyCode
                : data?.payInCurrencyCode}
            </p>
          </div>
          <p className="text-[9px] font-medium text-clrGray66 dark:text-clrPlaceholder w-3/4 md:w-[35%] mx-auto text-center">
            {type === "FUND"
              ? "We will confirm the receipts of Fund, and send the money to your wallet within the next 30 minutes."
              : "Your withdrawal request is being reviewed and the converted funds will be sent to your selected account within 12 hours."}
          </p>
        </div>
        <div>
          <p className="mb-3 text-[10px] font-semibold text-clrTextGray dark:text-clrPlaceholder">
            Transaction Details
          </p>
          <div className="space-y-3">
            <p className="flex justify-between">
              <span className="text-[10px] font-medium text-clrGray dark:text-clrPlaceholder">
                Date
              </span>
              <span className="text-[10px] font-semibold text-clrTextGray dark:text-white dark:font-medium">
                {moment(data?.initiatedAtTimestamp).format(
                  "MM/DD/YYYY | h:mm a"
                )}
              </span>
            </p>
            <p className="flex justify-between">
              <span className="text-[10px] font-medium text-clrGray dark:text-clrPlaceholder">
                Currency
              </span>
              <span className="text-[10px] font-semibold text-clrTextGray dark:text-white dark:font-medium">
                {type === "FUND"
                  ? data?.payInCurrencyCode
                  : data?.receiveInCurrencyCode}
              </span>
            </p>
            {/* <p className="flex justify-between">
              <span className="text-[10px] font-medium text-clrGray">
                Quantity
              </span>
              <span className="text-[10px] font-semibold text-clrTextGray">
                23,0000 NGN
              </span>
            </p> */}
            <p className="flex justify-between">
              <span className="text-[10px] font-medium text-clrGray dark:text-clrPlaceholder">
                Payment method
              </span>
              <span className="text-[10px] font-semibold text-clrTextGray dark:text-white dark:font-medium">
                {type === "FUND"
                  ? data?.consumerPaymentMethod?.paymentMethod
                  : data?.providerPaymentMethods?.paymentMethod}
              </span>
            </p>
            <p className="flex justify-between">
              <span className="text-[10px] font-medium text-clrGray dark:text-clrPlaceholder">
                Amount
              </span>
              <span className="text-[10px] font-semibold text-clrTextGray dark:text-white">
                {type === "FUND" ? data?.payableAmount : data?.receivableAmount}{" "}
                {type === "FUND"
                  ? data?.payInCurrencyCode
                  : data?.receiveInCurrencyCode}
              </span>
            </p>
          </div>
        </div>
        <div className="w-[60%] mt-8 mx-auto space-y-4">
          <Button
            text="Confirm & Close"
            type="button"
            handleClick={handleBackToWallet}
          />

          <ButtonOutlineWallet
            text="Appeal"
            type="button"
            handleClick={() => setIsAppeal(true)}
            showIcon={true}
            showIconEnd={true}
            btnIcon={<MessageIcon />}
            btnIconEnd={ArrowDown}
            imgWidth="15px"
            imgHeight="15px"
            wBtn="w-full"
            py="md:py-2"
          />
        </div>
      </div>
      <p className="text-[8px] font-normal text-clrGray text-center opacity-40 mt-4">
        Powered by Xend Bridge{" "}
      </p>
      {isAppeal && (
        <Appeal
          modalRef={AppealRef}
          handleModal={handleCloseModal}
          data={data as OrderResponse}
        />
      )}
    </>
  );
};

export default Success;
