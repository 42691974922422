import React, { useState} from "react";
import {
  InputBox,
  Card,
  CardTitle,
  ButtonAuth,
  ButtonOutline,
  ErrorMsg,
} from "../../reusables";
import AuthFrame from "../../reusables/AuthFrame";
import { useSignUpMutation } from "../../../features/api/authSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { setUser } from "../../../features/store/authReducerSlice";

interface SignUpDetails {
  name: string;
  email: string;
  password: string;
  macAddress: null;
  confirmpassword: string;
}

const Referral = (signupDetails: SignUpDetails) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [referral, setReferral] = useState("");
  const [isError, setError] = useState("");

  const [signUp, { isLoading }] = useSignUpMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReferral(e.target.value);
  };

  const handleSubmit = () => {
    signUp({ ...signupDetails, referrer: referral })
      .unwrap()
      .then((res) => {
        if (res?.success) {
          dispatch(setUser(res?.data));
          navigate("/home");
        } else {
          setError(res.message);
        }
      })
      .catch((error) => {
        if (error?.data?.message) {
          setError(error.data.message);
        } else {
          setError("An error occurred.");
        }
      });
  };

  return (
    <div className="flex">
      <Card>
        <div className="flex items-center md:items-start flex-col mt-2 w-[300px] mx-auto mb-12 md:mb-8 h-full">
          <div className="">
            <CardTitle title="Referral" />
          </div>
          <div className="mt-10 mb-10">
            <InputBox
              type="referral"
              title="Enter Referral Code"
              name="referral"
              id="referral"
              value={referral}
              handleChange={handleChange}
            />
            <ErrorMsg errMsg={isError} />
          </div>
          <div className="w-full space-y-4">
            <ButtonAuth
              type="submit"
              loading={isLoading}
              handleClick={handleSubmit}
              text="Proceed"
              disabled={isLoading}
            />
            <ButtonOutline
              type="button"
              handleClick={handleSubmit}
              text="Skip"
              disabled={isLoading}
            />
          </div>
        </div>
      </Card>
      <AuthFrame />
    </div>
  );
};

export default Referral;
