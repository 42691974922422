import { useState } from "react";
import AddIcon from "../../assets/icons/wallet/add.svg";
import { AddNewCaption } from "./AdvertModals";
import { ButtonYellow, EmptyCard, TableLoader } from "../reusables";
import TableMain from "../reusables/ReactDataTable";
import { adsCaption } from "./data";
import { useCreateCaptionMutation, useGetCaptionsQuery } from "../../features/api/advertSlice";



const Caption = () => {
  const [isNewCaption, setNewCaption] = useState<boolean>(false);
  const { data, isLoading, isFetching } = useGetCaptionsQuery();

  const [createCaption, {isLoading: creatingCaption}] =  useCreateCaptionMutation()

  const handleCaption = (data: { caption: string, is_active: boolean}) =>{
    createCaption(data)
    .unwrap()
    .then(()=>{
      setNewCaption(false)
    })
    .catch((err)=>{
      console.error(err, "i am the error here")
    })
  }

  return (
    <section className="w-[96%] mx-auto mt-3.5 md:mt-3 mb-6 h-full">
      <div className="px-4 pt-2.5 pb-20 mb-2 bg-white dark:bg-clrDarkBg md:px-10">
        <div>
          <div className="mt-4 mb-4 md:mb-9">
            <h3 className="text-xs font-semibold text-black md:text-sm dark:text-white">
              Advert Captions {' '}
            </h3>
            <p className="text-x10 text-clrGray dark:text-clrPlaceholder">
              Write a caption you want your viewers to see when viewing your ads {' '}
            </p>
          </div>
          <button onClick={()=> setNewCaption(!isNewCaption)}>
            <ButtonYellow text="Create Agent" btnIcon={AddIcon} to={""} />
          </button>
        </div>
        <div>
          {isLoading || isFetching ? (
            <TableLoader />
          ) : (
            <TableMain
              defaultData={data?.data || []}
              columns={adsCaption}
              totalPages={1}
              pageSize={data?.data?.length || 0}
              currentPage={1}
              setPage={() => {}}
            />
          )}
          {!isLoading && !isFetching && (!data?.data || data?.data.length === 0) && (
            <EmptyCard 
              description={"No Caption"}
            />
          )}
        </div>
      </div>
      {isNewCaption && <AddNewCaption handleClick={handleCaption} handleModal={() => setNewCaption(!isNewCaption)} isLoading ={creatingCaption} />}
    </section>
  );
};


export default Caption;
