import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from "../../app/store";
import config from "./config";

// initialize an empty api service that we'll inject endpoints into later as needed
export const apiSlice = createApi({
    reducerPath: "apiUrl",
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => {
            const user = JSON.parse(localStorage.getItem("user") || "{}");
            if (user) {
                headers.set("authorization", `Bearer ${user.token}`);
                return headers;
            }
        },

    }),
    tagTypes: ['Tokens',
        'Vouchers',
        'Agents',
        'Reward',
        'Wallet',
        'Devices',
        'Explorer',
        'Disputes',
        'ParentalControl',
        'Subscription',
        'HubForms',
        'TwoFA',
        'Analytics'],
    endpoints: () => ({}),
})

export const appealSlice = createApi({
    reducerPath: "disputeUrl",
    baseQuery: fetchBaseQuery({
        baseUrl: config.disputeBaseUrl,
        prepareHeaders: (headers, api) => {
            const {
                auth: { token },
            } = api.getState() as RootState;
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
                return headers;
            }
        },
    }),
    endpoints: () => ({}),
})

export const explorerSlice = createApi({
    reducerPath: "explorerUrl",
    baseQuery: fetchBaseQuery({
        baseUrl: config.explorerUrl,
        prepareHeaders: (headers) => {
            const user = JSON.parse(localStorage.getItem("user") || "{}");
            if (user) {
                headers.set("authorization", `Bearer ${user.token}`);
                return headers;
            }
        },
    }),
    tagTypes: ['Explorer', 'Wallet'],
    endpoints: () => ({}),
})

export const stakingApi = createApi({
    reducerPath: "stakingUrl",
    baseQuery: fetchBaseQuery({
        baseUrl: config.stakingBaseUrl,
        prepareHeaders: (headers) => {
            const user = JSON.parse(localStorage.getItem("user") || "{}");
            if (user) {
                headers.set("authorization", `Bearer ${user.token}`);
                return headers;
            }
        },
    }),
    tagTypes: ['Wallet', 'Staking'],
    endpoints: () => ({}),
})

export const clusterSlice = createApi({
    reducerPath: 'clusterUrl',
    baseQuery: fetchBaseQuery({
        baseUrl: config.clusterUrl,
        prepareHeaders: (headers) => {
            const user = JSON.parse(localStorage.getItem('mtn-user') || '{}');
            if (user) {
                headers.set('authorization', `Bearer ${user.accessToken}`);
                return headers;
            }
        },
    }),
    tagTypes: ['Cluster'],
    endpoints: () => ({}),
});

export const apiAdvertSlice = createApi({
    reducerPath: 'advertApiUrl',
    baseQuery: fetchBaseQuery({
      baseUrl: config.advertApiUrl,
      prepareHeaders: (headers) => {
        const user = JSON.parse(localStorage.getItem('mtn-user') || '{}');
        if (user) {
          headers.set('authorization', `Bearer ${user.accessToken}`);
          return headers;
        }
      },
    }),
    tagTypes: ['Devices', 'Adverts'],
    endpoints: () => ({}),
  });
  