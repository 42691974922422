import SecondaryNav from "../reusables/SecondaryNav";

const Explorer = () => {
 
  return (
      <SecondaryNav
        paths={[
          { path: "/home/explorer/overview", title: "Overview" },
          { path: "/home/explorer/hotspot-hubs", title: "Hotspot Hubs" },
          { path: "/home/explorer/claim-history", title: "Claim History" },
        ]}
      />
  );
};

export default Explorer;
