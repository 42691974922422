import SecondaryNav from "../reusables/SecondaryNav";

const Booster = () => {
  return (
    <SecondaryNav 
      paths={[
        {path: "/home/booster/overview", title: "Overview"},
        {path: "/home/booster/history", title: "Boost History" }
      ]}
    />
   
  );
};

export default Booster;
