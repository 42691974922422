import { useState } from "react";
import { SearchRow } from "./common";
import TrafficSummary from "./TrafficSummary";

const TrafficAnalytics = () => {
  const [filterYear, setFilterYear] = useState<string>('2024');
  const [filterTimeline, setFilterTimeline] = useState<string>('Yearly')
  const [filterMonth, setFilterMonth] = useState<string>('August');;
  return (
    <section className="w-[96%] mx-auto pb-6 overflow-y-auto">
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-6 my-4">
        <div className="grid gap-7">
          <h3 className="text-sm font-semibold text-clrGray dark:text-white">
            Hub History
          </h3>
          <SearchRow 
          setFilterYear={setFilterYear} 
          filterYear={filterYear} 
          filterTimeline={filterTimeline}
          setFilterTimeline={setFilterTimeline}
          setFilterMonth={setFilterMonth}
          filterMonth={filterMonth}
        />
        </div>
        <TrafficSummary />
      </div>
      
    </section>
  );
};

export default TrafficAnalytics;
