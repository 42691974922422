import { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { MobileFilterSearch } from "../hotspotHubs/OptionsStatus";
import TableMain from "../reusables/ReactDataTable";
import { boosterHistoryColumn } from "./data";
import { useGetRewardBoosterHistoryQuery } from "../../features/api/rewardSlice";

import { EmptyCard, TableLoader } from "../reusables";

const BoosterHistory = () => {
  const { id: userId } = useAppSelector((state) => state.auth);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [startDateTimeStamp, setStartDateTimestamp] = useState<string>("");
  const [endDatetimeStamp, setEndDateTimeStamp] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const { data, isLoading, isFetching } = useGetRewardBoosterHistoryQuery({
    userId: userId,
    StartDateTimeStamp: startDateTimeStamp,
    EndDateTimeStamp: endDatetimeStamp,
    Page: page,
    PageSize: 30,
  });

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartDate(event.target.value);
    const selectedDate = new Date(event.target.value);
    setStartDateTimestamp(selectedDate.getTime().toString());
    setStartDateTimestamp(selectedDate.getTime().toString());
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
    const selectedDate = new Date(event.target.value);
    setEndDateTimeStamp(selectedDate.getTime().toString());
  };

  return (
    <section className="w-[96%] mx-auto mb-6 overflow-y-auto bg-white dark:bg-clrDarkBg mt-2">
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:px-8 md:py-10">
        <div className="flex flex-wrap items-end gap-3 mb-8">
          <div className="flex w-full gap-3 md:w-auto">
            <MobileFilterSearch>
              <div className="relative mb-3">
                <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder">
                  Start Date
                </h4>
                <input
                  type="date"
                  className="w-full md:w-52 text-x10 text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x9 bg-inherit"
                  value={startDate}
                  onChange={(e) => handleStartDateChange(e)}
                />
              </div>
              <div className="relative">
                <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder">
                  End Date
                </h4>
                <input
                  type="date"
                  className="w-full md:w-52 text-x10 border-[1px] text-clrPlaceholder border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x9 bg-inherit"
                  value={endDate}
                  onChange={(e) => handleEndDateChange(e)}
                />
              </div>
            </MobileFilterSearch>
          </div>
        </div>
        <div>
          {isLoading ? (
            <TableLoader />
          ) : (
            <TableMain
              defaultData={data?.data.records || []}
              columns={boosterHistoryColumn}
              totalPages={data?.data.totalPages || 0}
              pageSize={data?.data.pageSize || 0}
              currentPage={data?.data.currentPage || 0}
              setPage={setPage}
            />
          )}
        </div>
        {!isLoading &&
          !isFetching &&
          (data?.data?.records?.length ?? 0) === 0 && (
            <EmptyCard description="There are no active boost plans available at the moment" />
          )}
      </div>
    </section>
  );
};

export default BoosterHistory;
