import { useState, useRef } from "react";
import { useAppSelector } from "../../app/hooks";
import { Button, DropDownContainer, ErrorMsg } from "../reusables";
import { Modal } from "../reusables/Modal";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-Yellow.svg";
import { useGenerateVoucherMutation } from "../../features/api/voucherSlice";
import { renderDays, renderHrs } from "../../Helper";

type GenerateTokenProps = {
  handleModal: () => void;
};

//Component to generate voucher for a user
export const GenerateVoucher: React.FC<GenerateTokenProps> = ({
  handleModal,
}) => {
  const { businessId } = useAppSelector((state) => state.auth);

  const modalRef = useRef<HTMLElement>(null);
  const [option, toggleOption] = useState<string>("");
  const [voucherRecipient, setVoucherRecipient] = useState<string>("");
  const [voucherValidity, setVoucherValidity] = useState<string>("days");
  const [voucherAmount, setVoucherAmount] = useState<string>("");
  const [errorMsg, setError] = useState<string>("");
  const [validityPeriod, setValidityPeriod] = useState<string>("");

  const handleCloseModal = () => {
    toggleOption("");
  };

  //Generate Voucher
  const [generateVoucher, { isLoading }] = useGenerateVoucherMutation();

  const handleGenerateVoucher = () => {
    setError("");
    if (!voucherRecipient) {
      setError("Please add a recipient for this voucher");
    } else if (!validityPeriod) {
      setError("Please indicate how long this voucher is valid for");
    } else if (!voucherAmount) {
      setError("Please set a value for this voucher");
    } else {
      let data = {
        phoneNumber: voucherRecipient,
        email: "",
        amount: Number(voucherAmount),
        validityInHours:
          voucherValidity === "days" &&  typeof validityPeriod !== "string"
            ? Number(validityPeriod) * 24
            :  voucherValidity === "days" && typeof validityPeriod === "string" && validityPeriod.toLowerCase() == "unlimited" ? 
              17532
            : Number(validityPeriod),
        businessId: businessId,
      };
      generateVoucher(data)
        .unwrap()
        .then((res) => {
          if (res.success) {
            handleModal();
          } else {
            setError(res.message);
          }
        })
        .catch((err) => {
          setError(err?.data.message);
        });
    }
  };

  useOutsideClick(modalRef, handleCloseModal);
  return (
    <Modal
      close={true}
      title={"Generate Voucher"}
      description={"Input necessary details to generate Voucher"}
      handleCloseModal={handleModal}
    >
      <>
        <div className="flex flex-col gap-2 mb-4 ">
          <div className="p-4 bg-white rounded dark:bg-clrDarkBg md:px-5 md:pt-5">
            <label
              htmlFor="voucherrecipient"
              className="block font-semibold text-black dark:text-white text-x10 md:text-xs"
            >
              Phone Number
            </label>

            <input
              type="text"
              name="voucherrecipient"
              placeholder="08000000000"
              value={voucherRecipient}
              onChange={(e) => {
                setVoucherRecipient(e.target.value);
                setError("");
              }}
              className="text-clrGray placeholder:text-x11 text-x11 pb-1 font-normal dark:text-clrPlaceholder border-b-[1px] border-solid border-clrBorder5 dark:border-clrGray w-full focus:outline-none dark:bg-transparent"
              // className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
            />
            <p className="mt-1 text-x10 text-clrGray">
              {" "}
              Phone Number of the recipient
            </p>
          </div>
          <div className="p-4 bg-white rounded dark:bg-clrDarkBg md:px-5 md:pt-5">
            <div className="space-y-6">
              <div className="flex items-center gap-8">
                <label
                  htmlFor=""
                  className="block mr-3 font-semibold text-black dark:text-white text-x10 md:text-xs"
                >
                  Voucher Validity
                </label>

                <div
                  className="relative flex items-center gap-2 radio-input"
                  onClick={() => {
                    setVoucherValidity("days");
                    setValidityPeriod("");
                  }}
                >
                  <input
                    type="radio"
                    name="validity"
                    id=""
                    className=""
                    checked={voucherValidity === "days"}
                  />
                  {voucherValidity === "days" && (
                    <div className="circle !left-[0.19em] !top-[0.2em] !absolute"></div>
                  )}
                  <label
                    htmlFor=""
                    className="block font-medium text-black dark:text-clrPlaceholder text-x10 md:text-xs"
                  >
                    Days
                  </label>
                </div>
                <div
                  className="relative flex items-center gap-2 radio-input"
                  onClick={() => {
                    setVoucherValidity("hrs");
                    setValidityPeriod("");
                  }}
                >
                  <input
                    type="radio"
                    name="validity"
                    id=""
                    className=""
                    checked={voucherValidity === "hrs"}
                  />
                  {voucherValidity === "hrs" && (
                    <div className="circle !left-[0.19em] !top-[0.2em] !absolute"></div>
                  )}
                  <label
                    htmlFor=""
                    className="block font-medium text-black dark:text-clrPlaceholder text-x10 md:text-xs"
                  >
                    Hours
                  </label>
                </div>
              </div>
            </div>
            <div className="rounded bg-clrBg2 dark:bg-clrDarkBg2">
              <Filter
                modalRef={modalRef}
                options={
                  voucherValidity === "days" ? renderDays() : renderHrs()
                }
                toggleFilter={toggleOption}
                selectedFilterOption={option}
                currentFilterOption={"VALIDITY"}
                selectFilterOption={setValidityPeriod}
                selectedOption={
                  !validityPeriod
                    ? "Select Validity Period"
                    : validityPeriod + " " + voucherValidity
                }
                label={""}
              />
            </div>
          </div>
          <div className="p-4 bg-white rounded dark:bg-clrDarkBg md:px-5 md:pt-5 ">
            <div className="flex flex-col items-center justify-center gap-4 ">
              <p className="font-semibold text-black dark:text-white text-x10 md:text-xs">
                {" "}
                Voucher Amount
              </p>
              <p className="text-xs font-semibold text-clrGray dark:text-clrPlaceholder opacity-60">
                Click to enter amount
              </p>
              <div className="flex">
                <input
                  className="w-1/2 text-6xl font-bold text-right text-black outline-none dark:bg-clrDarkBg dark:text-white dark:placeholder:!text-white"
                  onChange={(e) => setVoucherAmount(e.target.value)}
                  value={voucherAmount}
                  placeholder="0"
                />
                <span className="self-end pb-5 font-normal text-gray-600 text-x10 dark:text-clrPlaceholder">
                  USD
                </span>
              </div>
            </div>
          </div>
          <ErrorMsg errMsg={errorMsg} />
        </div>

        <div className="w-4/5 mx-auto">
          <Button
            text="Set"
            type="button"
            loading={isLoading}
            handleClick={handleGenerateVoucher}
          />
        </div>
      </>
    </Modal>
  );
};

// Filter options
export const Filter: React.FC<DropdownContainerFilterProps> = ({
  options,
  toggleFilter,
  selectedOption,
  modalRef,
  selectFilterOption,
  selectedFilterOption,
  currentFilterOption,
}) => {
  return (
    <div className="relative mb-4 md:mb-0">
      <button
        onClick={() => toggleFilter(currentFilterOption)}
        className="flex items-center justify-between relative w-full mt-[0.875rem] p-2"
      >
        <p className="text-xs font-medium text-clrGray dark:text-clrPlaceholder">
          {selectedOption}
        </p>
        <ArrowDown width={20} height={20} />
      </button>
      {selectedFilterOption === currentFilterOption && (
        <DropDownContainer
          list={options}
          modalRef={modalRef}
          closeDropdown={toggleFilter}
          handleSelect={selectFilterOption}
        />
      )}
    </div>
  );
};
