import { useState } from "react";
import Verify from "./Verify";
import CreatePin from "./CreatePin";
import Referral from "./Referral";
import SignUpForm from "./SignUpForm";
import Success from "./Success";

const signUpDetails = {
    email: "",
    fname: "",
    lname: "",
    otp: "",
    pin: "",
};

//Component to handle all the sig up steps 
const SignUp = () => {
    const [form, setForm] = useState(signUpDetails);
    const [signUpStep, setStep] = useState<number>(0);

    //Function to handle the signup steps and also save any value from the children component 
    const handleNext = (title?: string, value?: string) => {
        if (title) {
            setForm({ ...form, [title]: value });
        }
        setStep(signUpStep + 1);
    };


    //Function to determine which of the signupsteps to render 
    const renderSignUpPage = () => {
        switch (signUpStep) {
            case 1:
                return <Success email={form.email} handleNext={() => setStep(2)} />
            case 2:
                return <Verify email={form.email} handleBack={() => setStep(0)} handleNext={handleNext} />
            case 3:
                return <CreatePin email={form.email} handleNext={handleNext} />
            case 4:
                return <Referral name={form.fname + " " + form.lname} email={form.email} password={form.pin} macAddress={null} confirmpassword={form.pin} />
            default:
                return <SignUpForm handleNext={setStep} form={form} setForm={setForm}/>

        }
    }
    return (
        <>
         {renderSignUpPage()}
        </>
        
    );
};
export default SignUp;
