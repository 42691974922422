import React, { ReactElement, useRef } from "react";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrow-back.svg";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { ReactComponent as CloseIcon } from "../../assets/icons/hotspot-hubs/close.svg";

interface ModalProps {
  handleCloseModal: () => void;
  children: ReactElement;
  title?: string;
  close?: boolean;
  description?: string;
  goBack?: boolean;
  handleGoBack?: () => void; 
}

export const Modal: React.FC<ModalProps> = ({
  handleCloseModal,
  children,
  title,
  close,
  description,
  goBack,
  handleGoBack,
}) => {
  const modalRef = useRef<HTMLElement>(null);
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <section
      className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#F4F4F4] md:bg-[#00000033] dark:md:bg-[#00000080] dark:bg-clrDarkBg2 flex justify-center md:items-center"
      ref={modalRef}
    >
      <div className="bg-clrBg2 dark:bg-clrDarkBg2 py-6 z-30 rounded-xl w-11/12 md:px-4 md:w-[450px]">
        {goBack && (
          <button className="mb-2 md:mb-3 btn-reset" onClick={handleGoBack}>
            <ArrowBack className="w-5 h-5 dark-arrow" />
          </button>
        )}

        <div className="flex items-start justify-between">
          {title && (
            <div className="mb-3">
              <h1 className="mb-2 text-base font-semibold text-black dark:text-white">
                {title}
              </h1>
              <p className="font-normal text-x10 md:text-xs text-clrGray dark:text-clrPlaceholder">
                {description}
              </p>
            </div>
          )}

          {close && (
            <button onClick={handleCloseModal}>
              <CloseIcon className="w-[13px] h-[13px] self-end dark-close" />
            </button>
          )}
        </div>
        {children}
      </div>
    </section>
  );
};

export const Modal2: React.FC<ModalProps> = ({
  handleCloseModal,
  children,
  close,
  goBack,
  handleGoBack,
}) => {
  const modalRef = useRef<HTMLElement>(null);
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <section
      className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#F4F4F4] md:bg-[#00000033] dark:md:bg-[#00000080] dark:bg-clrDarkBg2 flex justify-center md:items-center"
      ref={modalRef}
    >
      <div className=" bg-clrBg2 dark:bg-clrDarkBg2 py-6 z-30 rounded-xl w-11/12 md:px-4 md:w-[450px]">
        <div className="flex justify-between">
          {goBack && (
            <button className="mb-2 md:mb-3 btn-reset" onClick={handleGoBack}>
              <ArrowBack className="w-5 h-5 dark-arrow" />
            </button>
          )}
          {close && (
            <button onClick={handleCloseModal}>
              <CloseIcon className="w-[13px] h-[13px] self-end dark-close" />
            </button>
          )}
        </div>

        {children}
      </div>
    </section>
  );
};
