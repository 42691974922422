import { Button, ButtonOutlineWallet, ErrorMsg } from "../reusables";
import SuccessIcon from "../../assets/icons/wallet/success.svg";
import { ReactComponent as ConfirmActionIcon } from "../../assets/icons/confirm-action.svg";
import { Modal } from "../reusables/Modal";

export const ExplorerSuccess: React.FC<ExplorerSuccessProps> = ({
  handleModal,
  handleCloseModal,
  title,
  description,
  description2,
  btnText,
  btnText2,
}) => {
  return (
    <Modal
      close={false}
      title={""}
      description={""}
      goBack={false}
      handleCloseModal={() => {}}
    >
      <div>
        <div className="flex flex-col items-center justify-center gap-3 px-3 py-8 bg-white rounded dark:bg-clrDarkBg">
          <img
            src={SuccessIcon}
            alt="Success"
            width={80}
            height={80}
            className="w-20 h-20 "
          />
          <div className="mb-2 text-center">
            <h2 className="mb-3 text-sm font-bold text-clrGreen2 md:text-base">
              {title}
            </h2>
            <p className="text-x9 md:text-x10 font-medium text-clrGray66 dark:text-clrPlaceholder w-[90%] md:w-[60%] mx-auto">
              {description}{" "}
            </p>
            <p className="text-x9 md:text-x10 font-medium text-clrGray66 dark:text-clrPlaceholder leading-6 w-[90%] md:w-[50%] mx-auto mt-3">
              {description2}{" "}
            </p>
          </div>
        </div>

        <div className="md:w-[80%] mt-6 mx-auto flex flex-col gap-3 ">
          <Button
            type="button"
            text={btnText}
            handleClick={handleCloseModal}
            fontWeight="font-normal"
          />
          <ButtonOutlineWallet
            type="button"
            text={btnText2}
            handleClick={handleModal}
            wBtn="w-full"
            py="py-2"
            textSize="text-x11"
          />
        </div>
      </div>
    </Modal>
  );
};

/**
 * Component to show a summary of how much you are claiming and where you are updating it to
 * Renders a modal for selecting preferred method of transaction.
 * @param handleModal - Function to handle closing the modal
 * @param claimWNT - Function to handle claiming wnt from the explorer.
 * @returns React functional component.
 */
export const ClaimSummary: React.FC<ClaimProps> = ({
  handleModal,
  claimWNT,
  wntBalance,
  claimingReward,
  errMsg,
}) => {
  return (
    <Modal
      close={true}
      title={"Summary"}
      description={""}
      goBack={false}
      handleCloseModal={handleModal}
    >
      <>
        <div className="flex flex-col items-center justify-center gap-2.5 px-3 py-3.5 mb-8 bg-white dark:bg-clrDarkBg rounded">
          <ConfirmActionIcon />

          <p className="-mt-3.5 font-medium text-x9 md:text-x10 text-clrGray66 dark:text-clrGray">
            You are claiming
          </p>
          <h2 className="text-base font-semibold text-black dark:text-white md:text-lg">
            {wntBalance} WNT
          </h2>
          <p className="font-medium text-x9 md:text-x10 text-clrGray66 dark:text-clrGray">
            to
          </p>
          <div className="flex items-center gap-2">
            <div className="flex items-center w-4 h-4 rounded bg-[#d3d3d3]">
              <ConfirmActionIcon />
            </div>
            <p className="font-medium text-x9 md:text-x10 text-clrGray66 dark:text-white">
              Wicrypt Wallet
            </p>
          </div>
          <ErrorMsg errMsg={errMsg} />
        </div>

        <div className="md:w-[80%] mt-6 mx-auto ">
          <Button
            type="button"
            text="Confirm"
            loading={claimingReward}
            handleClick={claimWNT}
          />
        </div>
      </>
    </Modal>
  );
};
