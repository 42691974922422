import React, { useState, useRef } from "react";
import { Button, DropDownContainer, ErrorMsg } from "../reusables";
import { Modal } from "../reusables/Modal";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-Yellow.svg";
import { useGenerateTokenMutation } from "../../features/api/tokenSlice";
import { renderDays, renderHrs } from "../../Helper";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrow-back.svg";
import { ReactComponent as CancelIcon }  from "../../assets/general/cancel.svg";
import { filesize } from "filesize";
import moment from "moment";
import { Token } from "./data";
import EnterPin from "../reusables/EnterPin";
import { ModalNew } from "../ui/Modal";
import { Link } from "react-router-dom";


type GenerateTokenProps = {
  handleModal: () => void;
};

//Component to generate a token
export const GenerateToken: React.FC<GenerateTokenProps> = ({
  handleModal,
}) => {
  const modalRef = useRef<HTMLElement>(null);
  const [option, toggleOption] = useState<string>("");
  const [tokenType, setTokenType] = useState<string>("Data");
  const [dataUnit, setDataUnit] = useState<string>("");
  const [dataVolume, setDataVolume] = useState<string>("");
  const [tokenQuantity, setTokenQuantity] = useState<string>("");
  const [tokenValidity, setTokenValidity] = useState<string>("days");
  const [validityPeriod, setValidityPeriod] = useState<string>("");
  const [errorMsg, setError] = useState<string>("");

  const secondsInOneDay = 24 * 60 * 60;

  //Generate Tokens
  const [generateToken, { isLoading }] = useGenerateTokenMutation();

  const handleCloseModal = () => {
    toggleOption("");
  };

  useOutsideClick(modalRef, handleCloseModal);

  const handleGenerateTokens = () => {
    setError("");
    if (!tokenType) {
      setError("Please choose a token type");
    } else if (validityPeriod === "" && tokenType === "timed") {
      setError("Please indicate how long this timed token is valid for");
    } else if (dataVolume === "" && tokenType === "data") {
      setError("Please indicate the value of the data token");
    } else if (!tokenQuantity) {
      setError("Please set the quantity of tokens you are looking to generate");
    } else {
      let data = {
        customerId: "",
        secondsAllocated:
         tokenValidity === "days" && typeof validityPeriod === "number"
          ? validityPeriod * secondsInOneDay
          : tokenValidity === "days" && typeof validityPeriod === "string"
          ? 730 * secondsInOneDay // 730 days for 2 years
          : typeof validityPeriod === "number"
          ? validityPeriod * 60 * 60
          : 0,
        bytesAllocated:
          dataUnit === "GB"
            ? Number(dataVolume) * 1024 * 1024 * 1024
            : dataUnit === "MB"
            ? Number(dataVolume) * 1024 * 1024
            : Number(dataVolume) * 1024,
        codeValidityInSeconds: 0,
        dataTokenUsageType: tokenType,
        quantity: Number(tokenQuantity),
      };
      generateToken(data)
        .unwrap()
        .then((res) => {
          if (res.success) {
            handleModal();
          } else {
            setError(res.message);
          }
        })
        .catch((err) => {
          setError(err?.data.message);
        });
    }
  };
  return (
    <Modal close={true} title={"Generate Token"} handleCloseModal={handleModal}>
      <>
        <div className="flex flex-col gap-6 p-4 mb-4 bg-white rounded dark:bg-clrDarkBg md:px-5 md:pt-5 md:pb-8">
          <div>
            <Filter
              modalRef={modalRef}
              options={[
                { id: 1, value: "Data", enum: "Data" },
                { id: 2, value: "Timed", enum: "Timed" },
              ]}
              toggleFilter={toggleOption}
              selectedFilterOption={option}
              currentFilterOption={"TOKEN TYPE"}
              selectFilterOption={setTokenType}
              selectedOption={tokenType}
              label={"Token Type"}
            />
            <p className="mt-2 font-normal text-clrGray dark:text-clrPlaceholder md:text-x9 text-x10">
              What type of Token do you want to generate
            </p>
          </div>
          {tokenType === "Data" && (
            <div className="flex flex-wrap items-center justify-between md:flex-nowrap gap-y-6 gap-x-4">
              <div className="w-[80%]">
                <label
                  htmlFor="volume"
                  className="block mb-2 font-semibold text-black dark:text-white text-x10 md:text-xs"
                >
                  Data Volume
                </label>

                <input
                  type="text"
                  name="volume"
                  value={dataVolume}
                  onChange={(e) => setDataVolume(e.target.value)}
                  placeholder="Enter Data Volume Here"
                  className="text-clrGray dark:placeholder:!text-clrPlaceholder dark:text-clrPlaceholder dark:bg-transparent placeholder:text-x11 text-x11 pb-1 font-normal border-b-[1px] border-solid border-clrBorder5 w-full focus:outline-none"
                />
              </div>
              <div className="w-[25%]">
                <Filter
                  modalRef={modalRef}
                  options={[
                    { id: 1, value: "TB", enum: "TB" },
                    { id: 2, value: "GB", enum: "GB" },
                    { id: 3, value: "MB", enum: "MB" },
                  ]}
                  toggleFilter={toggleOption}
                  selectedFilterOption={option}
                  currentFilterOption={"DATA UNIT"}
                  selectFilterOption={setDataUnit}
                  selectedOption={dataUnit}
                  label={"Data Unit"}
                />
              </div>
            </div>
          )}

          {tokenType === "Timed" && (
            <>
              <div className="space-y-6">
                <div className="flex items-center gap-8">
                  <label
                    htmlFor=""
                    className="block mr-3 font-semibold text-black dark:text-white text-x10 md:text-xs"
                  >
                    Validity
                  </label>
                  <div
                    className="relative flex items-center gap-2 radio-input"
                    onClick={() => {
                      setTokenValidity("days");
                      setValidityPeriod("");
                    }}
                  >
                    <input
                      type="radio"
                      name="validity"
                      id=""
                      className=""
                      checked={tokenValidity === "days"}
                    />
                    {tokenValidity === "days" && (
                      <div className="circle !left-[0.19em] !top-[0.2em] !absolute"></div>
                    )}
                    <label
                      htmlFor=""
                      className="block font-medium text-clrGray dark:text-clrPlaceholder text-x10 md:text-xs"
                    >
                      Days
                    </label>
                  </div>
                  <div
                    className="relative flex items-center gap-2 radio-input"
                    onClick={() => {
                      setTokenValidity("hrs");
                      setValidityPeriod("");
                    }}
                  >
                    <input
                      type="radio"
                      name="validity"
                      id=""
                      className=""
                      checked={tokenValidity === "hrs"}
                    />
                    {tokenValidity === "hrs" && (
                      <div className="circle !left-[0.19em] !top-[0.2em] !absolute"></div>
                    )}
                    <label
                      htmlFor=""
                      className="block font-medium text-clrGray dark:text-clrPlaceholder text-x10 md:text-xs"
                    >
                      Hours
                    </label>
                  </div>
                </div>
                <div>
                  <Filter
                    modalRef={modalRef}
                    options={
                      tokenValidity === "days" ? renderDays() : renderHrs()
                    }
                    toggleFilter={toggleOption}
                    selectedFilterOption={option}
                    currentFilterOption={"VALIDITY"}
                    selectFilterOption={setValidityPeriod}
                    selectedOption={
                      !validityPeriod
                        ? "Select Validity Period"
                        : validityPeriod + " " + tokenValidity
                    }
                    label={""}
                  />
                  <p className="mt-2 font-normal text-clrGray dark:text-clrPlaceholder md:text-x9 text-x10">
                    How long do you want the token to last
                  </p>
                </div>
              </div>
              <div className="">
                <label
                  htmlFor="quantity"
                  className="block mb-2 font-semibold text-black dark:text-white text-x10 md:text-xs"
                >
                  Quantity
                </label>

                <input
                  type="text"
                  name="quantity"
                  onChange={(e) => setTokenQuantity(e.target.value)}
                  placeholder="how may tokens do you want to generate"
                  className="text-clrGray dark:text-clrPlaceholder dark:bg-transparent placeholder:text-x11 text-x11 pb-1 font-normal border-b-[1px] border-solid border-clrBorder5 placeholder:!text-clrPlaceholder dark:placeholder:text-clrPlaceholder w-full focus:outline-none"
                />
              </div>
            </>
          )}
          <ErrorMsg errMsg={errorMsg} />
        </div>

        <div className="w-4/5 mx-auto">
          <Button
            text="Generate"
            type="button"
            loading={isLoading}
            handleClick={handleGenerateTokens}
          />
        </div>
      </>
    </Modal>
  );
};

// Filter options
export const Filter: React.FC<DropdownContainerFilterProps> = ({
  options,
  toggleFilter,
  selectedOption,
  modalRef,
  selectFilterOption,
  selectedFilterOption,
  currentFilterOption,
  label,
}) => {
  return (
    <div className="relative mb-4 md:mb-0">
      <h4 className="mb-2 md:mb-2.5 font-semibold text-black dark:text-white text-x10 md:text-xs">
        {label}
      </h4>
      <button
        onClick={() => toggleFilter(currentFilterOption)}
        className="flex items-center justify-between relative w-full mt-[0.875rem] border-b-[1px] border-solid border-clrBorder5"
      >
        <p className="text-xs font-medium text-black dark:text-clrPlaceholder">
          {selectedOption}
        </p>
        <ArrowDown width={20} height={20} />
      </button>
      {selectedFilterOption === currentFilterOption && (
        <DropDownContainer
          list={options}
          modalRef={modalRef}
          closeDropdown={toggleFilter}
          handleSelect={selectFilterOption}
        />
      )}
    </div>
  );
};


interface TokenDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  tokenData?: Token | null;
}

const TokenDetails: React.FC<TokenDetailsProps> = ({
  isOpen,
  onClose,
  tokenData
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEnterPin, setIsEnterPin] = useState(false);
  const [isReasonToDeactiveOpen, setIsReasonToDeactiveOpen] = useState(false);
  const modalRefEnterPin = useRef<HTMLElement>(null);
  const [step, setStep] = useState<number>(1);
  const [pin, setPin] = useState<string>("");
  

  const handleCloseModal = () => {
    setIsEnterPin(false);
  };

  useOutsideClick(modalRefEnterPin, handleCloseModal);

  const handleNext = () => {
    setIsEnterPin(true);
  }

  //Set the User's pin
  const handlePin = (res: string) => {
    setPin(res);
  };

  //Send OTP to the user
  const handleSendOTP = () => {

    if (pin.length === 4) {
      setIsEnterPin(false);
      setIsReasonToDeactiveOpen(true)
    }
  };

  const modalRef = useRef<HTMLDivElement>(null);

  const handleDeactivateToken = () => {
    setIsEnterPin(true)
  };

  const formatValidityPeriod = (seconds: string) => {
    const duration = moment.duration(Number(seconds) || 0, 'seconds');
    if (Number(seconds) === 0) return "--";
    if (duration.days() > 31) return "Unlimited";
    
    let result = [];
    if (duration.days() > 0) {
      result.push(`${duration.days()} Day${duration.days() > 1 ? 's' : ''}`);
    }
    if (duration.hours() > 0) {
      result.push(`${duration.hours()} Hr${duration.hours() > 1 ? 's' : ''}`);
    }
    return result.join(' ');
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'active': return '#00C087';
      case 'exhausted': return '#DB5F5F';
      case 'inactive': return '#CFA809';
      default: return '#000000';
    }
  };

  const details = [
    {
      label: "Token",
      value: tokenData?.code
    },
    {
      label: "Assigned To",
      value: tokenData?.dataTokenOwner?.phoneNumber || tokenData?.dataTokenOwner?.email || 'Not Assigned'
    },
    {
      label: "Data Allocated",
      value: filesize(tokenData?.bytesAllocated || 0)
    },
    {
      label: "Data Used",
      value: filesize(tokenData?.bytesUsed || 0)
    },
    {
      label: "Validity Period",
      value: formatValidityPeriod(tokenData?.secondsAllocated || '0')
    },
    {
      label: "Usage Type",
      value: tokenData?.dataTokenUsageType
    },
    {
      label: "Status",
      value: tokenData?.voucherStatus,
      customStyle: { color: getStatusColor(tokenData?.voucherStatus || '') }
    }
  ];

  return (
    <>
      <div className={`fixed inset-0 z-50 ${isOpen ? 'visible' : 'invisible'}`}>
        <div
          className={`fixed inset-0 bg-black/30 transition-opacity duration-300 ${
            isOpen ? 'opacity-100' : 'opacity-0'
          }`}
          onClick={onClose}
        />
        <div
          ref={modalRef}
          className={`fixed top-0 right-0 z-50 h-screen w-full md:w-96 bg-white shadow-lg
            transform transition-all duration-300 ease-in-out overflow-y-auto py-10 px-[1.875rem] flex flex-col
            ${isOpen ? 'translate-x-0' : 'translate-x-full'}
          `}
        >
          <div className="flex items-center gap-3 pb-6">
            <button
              onClick={onClose}
              className="hover:bg-gray-100 p-1 rounded-full transition-colors"
            >
              <ArrowBack className="h-5 w-5" />
            </button>
            <h3 className="font-semibold text-[0.84rem] leading-[1.17rem] text-black">Token Details</h3>
          </div>
          <div className="space-y-4">
            {details.map((detail, index) => (
              <div
                key={`${detail.label}-${index}`}
                className="flex justify-between items-center pt-3 pb-4 border-b"
              >
                <span className="text-[0.656rem] leading-[0.98rem] text-clrGray font-medium">
                  {detail.label}
                </span>
                <span
                  className="text-[0.656rem] leading-[0.98rem] text-black font-semibold"
                  style={detail.customStyle}
                >
                  {detail.value}
                </span>
              </div>
            ))}
          </div>
          <div className="w-fit mt-auto flex justify-start">
             <Button
              type="button"
              text={"Deactiviate Token"}
              handleClick={handleDeactivateToken}
              fontWeight="font-normal"
              showIcon={true}
              // wBtn="!w-fit"
              px="md:!px-4"
              btnIcon={<CancelIcon className="h-[0.7rem] w-[0.7rem]" />}
              disabled={tokenData?.voucherStatus.toLowerCase() !== 'active'}
            />
          </div>
        </div>
      </div>
      {isEnterPin &&
        <EnterPin
          handleModal={handleCloseModal}
          handleContinue={handleSendOTP}
          handleChange={handlePin}
          errMsg={""}
          resendOtp={() => console.log("resend OTP")}
          loading={false}
        />
      }

      {isReasonToDeactiveOpen &&
        <ReasonToDeactive
          handleModal={() => setIsReasonToDeactiveOpen(false)}
          handleContinue={() => setIsReasonToDeactiveOpen(false)}
          handleChange={handlePin}
          errMsg={""}
          resendOtp={() => console.log("resend OTP")}
          loading={false}
        />
      }
      <ModalNew
        isOpen={isModalOpen}
        onClose={() => setIsEnterPin(false)}
        title="Enter Pin"
      >
        <div className="space-y-4">
          <p className="text-gray-600">Enter your pin to proceed</p>
          
          <div className="flex gap-2 justify-center">
            {[...Array(4)].map((_, i) => (
              <div
                key={i}
                className="w-12 h-12 border-2 border-gray-300 rounded-md flex items-center justify-center text-xl font-semibold"
              >
                {i === 0 ? '6' : ''}
              </div>
            ))}
          </div>

          <div className="text-center">
            <a href="#" className="text-blue-600 hover:underline">
              Forgot Pin?
            </a>
          </div>

          <button className="w-full py-3 bg-blue-900 text-white rounded-md hover:bg-blue-800">
            Continue
          </button>
        </div>
      </ModalNew>
    </>
  );
};

export default TokenDetails;


//Get User Pin here
export const ReasonToDeactive = ({
    handleModal,
    handleContinue,
    errMsg,
    handleChange,
}: ConfirmModalProps) => {
    return (
        <Modal
            close={true}
            title={"Reason for Deactivation"}
            description={"Please enter a short reason below"}
            handleCloseModal={handleModal}
            goBack={false}
        >
            <>
                <div className="flex flex-col gap-2 w-full p-4 bg-white rounded dark:bg-clrDarkBg">
                   <p className="font-medium text-[0.656rem] leading-4 text-clrGray">Reason</p>
                   <textarea name="" id="" className="rounded-xl bg-clrBg h-36 w-full text-x10 p-2" ></textarea>
                   <p className="font-medium text-[0.656rem] leading-4 text-clrGray">0/250 Words</p>
                </div>
                <div className="md:w-[80%] mt-8 mx-auto ">
                    <p className="mb-2 font-medium text-center text-x10 text-clrRed">
                        {errMsg}
                    </p>
                    <Button text="Submit" type="button" handleClick={handleContinue} />
                </div>
            </>
        </Modal>
    );
};

