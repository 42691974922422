import { useEffect, useRef, useState } from "react";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { MobileFilterSearch } from "./OptionsStatus";
import MintBag from "../../assets/icons/hotspot-hubs/bag.svg";
import TableMain from "../reusables/ReactDataTable";
import { sessionHistoryColumn } from "./ReactTableDataList";
import {
  useGetSessionsQuery,
} from "../../features/api/deviceSlice";
import { EmptyCard, Filter, TableLoader } from "../reusables";
import { calculateDateRange } from "../../Helper";


const SessionHistory = () => {
  const [option, toggleOption] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [searchValue, setSearch] = useState<string>("");
  const [duration, setDuration] = useState<string>("Last Week");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const [startDateTimeStamp, setStartDateTimestamp] = useState<string>("");
  const [endDatetimeStamp, setEndDateTimeStamp] = useState<string>("");

  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    toggleOption("");
  };

  useOutsideClick(modalRef, handleCloseModal);


  const handleDateRangeChange = (range: string) => {
    setDuration(range)
    const [newStartDate, newEndDate] = calculateDateRange(range);

    setStartDateTimestamp(newStartDate?.toString() || "");
    setEndDateTimeStamp(newEndDate?.toString() || "");
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    setStartDateTimestamp((selectedDate.getTime()).toString());
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    setEndDateTimeStamp((selectedDate.getTime()).toString());
  };


  const { data, isLoading, isFetching } = useGetSessionsQuery({
    isActive: false,
    page,
    pageSize: 30,
    startDateTimeStamp: startDateTimeStamp,
    endDatetimeStamp: endDatetimeStamp,
    deviceName: searchValue
  });


  useEffect(() => {
    handleDateRangeChange("Last Week")
  }, []);

  return (
    <section className="w-[96%] mx-auto py-6 overflow-y-auto">
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-8">
        <h3 className="text-sm font-semibold text-[#6b6b6b] dark:text-clrPlaceholder mb-8">
          Track your hub's data status
        </h3>
        <div className="flex flex-wrap items-center gap-4 mb-8 md:flex-nowrap">
          <MobileFilterSearch>
            <Filter
              modalRef={modalRef}
              options={[
                "Last Week",
                "Last Month",
                "Last 3 Months",
                "Last Year",
                "Custom",
              ]}
              toggleFilter={toggleOption}
              selectedFilterOption={option}
              currentFilterOption={"DURATION"}
              selectFilterOption={handleDateRangeChange}
              selectedOption={duration}
              label={"Duration"}
            />
            {duration === "Custom" &&
              <>
                <div className="relative">
                  <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel">
                    Start Date
                  </h4>
                  <input
                    type="date"
                    className="w-full md:w-52 text-x10 text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x9 bg-inherit"
                    value={startDate}
                    onChange={(e) => handleStartDateChange(e)}
                  />
                </div>
                <div className="relative">
                  <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel">
                    End Date
                  </h4>
                  <input
                    type="date"
                    className="w-full md:w-52 text-x10 border-[1px] text-clrPlaceholder border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x9 bg-inherit"
                    value={endDate}
                    onChange={(e) => handleEndDateChange(e)}
                  />
                </div>
              </>
            }
          </MobileFilterSearch>
          <div className="flex-1 md:flex-auto ">
              <label
                htmlFor=""
                className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
              >
                Search
              </label>
              <input
                type="text"
                name=""
                id=""
                value={searchValue}
                onChange={(e)=> setSearch(e.target.value)}
                placeholder="Hub Name"
                className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
              />
            </div>
        </div>
        
        <div>
          {isLoading || isFetching ? (
            <TableLoader />
          ) : (
            <TableMain
              defaultData={data?.data?.records || []}
              columns={sessionHistoryColumn}
              totalPages={data?.data.totalPages || 0}
              pageSize={data?.data.pageSize || 0}
              currentPage = {data?.data.currentPage || 0}
              setPage={setPage}
            />
          )}
        </div>
        {!isLoading && !isFetching && data?.data.records.length === 0 && (
          <div className="flex flex-col items-center justify-center">
            <EmptyCard description="No result found" />
            <a
              href="https://nft.wicrypt.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex justify-between items-center w-fit h-8 mt-3 px-4 gap-3 bg-clrDarkYellow rounded-[28px] transition duration-500 ease-in-out transform hover:bg-[#e3be27] hover:scale-[0.95]"
            >
              <img src={MintBag} alt="Mint Hub" width={18} height={18} />
              <p className="text-xs font-medium text-white">{"Mint Hub"}</p>
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

export default SessionHistory;
