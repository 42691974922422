import { useState } from "react";
import { Button, EmptyCard, ErrorMsg, TableLoader } from "../reusables";
import { Modal } from "../reusables/Modal";
import {
  useGetDevicesToSyncQuery,
  useSyncDeviceMutation,
  useUnsyncDeviceMutation,
} from "../../features/api/explorerSlice";
import { ReactComponent as UnsyncIcon } from "../../assets/icons/unsync.svg";

export const SyncDevices: React.FC<{
  handleCloseModal: () => void;
  tokenId: number;
}> = ({ handleCloseModal, tokenId }) => {
  const [active, setActive] = useState<string>("");
  const [searchParam, setSearchValue] = useState<string>("");
  const [errMsg, setError] = useState<string>("");

  //Sync device
  const [synDevice, { isLoading: synchingDevice }] = useSyncDeviceMutation();

  //Get devices to sync
  const { data: devicesToSync, isLoading: gettingDevicestoSync } =
    useGetDevicesToSyncQuery({
      tokenId: tokenId,
      searchParam: searchParam,
      pageNumber: 1,
      pageSize: 30,
    });

  //Function to handle synching devices to an NFT
  const handleSyncDevice = () => {
    if (active) {
      setError("");
      synDevice({ deviceId: active, tokenId })
        .unwrap()
        .then((res) => {
          if (res.success) {
            handleCloseModal();
          } else {
            setError(res.message);
          }
        })
        .catch((err) => {
          setError(err?.data?.message);
        });
    } else {
      setError("Please select a hub to sync");
    }
  };

  return (
    <Modal
      close={true}
      title="Sync Hub"
      description="Select your preferred physical hub to sync"
      goBack={false}
      handleCloseModal={handleCloseModal}
    >
      <>
        <input
          type="text"
          name="seachdevice"
          id="searchdevice"
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search Hub name"
          className="w-full md:w-fit block text-xs font-medium text-clrGray mb-5 border-[1px] border-solid border-clrPlaceholder dark:border-clrGray rounded-3xl h-7 pb-1 px-4 placeholder:!text-clrPlaceholder placeholder:text-[10px] bg-inherit"
        />
        <div className="space-y-2 radio-input">
          {gettingDevicestoSync ? (
            <TableLoader />
          ) : (
            <>
              {devicesToSync?.data.records.map((device) => (
                <button
                  key={device?.id}
                  onClick={() => setActive(device?.id)}
                  className={`${
                    active === device?.id ? "border-clrYellow border-[1px]" : ""
                  } flex gap-4 items-center justify-between w-full p-2.5 md:p-4 bg-white dark:bg-clrDarkBg rounded `}
                >
                  <div className="w-10 h-10 flex items-center justify-center rounded-md bg-[#d3d3d3]">
                    <img
                      src={device?.imageUrl}
                      alt="more options"
                      width={35}
                      height={35}
                    />
                  </div>
                  <h3 className="mr-auto font-medium text-left text-black dark:text-white text-x10 md:text-xs">
                    {device?.name}
                  </h3>
                  <input
                    type="radio"
                    id="value-1"
                    name="devices"
                    value="value-1"
                  />
                  <div className="circle"></div>
                </button>
              ))}
            </>
          )}
          <ErrorMsg errMsg={errMsg} />
          {!gettingDevicestoSync && !devicesToSync?.data.records.length ? (
            <EmptyCard description="No Hubs to sync" />
          ) : (
            <div className="mt-3">
              <Button
                handleClick={handleSyncDevice}
                text="Sync"
                type="button"
                wBtn="w-fit"
                loading={synchingDevice}
              />
            </div>
          )}
        </div>
      </>
    </Modal>
  );
};

export const UnSync: React.FC<{
  handleCloseModal: () => void;
  tokenId: number;
}> = ({ handleCloseModal, tokenId }) => {
  const [unsynDevice, { isLoading: unsynchingDevice }] =
    useUnsyncDeviceMutation();
  const [errMsg, setError] = useState<string>("");

  const handleUnsuncDevice = () => {
    unsynDevice(tokenId)
      .unwrap()
      .then((res) => {
        if (res.success) {
          handleCloseModal();
        } else {
          setError(res.message);
        }
      })
      .catch((err) => {
        setError(err?.data?.message);
      });
  };

  return (
    <Modal
      close={false}
      title=""
      description=""
      goBack={false}
      handleCloseModal={handleCloseModal}
    >
      <div>
        <div className="flex flex-col items-center justify-center gap-4 px-3 py-8 mb-8 bg-white rounded dark:bg-clrDarkBg">
          <UnsyncIcon />
          <div className="mb-4 text-center">
            <h2 className="mb-3 text-sm font-semibold text-black dark:text-white md:text-base">
              Unsync Hub
            </h2>
            <p className="text-x9 md:text-x10 font-medium text-clrGray66 dark:text-clrPlaceholder w-[90%] md:w-[60%] mx-auto">
              You are about to disconnect this NFT from a hotspot hub. Do you
              want to proceed
            </p>
          </div>
          <ErrorMsg errMsg={errMsg} />
        </div>

        <div className="md:w-[80%] mt-6 mx-auto ">
          <Button
            type="button"
            text="Unsync Hub"
            loading={unsynchingDevice}
            handleClick={handleUnsuncDevice}
          />
        </div>
      </div>
    </Modal>
  );
};
