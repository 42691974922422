import React, { useState } from "react";
import { EXPLORER_ONBOARDING_DATA } from "./OnboardingData";
import { Button, ButtonOutlineWallet } from "../reusables";

interface OnboardProps {
  totalSteps: number;
  currentStep: number;
  goToStep: (stepIndex: number) => void;
}

export const Onboarding: React.FC<OnboardingProps> = ({ handleGetStarted }) => {
  const [carolIndex, setCarolIndex] = useState<number>(0);

  const handleClick = (n: number) => {
    setCarolIndex((prevState) => {
      let newIndex = prevState + n;
      if (newIndex < 0) {
        newIndex = EXPLORER_ONBOARDING_DATA.length - 1;
      }
      if (newIndex > EXPLORER_ONBOARDING_DATA.length - 1) {
        newIndex = 0;
      }

      return newIndex;
    });
  };

  return (
    <div>
      <section className="fixed inset-0 z-20 h-screen overflow-hidden dark:bg-#00000080 bg-[#00000033] flex justify-center items-center">
        <div className="bg-white dark:bg-clrDarkBg2 rounded-[1.25rem] px-5 py-10 w-4/5 md:w-[25rem]">
          <div className="flex flex-col items-center justify-center gap-4 pt-5 bg-white rounded dark:bg-clrDarkBg2">
            <div className="flex items-center justify-center w-16 h-16 rounded-full bg-clrDarkYellow bg-opacity-30">
              <img
                src={EXPLORER_ONBOARDING_DATA[carolIndex].image}
                alt="Explorer"
                width={44}
                height={44}
                className="w-10 h-10 md:w-11 md:h-11"
              />
            </div>
            <div className="text-center">
              <h2
                className={`mb-3 text-sm md:text-base font-semibold text-black w-[90%] dark:text-white mx-auto`}
              >
                {EXPLORER_ONBOARDING_DATA[carolIndex].title}
              </h2>
              <p className="text-x9 md:text-x10 font-medium text-clrGray66 dark:text-clrPlaceholder w-[90%] md:w-[65%] mx-auto">
                {EXPLORER_ONBOARDING_DATA[carolIndex].details}
              </p>
            </div>
            <div className="flex justify-center gap-2 mt-4 mb-8 lg:justify-start">
              <div
                className={`${
                  carolIndex === 0 ? "bg-[#E5B910]" : ""
                } w-2 h-2 rounded-full bg-[#C4C4C4]`}
              ></div>
              <div
                className={`${
                  carolIndex === 1 ? "bg-[#E5B910]" : ""
                } w-2 h-2 rounded-full bg-[#C4C4C4]`}
              ></div>
              <div
                className={`${
                  carolIndex === 2 ? "bg-[#E5B910]" : ""
                } w-2 h-2 rounded-full bg-[#C4C4C4]`}
              ></div>
              <div
                className={`${
                  carolIndex === 3 ? "bg-[#E5B910]" : ""
                } w-2 h-2 rounded-full bg-[#C4C4C4]`}
              ></div>
              <div
                className={`${
                  carolIndex === 4 ? "bg-[#E5B910]" : ""
                } w-2 h-2 rounded-full bg-[#C4C4C4]`}
              ></div>
              <div
                className={`${
                  carolIndex === 5 ? "bg-[#E5B910]" : ""
                } w-2 h-2 rounded-full bg-[#C4C4C4]`}
              ></div>
            </div>
          </div>
          <div className="mx-auto space-y-4 md:w-4/5">
            {carolIndex === 5 ? (
              <Button
                type="button"
                text="Get Started"
                handleClick={() => {
                  handleGetStarted();
                }}
              />
            ) : (
              <Button
                type="button"
                text="Next"
                handleClick={() => handleClick(1)}
              />
            )}
            {!(carolIndex === 0) && (
              <ButtonOutlineWallet
                type="button"
                text="Previous"
                handleClick={() => handleClick(-1)}
                wBtn="w-full"
                py="py-2"
              />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
const CustomIndicators: React.FC<OnboardProps> = ({
  totalSteps,
  currentStep,
  goToStep,
}) => {
  const renderIndicators = () => {
    const indicators = [];
    for (let i = 0; i < totalSteps; i++) {
      const isActive = i === currentStep;
      indicators.push(
        <button
          key={i}
          className={`indicator-button ${isActive ? "active" : ""}`}
          onClick={() => goToStep(i)}
        >
          {i + 1}
        </button>
      );
    }
    return indicators;
  };

  return <div className="indicators-container">{renderIndicators()}</div>;
};
