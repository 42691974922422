import { useParams } from 'react-router-dom';
import { SpeedDetail } from './common';
import speedImg from '../../assets/icons/speed2.svg';
import uploadImg from '../../assets/icons/upload.svg';
import downloadImg from '../../assets/icons/download.svg';
import { useGetDeviceBandwidthInfoQuery } from '../../features/api/analyticsSlice';




const Speed = () => {
  const {analyticId } = useParams();
  const {data: bandwidthInfo} = useGetDeviceBandwidthInfoQuery(analyticId || '', {skip: !analyticId})
  const averageSpeed = bandwidthInfo?.data?.averageSpeed || "0 Mbps";

  // Split the string into the number and the unit
  const [speedValue, speedUnit] = averageSpeed.split(" ");
  return (
    <section className="w-[96%] mx-auto pb-6 overflow-y-auto">
      <div className="px-3 py-4 my-4 bg-white rounded-lg dark:bg-clrDarkBg md:px-6 md:pt-6 md:pb-3">
        <h3 className="text-sm md:text-base font-semibold text-clrGray dark:text-white">
          Performance{' '}
        </h3>
        <div className="border border-[#C4C4C433] p-5 rounded-lg w-full md:w-[386px] xl:w-[40%] mx-auto my-10">
          <img alt={'speed'} src={speedImg} className="mx-auto flex" />
          <p className="text-sm text-clrGray dark:text-clrPlaceholder text-center mt-3">{bandwidthInfo?.data?.speedCategory}</p>
          <div className="text-black dark:text-white text-lg font-medium items-center flex gap-2 justify-center mt-4 mb-6">
            <span className="text-5xl">{speedValue}</span>{speedUnit}
          </div>
          <div className="card-details grid sm:grid-cols-2 gap-3">
            <SpeedDetail
              imgSrc={downloadImg}
              title="Download Speed"
              value={bandwidthInfo?.data?.downloadSpeed || ""}
            />
            <SpeedDetail
              imgSrc={uploadImg}
              title="Upload Speed"
              value={bandwidthInfo?.data?.uploadSpeed || ""}
            />
          </div>
        </div>{' '}
      </div>
    </section>
  );
};

export default Speed;
