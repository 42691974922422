import { ethers } from "ethers";
import abi from "./methods/reward.json";
import {getGasFee} from "./methods/gasFee";
import { IHubFormQuestion } from "./components/hubForm/FormContent";


/**
 * Validates an email address.
 * @param value - The email address to validate.
 * @returns A boolean indicating whether the email address is valid.
 */
export const validateEmail = (value: string): boolean => {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(value);
};

/**
 * Rounds a number to a specified number of decimal places.
 * @param num - The number to round.
 * @param dec - The number of decimal places to round to.
 * @returns The rounded number.
 */
export const preciseRound = (num: number, dec: number): number => {
  const numSign = num >= 0 ? 1 : -1;
  return Number((Math.round(num * Math.pow(10, dec) + numSign * 0.0001) / Math.pow(10, dec)).toFixed(dec));
};

/**
 * Copies the specified text to the clipboard.
 * @param text - The text to be copied.
 */
export const copyToClipboard = (text: string): void => {
  const textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};

/**
 * Modifies the text by adding ellipsis (...) if its length exceeds the specified limit.
 * @param text - The original text.
 * @param noToView - The maximum number of characters to display before adding ellipsis.
 * @returns The modified text with ellipsis if necessary.
 */
export const sliceText = (text: string, noToView: number): string => {
  if (text.length > noToView) {
    let formattedText = text.slice(0, noToView) + "...";
    return formattedText;
  }
  return text;
};


/**
 * Format a number with commas as thousands separators and customizable decimal and thousand separators.
 * @param n - The number to format.
 * @param c - The number of decimal places (default: 2).
 * @param d - The decimal separator (default: ".").
 * @param t - The thousand separator (default: ",").
 * @returns The formatted number as a string.
 */

export function numberWithCommasWithoutDecimal(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

 export const numberWithCommas = (
  n: number,
  c: number = 2,
  d: string = ".",
  t: string = ","
): string => {
  c = isNaN(Math.abs(c)) ? 2 : Math.abs(c);
  d = d === undefined ? "." : d;
  t = t === undefined ? "," : t;
  let s = n < 0 ? "-" : "";
  let i = String(parseInt((Math.abs(Number(n)) || 0).toFixed(c), 10));
  let j = i.length > 3 ? i.length % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - parseFloat(i))
          .toFixed(c)
          .slice(2)
      : "")
  );
};

export const renderDays = () => {
  var arr = [];
  for (let i = 1; i < 32; i++) {
    let title = i > 1 ? " days" : " day"
    arr.push({id: i, value: i + title, enum: i});
  }
  arr.push({id: arr.length + 1, value: "Unlimited", enum: "Unlimited"});
  return arr;
};

export const renderHrs = () => {
  var arr = [];
  for (let i = 1; i < 25; i++) {
    let title = i > 1 ? " hrs" : " hr"
    arr.push({ id: i, value : i +  title, enum: i});
  }
  return arr;
};

//Get Reward from Contract
export const withdrawablereward = (holderId: string) => {
  return async () => {
    let withdrawables = await walletInfo(holderId);
    return Number(withdrawables[1]) / Math.pow(10, 18);
  };
};

/**
 * This function is used to create contracts web3 objects used for calling contract methods.
 * @param abi
 * @param contractAddress
 */


 interface CustomWindow extends Window {
  ethereum?: any;
}

 async function createContract(abi : any, contractAddress: string) {
  const provider = new ethers.BrowserProvider((window as CustomWindow).ethereum);
  const signer = await provider.getSigner();
  return new ethers.Contract(contractAddress, abi, signer);
}

//Get wallet info
export const walletInfo = async(holderId: string) => {
  try {
    const contract = await createContract(
      abi,
      process.env.REACT_APP_REWARD_CONTRACT as string
    );
    let rewards = await contract.getHolderReward(holderId);
    return rewards;
  } catch (err) {
    return [];
  }
}

//Withdraw claim
export async function withdrawClaims(address: string) {
  try {
    const contract = await createContract(
      abi,
      process.env.REACT_APP_REWARD_CONTRACT as string
    );
    let gasFee = await getGasFee();
    let claim = await contract.claimReward({ from: address, gasPrice: gasFee });

    return claim;
  } catch (err) {
    return [];
  }
}

//Open Camers
export const openCamera = () => {
  return async () => {
    let stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false
    });
    let videotostream = window.document.getElementById("videocamera") as HTMLVideoElement;
    videotostream.srcObject = stream;
    return stream;
  };
};

//Take a picture 

export const takePicture = (stream: MediaStream) => {
  return (): string => {
    const canvas = document.getElementById("captureImage") as HTMLCanvasElement | null;
    const video = document.getElementById("videocamera") as HTMLVideoElement | null;

    if (!canvas || !video) {
      throw new Error("Canvas or video element not found.");
    }

    const context = canvas.getContext("2d");
    if (!context) {
      throw new Error("Canvas context not available.");
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const image_data_url = canvas.toDataURL("image/jpeg");

    video.srcObject = null;

    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
    
    return image_data_url;
  };
};

export function calculateDateRange(range: string): [number | null, number | null] {
  const currentDate = new Date();
  const currentTimestamp = Math.floor(currentDate.getTime());

  let newStartDate: number | null = null;
  let newEndDate: number | null = null;

  switch (range) {
    case 'All':
      newStartDate = null;
      newEndDate = null;
      break;
    case 'Last Week':
      const lastWeekStart = new Date(currentDate);
      lastWeekStart.setDate(currentDate.getDate() - 7);
      newStartDate = Math.floor(lastWeekStart.getTime());
      newEndDate = currentTimestamp;
      break;
    case 'Last Month':
      const lastMonthStart = new Date(currentDate);
      lastMonthStart.setMonth(currentDate.getMonth() - 1);
      newStartDate = Math.floor(lastMonthStart.getTime());
      newEndDate = currentTimestamp;
      break;
    case 'Last 3 Months':
      const lastThreeMonthsStart = new Date(currentDate);
      lastThreeMonthsStart.setMonth(currentDate.getMonth() - 3);
      newStartDate = Math.floor(lastThreeMonthsStart.getTime());
      newEndDate = currentTimestamp;
      break;
    case 'Last Year':
      const lastYearStart = new Date(currentDate);
      lastYearStart.setFullYear(currentDate.getFullYear() - 1);
      newStartDate = Math.floor(lastYearStart.getTime());
      newEndDate = currentTimestamp;
      break;
    default:
      break;
  }

  return [newStartDate, newEndDate];
}

type MonthMap = {
  [key: number]: string;
};

export const  months: MonthMap = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec"
}


export function convertTo12HourFormat(time24: string | undefined) {
  if (time24) {
    // Extract hour and minute from the 24-hour time
    const [hour24, minute]: any = time24.split(':').map(Number);

    // Determine the period (AM or PM)
    const period = hour24 >= 12 ? 'PM' : 'AM';

    // Convert hour to 12-hour format
    let hour12 = hour24 % 12 || 12;

    // Pad single-digit hour with a leading zero
    if (hour12 < 10) {
      hour12 = Number(`0${hour12}`);
    }

    // Construct the 12-hour time format
    const time12 = `${hour12}:${minute < 10 ? '0' : ''}${minute} ${period}`;

    return time12;
  } else {
    return time24;
  }
}


// Function to process array the way to send to the backend
export function processArray(formFields: IHubFormQuestion[]) {
  // Check if the last object in the formFields has a value in the label property
  if (formFields.length > 0 && !formFields[formFields.length - 1]?.label) {
    // Remove the last object from the formFields
    formFields.pop();
  }
  // Split the formFields into arrays with max of 4 items each
  const result = [];
  for (let i = 0; i < formFields.length; i += 4) {
    result.push(formFields.slice(i, i + 4));
  }
  return result;
}

export const monthsOfYear = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const convertToCSV = (items: any[]): string => {
  const header = Object.keys(items[0]); // Get the table headers from the keys of the first item
  const csvRows = items.map(row => 
    header.map(fieldName => JSON.stringify(row[fieldName], (_, val) => val ?? '')).join(',')
  );
  
  return [header.join(','), ...csvRows].join('\n'); // Combine header and rows
};


export const exportTableData = (tableData: any, fileName: string) => {
  const csvData = convertToCSV(tableData);
  
  // Create a blob from the CSV data
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  
  // Create a temporary link element for downloading
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  
  link.click();
  document.body.removeChild(link); // Clean up
};

/** 
 * Generates a random shade of the base color #FFE582 (RGB: 255, 229, 130).
 * You can tweak the shade by increasing or decreasing the brightness factor.
 */
export const generateRandomShadeOfColor = (brightnessFactor = 0.8) => {
  // Base color #FFE582 in RGB
  const baseR = 255;
  const baseG = 229;
  const baseB = 130;

  // Randomly vary the brightness (darker or lighter)
  const shadeFactor = Math.random() * (1 - brightnessFactor) + brightnessFactor;

  // Apply the shade factor to each color channel (r, g, b)
  const r = Math.floor(baseR * shadeFactor);
  const g = Math.floor(baseG * shadeFactor);
  const b = Math.floor(baseB * shadeFactor);

  // Optionally generate a random alpha (transparency) value between 0 and 1
  const a = Math.random().toFixed(2);

  // Return the RGBA string
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};


export const handlePaste = async () => {
  try {
    const clipboardText = await navigator.clipboard.readText();
    return clipboardText;
  } catch (err) {
    console.error('Failed to read clipboard contents: ', err);
  }
};