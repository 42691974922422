import SecondaryNav from "../reusables/SecondaryNav";
import { useParams } from "react-router";

const DeviceName = () => {

    //Get the tokenid from the route
    const { tokenId } = useParams();

  return (
    <>
      <SecondaryNav
        paths={[
          {
            path: `/home/explorer/devices-name/${tokenId}/statistics`,
            title: "Statistics",
          },
          {
            path: `/home/explorer/devices-name/${tokenId}/transactions`,
            title: "Transactions",
          },
        ]}
      />
    </>
  );
};

export default DeviceName;
