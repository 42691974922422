import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import ProtectedRoute from "./protectedRoute";
import Login from "../components/auth/logIn/Login";
import SignUp from "../components/auth/signUp";
import Reset from "../components/auth/Reset";
import ResetPin from "../components/auth/ResetPin";
import Home from "../components/home";
import Dashboard from "../components/home/Dashboard";
import HotspotHubs from "../components/hotspotHubs/index";
import MyHubs from "../components/hotspotHubs/MyHubs";
import LiveSession from "../components/hotspotHubs/LiveSession";
import HubsReport from "../components/hotspotHubs/HubsReport";
import DeviceTracker from "../components/hotspotHubs/DeviceTracker";
import SessionHistory from "../components/hotspotHubs/SessionHistory";
import Wallet from "../components/wallet";
import TransactionHistory from "../components/wallet/transactionHistory";
import P2PWNT from "../components/wallet/P2PFlow";
import AddBankDetails from "../components/wallet/AddBankDetails";

import Booster from "../components/booster";
import RewardBooster from "../components/booster/BoosterOverview";
import BoosterHistory from "../components/booster/BoosterHistory";
import { logOut, setUser, saveWalletDetails } from "../features/store/authReducerSlice";
import Explorer from "../components/explorer";
import Overview from "../components/explorer/Overview";
import StakeMain from "../components/wallet/StakeMain";
import WithdrawP2P from "../components/wallet/WithdrawP2P";
import CryptoWithdraw from "../components/wallet/CryptoWithdraw";
import BoosterFaqs from "../components/booster/BoosterFaq";
import ClaimHistory from "../components/explorer/ClaimHistory";
import RewardHistory from "../components/booster/RewardHistory";
import SessionName from "../components/explorer/SessionName";
import DeviceName from "../components/explorer/DevicesName";
import Transaction from "../components/explorer/Transaction";
import Statistics from "../components/explorer/Statistics";
import ExplorerHotspotHubs from "../components/explorer/HotspotHubs";
import Voucher from "../components/voucher";
import Profile from "../components/profile";
import ProfileInfo from "../components/profile/ProfileInfo";
import ProfileSettings from "../components/profile/ProfileSettings";
import VerifyID from "../components/profile/VerifyID";
import Referrals from "../components/referrals";

//My Cluster
import Cluster from '../components/cluster';

import Token from "../components/token";
import BusinessReg from "../components/home/BusinessReg";
import Agents from "../components/agents";
import AgentsDetails from "../components/agents/AgentsDetails";
import LogOut from "../components/logOut";
import ParentalControl from "../components/parentalControl";
import ParentalControlSetup from "../components/parentalControl/Setup";
import BlacklistedList from "../components/parentalControl/BlacklistedList";
import Subscription from "../components/subscription";
import SelectedPlan from "../components/subscription/SelectedPlan";
import HubForm from "../components/hubForm";
import { ViewTemplate } from "../components/hubForm/ViewTemplate";
import FormContent from "../components/hubForm/FormContent";
import NetworkFeedbackForm from "../components/hubForm/NetworkFeedForm";
import Responses from "../components/hubForm/Responses";
import Content from "../components/hubForm/Content";
import TrafficDetails from "../components/analytics/TrafficDetails";
import AnalyticsDetails from "../components/analytics/AnalyticsDetails";
import Connections from "../components/analytics/Connections";
import Uptime from "../components/analytics/Uptime";
import DataUsage from "../components/analytics/DataUsage";
import Speed from "../components/analytics/Speed";
import TrafficAnalytics from "../components/analytics/Traffic";
import ConnectionDetails from "../components/analytics/ConnectionDetails";
import Analytics from "../components/analytics";
import AdvertControl from '../components/advertControl';
import AdvertControlOverview from '../components/advertControl/Overview';
import AdvertControlShare from '../components/advertControl/Share';
import AdvertControlCaption from '../components/advertControl/Caption';
import HubSetup from "../components/home/HubSetup";

//TODO: Figure out why private route doesn't work as expected. protect routes

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const walletDetails = JSON.parse(localStorage.getItem("walletDetails") || "{}")

  useEffect(() => {
    dispatch(setUser(user));
    if (user && user.token) {
      let expiresIn = user.expires;
      let today = Math.round(new Date().getTime() / 1000);
      if (expiresIn <= today) {
        dispatch(logOut());
      }
    }
    if(walletDetails){
      dispatch(saveWalletDetails(walletDetails))
    }
  }, []);

  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path={"/"} element={<Login />} />
        <Route path={"/signup"} element={<SignUp />} />
        <Route path={"/reset"} element={<Reset />} />
        <Route path={"/passwords/reset"} element={<ResetPin />} />
        {/* Private Routes */}
        <Route
          path={"/home"}
          element={
            <ProtectedRoute redirectPath="/">
              <Home />
            </ProtectedRoute>
          }
        >
          <Route path={"/home"} element={<Navigate replace to="dashboard" />} />
          <Route path={"dashboard"} element={<Dashboard />} />
          <Route path={'dashboard/hub-setup'} element={<HubSetup />} />
          <Route path={"dashboard/business-reg"} element={<BusinessReg />} />
          <Route path={"hotspot-hubs"} element={<HotspotHubs />}>
            <Route
              path={"/home/hotspot-hubs"}
              element={<Navigate replace to="my-hubs" />}
            />
            <Route path={"my-hubs"} element={<MyHubs />} />
            <Route path={"live-session"} element={<LiveSession />} />
            <Route path={"hubs-report"} element={<HubsReport />} />
          </Route>
          <Route
            path="hotspot-hubs/session-history"
            element={<SessionHistory />}
          />
          <Route
            path="hotspot-hubs/device-tracker"
            element={<DeviceTracker />}
          />
          <Route path="wallet" element={<Wallet />} />
          <Route
            path="wallet/transactionHistory"
            element={<TransactionHistory />}
          />
          <Route path="wallet/peer-to-peer" element={<P2PWNT />} />
          <Route path="wallet/withdraw-P2P" element={<WithdrawP2P />} />
          <Route path="wallet/crypto-withdraw" element={<CryptoWithdraw />} />
          <Route path="wallet/add-bank-details" element={<AddBankDetails />} />
          <Route path={"explorer"} element={<Explorer />}>
            <Route
              path={"/home/explorer"}
              element={<Navigate replace to="overview" />}
            />
            <Route path={"overview"} element={<Overview />} />
            <Route path={"hotspot-hubs"} element={<ExplorerHotspotHubs />} />
            <Route path={"claim-history"} element={<ClaimHistory />} />
          </Route>
          <Route
            path={"explorer/devices-name/:tokenId"}
            element={<DeviceName />}
          >
            <Route
              path={"/home/explorer/devices-name/:tokenId"}
              element={<Navigate replace to="statistics" />}
            />
            <Route path={"statistics"} element={<Statistics />} />
            <Route path={"transactions"} element={<Transaction />} />
          </Route>
          <Route path={"explorer/session-name/:sessionId"} element={<SessionName />} />
          <Route path={'parental-control'} element={<ParentalControl />} />
          <Route path="cluster" element={<Cluster />} />
          <Route
            path="parental-control/:deviceId/:deviceName"
            element={<ParentalControlSetup />}
          />
          <Route
            path="parental-control/:deviceId/:deviceName/:category/:categoryId"
            element={<BlacklistedList />}
          />
           <Route path="subscription" element={<Subscription />} />
          <Route path={'subscription-plan'} element={<SelectedPlan />} />
          <Route
            path={'subscription/:planId/:billingType'}
            element={<SelectedPlan />}
          />
           <Route path={'hub-form'} element={<HubForm />} />
          <Route path={'hub-form/form-template'} element={<ViewTemplate />} />
          <Route
            path={'hub-form/form-content/:formName/:formId?'}
            element={<FormContent />}
          />
          <Route path={'hub-form/detail'} element={<NetworkFeedbackForm />}>
            <Route
              path={'/home/hub-form/detail'}
              element={<Navigate replace to="content" />}
            />
            <Route path={':formId'} element={<Content />} />
            <Route path={'responses/:formId'} element={<Responses />} />
          </Route>
          <Route path={"booster"} element={<Booster />}>
            <Route
              path={"/home/booster"}
              element={<Navigate replace to="overview" />}
            />
            <Route path={"overview"} element={<RewardBooster />} />
            <Route path={"history"} element={<BoosterHistory />} />
          </Route>
          <Route path={'advert-control'} element={<AdvertControl />}>
            <Route path="overview" element={<AdvertControlOverview />} />
            <Route path="share" element={<AdvertControlShare />} />
            <Route path="caption" element={<AdvertControlCaption />} />
          </Route>
          <Route path={"vouchers"} element={<Voucher />} />
          <Route
            path={"booster/history/:cycleId"}
            element={<RewardHistory />}
          />
          <Route path="booster/booster-faq" element={<BoosterFaqs />} />
          <Route path="wallet/stake" element={<StakeMain />} />
          <Route path={'profile'} element={<Profile />}>
            <Route path="info" element={<ProfileInfo />} />
            <Route path="settings" element={<ProfileSettings />} />
          </Route>
          <Route path="profile/verifyID" element={<VerifyID />} />
          <Route path="referrals" element={<Referrals />} />
          <Route path="token" element={<Token />} />
          <Route path="agents" element={<Agents />} />
          <Route path={'analytics'} element={<Analytics />} />
          <Route
            path="agents/:businessUserId/:agentName/:businessId"
            element={<AgentsDetails />}
          />
            <Route
            path={'analytics/traffic/:trafficId/:analyticName/:trafficCategory'}
            element={<TrafficDetails />}
          />
          <Route path={'analytics'} element={<AnalyticsDetails />}>
            <Route
              path={'connections/:analyticId/:analyticName'}
              element={<Connections />}
            />
            <Route
              path={'uptime/:analyticId/:analyticName'}
              element={<Uptime />}
            />
            <Route
              path={'data-usage/:analyticId/:analyticName'}
              element={<DataUsage />}
            />
            <Route
              path={'speed/:analyticId/:analyticName'}
              element={<Speed />}
            />
            <Route
              path={'traffic/:analyticId/:analyticName'}
              element={<TrafficAnalytics />}
            />
          </Route>
          <Route
            path={'analytics/connections/:analyticId/:analyticName/:connectedUserId/:connectedUser'}
            element={<ConnectionDetails />}
          />
          <Route path="logOut" element={<LogOut />} />
        </Route>
        {/* Redirect to login page for unknown routes */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
