import { useRef, useState } from 'react';

import { AuthApp, AuthLoginApp } from './SecurityModals';

import { ButtonLoader, ErrorMsg } from '../reusables';
import { useNavigate } from 'react-router-dom';
import useOutsideClick from '../../Hooks/useOutsideClick';
import { useDeactivate2faMutation, useGetUserProfileQuery, useTwofaOptionsQuery } from '../../features/api/authSlice';
import { LanguageModal } from './LanguageModal';

const Security = () => {
    const user = JSON.parse(localStorage.getItem('mtn-user') || '{}');
    const navigate = useNavigate();

    const { data } = useTwofaOptionsQuery();
    const [deactivate2FA, { isLoading, isError }] = useDeactivate2faMutation();

    const modalRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [otp, setOtp] = useState('');
    const [isSecurityOpen, setIsSecurityOpen] = useState(false);

    const [isLanguageModal, setLanguageModal] = useState<boolean>(false);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    useOutsideClick(modalRef, handleCloseModal);

    const resetHandler = () => {
        !data?.data?.is2FaEnabled ? setShowModal(true) : deactivate2FA();
    };

    const handleLoginPin = () => {
        if (data?.data?.is2FaEnabled) {
            setIsSecurityOpen(true)
        } else {
            navigate('/home/profile/security/reset-password');
        }

    };

    const { data: userProfile } = useGetUserProfileQuery(user?.userId, {
        skip: !user?.userId,
    });


    useOutsideClick(modalRef, () => setLanguageModal(!isLanguageModal));

    const handleSettings = (action: string) => {
        switch (action) {
            case "Language": {
                setLanguageModal(true);
                break;
            }
            case "2fa": {
                resetHandler();
                break;
            }
            default: {
                handleLoginPin();
                break
            }

        }

    }



    const securityItem = (title: string, description: string, type: string, btnTitle: string, label?: string) => (
        <div className="grid lg:grid-cols-2 grid-cols-[1fr_30%] items-center justify-between gap-6 mb-4">
            <div>
                <h2 className="mb-1 text-base font-semibold text-black dark:text-white">
                    {title}
                    {label &&
                        <span
                            className='bg-clrGreen4 text-[10px] ml-2 rounded-lg text-center p-1 text-clrGreen2'>
                            {label}
                        </span>}
                </h2>
                <p className="text-[0.6875rem] font-normal text-clrGray66 dark:text-clrPlaceholder">
                    {description}
                </p>
            </div>
            <button
                className={`flex cursor-pointer justify-center items-center lg:w-[113px] w-fit text-center h-9 mt-3 px-6 gap-3  rounded-[28px] transition duration-500 ease-in-out transform  hover:scale-[0.95] ${data?.data?.is2FaEnabled && type === '2fa'
                        ? 'bg-clrRed3 hover:bg-[#DB5F5F]'
                        : 'bg-primary dark:bg-secondary hover:bg-[#e3be27]'
                    }`}
                onClick={() => handleSettings(type)}
                disabled={isLoading}
            >
                <p className="text-xs font-medium text-white">
                    {type === '2fa' && isLoading ? (
                        <ButtonLoader loading={isLoading} />
                    ) : data?.data?.is2FaEnabled && type === '2fa' ? (
                        'Deactivate'
                    ) : (
                        btnTitle
                    )}
                </p>
            </button>
        </div>
    );

    return (
        <section className="w-[96%] mx-auto mt-3.5 md:mt-3 mb-6 overflow-y-auto">

            <div className="bg-white dark:bg-clrDarkBg rounded-lg pt-[2.125rem] pb-36 grid space-y-8 font-medium">
                <div className='pl-11 pr-5 '>
                    <h2 className='text-clrGray dark:text-clrPlaceholder mb-4'> General</h2>
                    {securityItem(
                        'Language',
                        'Select a preferred  display language  for communication',
                        'Language',
                        "Change",
                        "English"
                    )}
                    {securityItem(
                        'TV Accounts',
                        'Manage your preferred TV account details ',
                        '2fa',
                        "Manage",
                        "4 Accounts"
                    )}
                </div>
                <div className='pl-11 pr-5 '>
                    <h2 className='text-clrGray dark:text-clrPlaceholder mb-4'> Security</h2>
                    {securityItem(
                        'Login PIN',
                        'Login password is used to log in to your account.',
                        'pin',
                        "Reset"
                    )}
                    {securityItem(
                        '2 Factor Authenticator',
                        'Secure your account with an authenticator app',
                        '2fa',
                        "Reset"
                    )}
                </div>


                {isError && <ErrorMsg errMsg={'Deactivation failed!'} />}
            </div>
            {showModal && (
                <AuthApp
                    handleModal={() => setShowModal(false)}
                    otp={otp}
                    setOtp={setOtp}
                    // timer={'55secs'}
                    formTitle={'Email Verification'}
                    description={`Enter the 6 digit code OTP sent to your email`}
                    email={`${userProfile?.data?.email}`}
                />
            )}

            {isSecurityOpen && (
                <AuthLoginApp
                    handleModal={() => setIsSecurityOpen(false)}
                    otp={otp}
                    setOtp={setOtp}
                    formTitle={'Authentication App'}
                    description={`Enter the 6 digit code generated by your authenticator app`}
                />
            )}
            {isLanguageModal && <LanguageModal modalRef={modalRef} handleCloseModal={() => setLanguageModal(!isLanguageModal)} />}
        </section>
    );
};

export default Security;

