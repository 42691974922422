import { useAppSelector } from "../../app/hooks";
import { Modal } from "./Modal";
import AuthCode from "react-auth-code-input";
import { Button } from ".";


//Modal to verify email by entering OTP
const VerifyPin = ({
    handleModal,
    handleContinue,
    handleChange,
    resendOtp,
    errMsg,
    loading,
  }: ConfirmModalProps) => {
    const { email } = useAppSelector((state) => state.auth);

    return (
      <Modal
        close={true}
        title={"Verify OTP"}
        description={`Please enter the six digit code we sent to : ${email}`}
        handleCloseModal={handleModal}
        goBack={false}
      >
        <>
          <div className="flex flex-col items-center justify-center w-full p-4 bg-white rounded dark:bg-clrDarkBg">
            <div className="mb-4 text-center">
              <label
                htmlFor="pin"
                className="block mb-4 font-bold leading-3 text-x10 text-clrTextBlue dark:text-clrPlaceholder md:text-sm"
              >
                Enter Code
              </label>
              <div className="mb-4">
                <AuthCode
                  inputClassName="w-10 h-10 text-center text-lg font-semibold text-primary border-transparent border-solid border rounded-md bg-primary/10"
                  containerClassName="flex justify-center gap-3"
                  ariaLabel="verify-otp"
                  isPassword={true}
                  autoFocus={true}
                  length={6}
                  allowedCharacters="numeric"
                  onChange={handleChange}
                />
              </div>
            </div>
            <p className="text-xs font-normal text-clrGray dark:text-clrPlaceholder ">
              Didn't receive an OTP?
            </p>
            <p
              className="underline mt-5 text-[10px] font-normal text-clrTextBlue dark:text-white cursor-pointer"
              onClick={resendOtp}
            >
              Resend OTP?
            </p>
          </div>
          <div className="md:w-[80%] mt-8 mx-auto ">
            <p className="mb-2 font-medium text-center text-x10 text-clrRed">
              {errMsg}
            </p>
            <Button
              loading={loading}
              disabled={loading}
              text="Submit"
              type="button"
              handleClick={handleContinue}
            />
          </div>
        </>
      </Modal>
    );
  };
  
export default VerifyPin