import { Card } from "../../reusables";
import { ButtonAuth } from "../../reusables/Button";
import { ReactComponent as SuccessCheck } from "../../../assets/auth/success.svg";
import AuthFrame from "../../reusables/AuthFrame";

interface SuccessProps {
  email: string;
  handleNext: () => void;
}

//Component rendered when user does not exist and can sign up
const Success = ({ email, handleNext }: SuccessProps) => {
  return (
    <div className="flex">
      <Card>
        <div className="relative flex justify-center items-center bg-white w-full max-w-[800px] mx-auto mb-12 md:mb-8 h-4/5 rounded-xl">
          <div className="flex flex-col items-center mt-8 mb-4">
            <SuccessCheck className="mb-6" />
            <div className="mb-6 space-y-5 text-center">
              <h1 className="text-xl font-medium  text-[#00C087]">
                Email Verified
              </h1>
              <p className="text-[0.625rem] md:text-base font-medium text-black">
                {email}
              </p>
              <p className="text-[0.625rem] md:text-sm font-medium text-[#666]">
                You're almost done, just a few steps away
              </p>
            </div>
            <div className="mb-8 w-full md:w-[90%] xl:w-[60%]">
              <ButtonAuth
                type="submit"
                text="Proceed"
                handleClick={handleNext}
              />
            </div>
          </div>
        </div>
      </Card>
      <AuthFrame />
    </div>
  );
};

export default Success;
