import { apiSlice } from "./apiSlice";
import {DashboardResponse, DashboardRevenue, LinkCodeResponse, MiningRewardResponse, RevenueRewardResponse} from "./homeSliceTypes";


const dashboardSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({

       //Get Dashboard Summary
        getDashboardSummary: build.query<DashboardResponse, void>({
            query: () => `/v4/users/dashboard`,
            providesTags: ['Agents', 'Vouchers', 'Agents', 'Devices']
        }),

        getUserRevenue: build.query<DashboardRevenue, void>({
            query: () => `/v4/users/dashboard/revenue`,
        }),

        getMiningReward: build.query<MiningRewardResponse, string>({
            query: (deviceId) => `/v4/miningReward?deviceId=${deviceId}`,
        }),
    
        getSessionHistory: build.query<RevenueRewardResponse, string>({
            query: (deviceId) => `/v4/sessions?deviceId=${deviceId}`,
        }),
        
         //Set Link Code
         linkCode: build.query<LinkCodeResponse, void>({
            query: () =>
                `/v4/hub-link-code`,
        }),
    

    }),
    overrideExisting: true,
})

export const { useGetDashboardSummaryQuery, useGetUserRevenueQuery, useGetMiningRewardQuery, useGetSessionHistoryQuery, useLinkCodeQuery} = dashboardSlice