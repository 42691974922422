import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { ReactComponent as LockedUpReward } from "../../assets/icons/locked-up-reward.svg";
import { ReactComponent as BoostReturns } from "../../assets/icons/boost-returns.svg";
import { ReactComponent as LockDuration } from "../../assets/icons/lock-duration.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right-yellow.svg";
import {
  useGetRewardBoosterDetailsQuery,
  useGetBoosterStatusQuery,
} from "../../features/api/rewardSlice";
import { Button, EmptyCard } from "../reusables";
import { ScaleLoader } from "react-spinners";
import { numberWithCommas } from "../../Helper";
import BoosterFlow from "./BoosterFlow";

//Component with the booster reward overview and modals for handling the booster details
const RewardBooster = () => {
  const [boosterOption, setBoosterOption] = useState<number>(0);

  const { id: userId } = useAppSelector((state) => state.auth);

  //Get the booster status
  const { data: boosterStatus } = useGetBoosterStatusQuery(userId);

  //Get details for the current booster
  const { data, isLoading, isError } = useGetRewardBoosterDetailsQuery(userId);

  return (
    <section className="w-[96%] mx-auto pb-6 bg-white dark:bg-clrDarkBg mt-3 overflow-y-auto">
      {!isError ? (
        <div className="grid grid-cols-1 gap-3 px-6 py-8 my-2 rounded-lg xl:px-20 xl:grid-cols-2 md:gap-4">
          <div className="flex items-center gap-2 md:gap-4 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-4">
            <div className="mr-auto">
              <p className="text-sm font-semibold text-clrTextLightGray dark:text-clrGray66">
                Total Locked up Reward
              </p>
              <p className="mt-3 mb-4 text-sm font-bold md:text-2xl text-clrTextGray dark:text-white ">
                {isLoading ? (
                  <ScaleLoader
                    loading={isLoading}
                    height={12}
                    width={2}
                    color={"#3d5170"}
                  />
                ) : (
                  numberWithCommas(data?.data.lockedReward || 0)
                )}
                <span className="text-xs"> WNT</span>
              </p>
            </div>
            <LockedUpReward />
          </div>
          <div className="flex items-center gap-2 md:gap-4 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-4">
            <div className="mr-auto">
              <p className="text-sm font-semibold text-clrTextLightGray dark:text-clrGray66">
                Boost Returns
              </p>
              <p className="mt-3 mb-4 text-sm font-bold md:text-2xl text-clrTextGray dark:text-white">
                {isLoading ? (
                  <ScaleLoader
                    loading={isLoading}
                    height={12}
                    width={2}
                    color={"#3d5170"}
                  />
                ) : (
                  numberWithCommas(data?.data.boostReward || 0)
                )}{" "}
                <span className="text-xs">WNT</span>
              </p>
            </div>
            <BoostReturns />
          </div>
          <div className="flex items-center gap-2 md:gap-4 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-4">
            <div className="mr-auto">
              <p className="text-sm font-semibold text-clrTextLightGray dark:text-clrGray66">
                Lock Duration
              </p>
              <p className="mt-3 mb-4 text-sm font-bold md:text-2xl text-clrTextGray dark:text-white">
                {isLoading ? (
                  <ScaleLoader
                    loading={isLoading}
                    height={12}
                    width={2}
                    color={"#3d5170"}
                  />
                ) : (
                  data?.data.lockPeriodInMonths
                )}{" "}
                Months{" "}
                <span className="text-xs text-clrTextLightGray">
                  (
                  {isLoading ? (
                    <ScaleLoader
                      loading={isLoading}
                      height={12}
                      width={2}
                      color={"#3d5170"}
                    />
                  ) : (
                    data?.data.daysLeft
                  )}{" "}
                  days left)
                </span>
              </p>
            </div>
            <LockDuration />
          </div>
        </div>
      ) : (
        <div className="pt-20">
          <EmptyCard description={"No active reward booster cycle found"} />
        </div>
      )}
      <Link
        to="/home/booster/booster-faq"
        className="flex justify-center mt-12 cursor-pointer align-center"
      >
        <div className="flex items-center w-10/12 md:w-1/2 bg-clrBg2 rounded-[10px] p-2 dark:bg-clrDarkBg2">
          <div className="mr-auto">
            <p className="text-sm font-semibold text-clrTextLightGray dark:text-clrPlaceholder">
              FAQs
            </p>
          </div>
          <ArrowRight />
        </div>
      </Link>
      <div className="flex justify-center mt-16 align-center">
        <div className="w-10/12 md:w-1/2">
          {boosterStatus && !boosterStatus?.data.isEnabled && (
            <Button
              text="Activate Booster"
              type="button"
              handleClick={() => setBoosterOption(1)}
            />
          )}
        </div>
      </div>
      <BoosterFlow
        boosterOption={boosterOption}
        setIsBoosterOption={setBoosterOption}
      />
    </section>
  );
};

export default RewardBooster;
