import { useRef, useState } from 'react';
import TableMain from '../reusables/ReactDataTable';
import { Button, ButtonOutline, EmptyCard, Filter, TableLoader } from '../reusables';
import { connectionsColumn } from './data';
import { useParams } from 'react-router-dom';

import { DeviceOverviewBox } from "./common";
import { MobileFilterSearch } from "../hotspotHubs/OptionsStatus";
// import { calculateDateRange } from "src/helper";
import { useGetDeviceAnalyticsOverviewQuery, useGetLiveSessionAnalyticsQuery, useGetSessionAnalyticsQuery } from '../../features/api/analyticsSlice';
import useOutsideClick from '../../Hooks/useOutsideClick';


const Connections = () => {
  const { analyticId } = useParams();
  const [page, setPage] = useState<number>(1);
  const [option, setOption] = useState<number>(1);
  const [duration, setDuration] = useState<string>("All");
  const [durationOption, toggleDurationOption] = useState<string>("");

  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    toggleDurationOption("");
  };

  useOutsideClick(modalRef, handleCloseModal);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  // const { data: deviceStats, isLoading: gettingDeviceStats } =
  //   useGetDeviceStatsQuery({
  //     hubId: "",
  //     search: searchValue,
  //     duration: "Custom",
  //     startDateTimeStamp: startDateTimeStamp,
  //     endDatetimeStamp: endDatetimeStamp,
  //   });

  const handleDateRangeChange = (range: string) => {
    setDuration(range);
    // const [newStartDate, newEndDate] = calculateDateRange(range);
    setStartDate("");
    setEndDate("");
    // setStartDateTimestamp(newStartDate?.toString() || "");
    // setEndDateTimeStamp(newEndDate?.toString() || "");
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value)
    // const selectedDate = new Date(event.target.value);
    // setStartDateTimestamp((selectedDate.getTime()).toString());
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value)
    // const selectedDate = new Date(event.target.value);
    // setEndDateTimeStamp((selectedDate.getTime()).toString());
  };


  const { data, isLoading, isFetching } = useGetLiveSessionAnalyticsQuery({
    page,
    pageSize: 30,
    search: '',
    deviceId: analyticId || ""
  });

  const { data: sessionsAnalytics, isLoading: gettingSessions, isFetching: fetchingSessions } = useGetSessionAnalyticsQuery({
    page,
    pageSize: 30,
    search: '',
    deviceId: analyticId || ""
  });


  const { data: deviceOverview, isLoading: gettingDeviceOverview } = useGetDeviceAnalyticsOverviewQuery(analyticId || '', { skip: !analyticId });

  const deviceData = [
    {
      title: 'Connected Devices',
      count: deviceOverview?.data?.connectedDeviceCount,
    },
    {
      title: 'Live Connections',
      count: deviceOverview?.data?.liveSessionsCount,
    },
    {
      title: 'Whitelisted Devices',
      count: deviceOverview?.data?.whitelistedDeviceCount,
    },
    {
      title: 'Active Tokens',
      count:
        (deviceOverview?.data?.activeTokensCount?.dataTokens || 0) +
        (deviceOverview?.data?.activeTokensCount?.timedTokens || 0),
    },
  ];

  return (
    <section className="w-[96%] mx-auto pb-6 overflow-y-auto">
      <div className="px-3 py-4 my-4 bg-white rounded-lg dark:bg-clrDarkBg md:px-6 md:pt-6 md:pb-3">
        <div className="mb-5 md:mb-9">
          <h3 className="text-sm font-semibold text-clrGray dark:text-white">
            Overall Connections{' '}
          </h3>
          <div className="grid grid-cols-2 gap-2 py-3 rounded-lg md:pt-6 md:pb-4 xl:grid-cols-4 md:gap-3">
            {deviceData.map((data, index) => (
              <DeviceOverviewBox
                key={index}
                title={data.title}
                count={data.count}
                loading={gettingDeviceOverview}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-wrap min-[320px]:flex-nowrap w-fit items-center gap-3 mb-8">
          <div>
            {option === 1 ?
              <Button
                text="Live Sessions"
                type="button"
                handleClick={() => setOption(1)}
                px="px-6"
                py="py-1"
                bgColor='dark:bg-secondary'
                textColor='dark:text-white'
              />
              :
              <ButtonOutline
                text="Live Sessions"
                type="button"
                handleClick={() => setOption(1)}
                wBtn="w-[127.5px]"
                borderWidth="border"
                py="py-1"
              />}
          </div>
          <div>
            {option === 2 ?
              <Button
                text="All Sessions"
                type="button"
                handleClick={() => setOption(2)}
                px="px-6"
                py="py-1"
                bgColor='dark:bg-secondary'
                textColor='dark:text-white'
              />

              :
              <ButtonOutline
                text="All Sessions"
                type="button"
                handleClick={() => setOption(2)}
                wBtn="w-[127.5px]"
                borderWidth="border"
                py="py-1"
              />
            }
          </div>
        </div>

        <div className="flex gap-2 w-auto mb-4">
          <MobileFilterSearch>
            <Filter
              modalRef={modalRef}
              options={[
                "All",
                "Last Week",
                "Last Month",
                "Last 3 Months",
                "Last Year",
                "Custom",
              ]}
              toggleFilter={toggleDurationOption}
              selectedFilterOption={durationOption}
              currentFilterOption={"DURATION"}
              selectFilterOption={handleDateRangeChange}
              selectedOption={duration}
              label={"Duration"}
            />
            {duration === "Custom" &&
              <>
                <div className="relative">
                  <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel">
                    Start Date
                  </h4>
                  <input
                    type="date"
                    className="w-full md:w-52 text-x10 text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x9 bg-inherit"
                    value={startDate}
                    onChange={(e) => handleStartDateChange(e)}
                  />
                </div>
                <div className="relative">
                  <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel">
                    End Date
                  </h4>
                  <input
                    type="date"
                    className="w-full md:w-52 text-x10 border-[1px] text-clrPlaceholder border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x9 bg-inherit"
                    value={endDate}
                    onChange={(e) => handleEndDateChange(e)}
                  />
                </div>
              </>
            }
          </MobileFilterSearch>
        </div>

        {option === 1 ?
          <div>
            <div>
              {isLoading || isFetching ? (
                <TableLoader />
              ) : (
                <TableMain
                  defaultData={data?.data?.records || []}
                  columns={connectionsColumn}
                  totalPages={data?.data.totalPages || 0}
                  pageSize={data?.data.pageSize || 0}
                  currentPage={data?.data.currentPage || 0}
                  setPage={setPage}
                />
              )}
            </div>
            {!isLoading && !isFetching && (data?.data.records.length || 0) === 0 && (
              <div className="flex flex-col items-center justify-center">
                <EmptyCard description="No result found" />
              </div>
            )}
          </div>
          :
          <div>
            <div>
              {gettingSessions || fetchingSessions ? (
                <TableLoader />
              ) : (
                <TableMain
                  defaultData={sessionsAnalytics?.data?.records || []}
                  columns={connectionsColumn}
                  totalPages={sessionsAnalytics?.data.totalPages || 0}
                  pageSize={sessionsAnalytics?.data.pageSize || 0}
                  currentPage={sessionsAnalytics?.data.currentPage || 0}
                  setPage={setPage}
                />
              )}
            </div>
            {!gettingSessions && !fetchingSessions && (sessionsAnalytics?.data.records.length || 0) === 0 && (
              <div className="flex flex-col items-center justify-center">
                <EmptyCard description="No result found" />
              </div>
            )}
          </div>
        }
      </div>
    </section>
  );
};

export default Connections;
