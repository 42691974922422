import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { CellRenderer } from "./CellRender";
import { numberWithCommas, numberWithCommasWithoutDecimal } from "../../Helper";
import moment from "moment";
import { filesize } from "filesize";
import { ReactComponent as InfoIcon } from "../../assets/icons/information-circle.svg";
import { useToggleAdSupportMutation } from "../../features/api/deviceSlice";

// TODO : Move types to a typescript file

type IUser = {
  balance: number;
  email: string;
  id: string;
  name: string;
  rate: number;
  verificationStatus: string;
};

// My devices/ My Hubs
type Device = {
  agentId: string;
  connectionId: string;
  deviceMode: string;
  status: string;
  id: string;
  isActive: boolean;
  latitude: number;
  longitude: number;
  macAddress: string;
  manager: string;
  managerId: string;
  name: string;
  rate: number;
  totalActiveSessions: number;
  user: IUser;
  isAdsEnabled: boolean;
  totalMiningReward: number;
  device_name?: string;
  amount_earned?: number;
  hotspotHub?: string;
  viewcount? : number;
  device?: any;
  devicePin: string;
  isCaptivePortalEnabled?: boolean;
};

const columnHelper = createColumnHelper<Device>();

export const myDeviceColum = [
  columnHelper.accessor("id", {
    header: "#",
    cell: (row) => {
      return <p>{Number(row?.row.id) + 1}</p>;
    },
  }),
  columnHelper.accessor("name", {
    header: "Hub Name",
  }),
  columnHelper.accessor("deviceMode", {
    header: "Mode",
  }),

  columnHelper.accessor("manager", {
    header: "Hub Manager (Agent)",
    cell: (val) => {
      const manager = val.getValue();
      return <p>{manager ? manager : "N/A"}</p>;
    },
  }),
  columnHelper.accessor("totalMiningReward", {
    header:  ()=> {return(<TotalMiningReward />)},
    cell: (val) => {
      const reward = val.getValue();
      return <p> {numberWithCommas(reward || 0)} WNT</p>;
    },
  }),
  columnHelper.accessor("rate", {
    header: "Billing Rate",
    cell: (val) => {
      const rate = val.getValue();
      return <p> USD {numberWithCommas(rate)} /GB</p>;
    },
  }),
  columnHelper.accessor("isAdsEnabled", {
    header: "Hub Ads",
    cell: (val) => {
      let { id, isAdsEnabled } = val?.row?.original
      return <AdToggle id={id} isAdsEnabled={isAdsEnabled} />
    },
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: (info) => {
      const status = info.getValue();
      let backgroundColor = "";
      let color = "";
      if (status.toLowerCase() === "online") {
        backgroundColor = "#E8FFF3";
        color = "#396";
      } else if (status.toLowerCase() === "offline") {
        backgroundColor = "#FFE4E4";
        color = "#DB5F5F";
      } else if (status.toLowerCase() === "blacklisted") {
        backgroundColor = "#585656";
        color = "#fff";
      }

      return (
        <div
          style={{
            background: backgroundColor,
            color,
            borderRadius: "19px",
            padding: "0.3rem 1rem",
            textAlign: "center",
          }}
          className="w-20 md:w-30 lg:w-full xl:w-[100%]"
        >
          {status}
        </div>
      );
    },
  }),
  columnHelper.accessor("id", {
    header: "",
    cell: (info) => {
      return <CellRenderer name={info?.row?.original?.name} id={info?.row?.original?.id} />
    },
  }),
];

//Live Sessions
type Session = {
  amountReceivedByHost: number;
  amountSpentByClient: number;
  clientEmail: string;
  clientMacAddress: string;
  dataUsedInBytes: number;
  deviceManagerEmail: string;
  deviceManagerName: string;
  deviceName: string;
  sessionDurationInMinutes: number;
  sessionStartDateTimestamp: number;
};

const columnSession = createColumnHelper<Session>();

export const livesessionColumn = [
  columnSession.accessor("deviceName", {
    header: "#",
    cell: (row) => {
      return <p>{Number(row?.row.id) + 1}</p>;
    },
  }),
  columnSession.accessor("deviceName", {
    header: "Hub Name",
  }),

  columnSession.accessor("deviceManagerName", {
    header: "Hub Manager (Agent)",
  }),
  columnSession.accessor("clientEmail", {
    header: "Connected User",
  }),
  columnSession.accessor("sessionDurationInMinutes", {
    header: "Duration",
    cell: (val) => {
      const duration = val.getValue();
      return <p> {numberWithCommas(duration || 0)} Mins</p>;
    },
  }),
  columnSession.accessor("dataUsedInBytes", {
    header: "Data Used",
    cell: (val) => {
      const dataUsed = val.getValue();
      return <p> {filesize(dataUsed || 0)}</p>;
    },
  }),
  columnSession.accessor("amountReceivedByHost", {
    header: "Amount Generated",
  }),
];

// Device Report / Hubs Report
type Report = {
  deviceName: string;
  usersCount: number;
  sessionsCount: string;
  totalUsage: number;
  totalRevenue: number;
  upTimeInHours: number;
  totalMiningReward: number;
};

const columnReport = createColumnHelper<Report>();

export const columnsReport = [
  columnReport.accessor("deviceName", {
    header: "Hub Name",
    cell: (val) => {
      const deviceName = val.getValue();
      return <p> {deviceName ? deviceName : "Unnamed"}</p>;
    },
  }),

  columnReport.accessor("usersCount", {
    header: "Number Of Users",
  }),

  columnReport.accessor("sessionsCount", {
    header: "Sessions",
    cell: (val) => {
      const sessions = val.getValue();
      return <p> {numberWithCommasWithoutDecimal(Number(sessions) || 0)}</p>;
    },
  }),
  columnReport.accessor("upTimeInHours", {
    header: "Uptime",
    cell: (val) => {
      const uptime = val.getValue();
      return <p> {numberWithCommasWithoutDecimal(uptime)} Hrs</p>;
    },
  }),

  columnReport.accessor("totalMiningReward", {
    header: () => {
      return(<MiningReward  />)
    },
    cell: (val) => {
      const miningReward = val.getValue();
      return <p> {numberWithCommasWithoutDecimal(miningReward)} WNT</p>;
    },
  }),

  columnReport.accessor("totalUsage", {
    header: "Data Used",
    cell: (val) => {
      const totalUsage = val.getValue();
      return <p> {filesize(totalUsage || 0)}</p>;
    },
  }),
  columnReport.accessor("totalRevenue", {
    header: () => {
      return(<ResellRevenue  />)
    },
    cell: (val) => {
      const totalRevenue = val.getValue();
      return "USD " + numberWithCommas(totalRevenue || 0);
    },
  }),
];

// Device Tracker
type Tracker = {
  Subscription: string;
  DeviceName: string;
  Value: string;
  Used: string;
  Remaining: string;
  Validity: string;
  ExpiryDate: string;
};

export const defaultDataTracker: Tracker[] = [
  {
    Subscription: "12-07-2021",
    DeviceName: "Polomall_01",
    Value: "200GB",
    Used: "20GB",
    Remaining: "180GB",
    Validity: "180GB",
    ExpiryDate: "12-09-2021",
  },
  {
    Subscription: "12-07-2021",
    DeviceName: "Polomall_01",
    Value: "200GB",
    Used: "20GB",
    Remaining: "180GB",
    Validity: "180GB",
    ExpiryDate: "12-09-2021",
  },
  {
    Subscription: "12-07-2021",
    DeviceName: "Polomall_01",
    Value: "200GB",
    Used: "20GB",
    Remaining: "180GB",
    Validity: "180GB",
    ExpiryDate: "12-09-2021",
  },
  {
    Subscription: "12-07-2021",
    DeviceName: "Polomall_01",
    Value: "200GB",
    Used: "20GB",
    Remaining: "180GB",
    Validity: "180GB",
    ExpiryDate: "12-09-2021",
  },
  {
    Subscription: "12-07-2021",
    DeviceName: "Polomall_01",
    Value: "200GB",
    Used: "20GB",
    Remaining: "180GB",
    Validity: "180GB",
    ExpiryDate: "12-09-2021",
  },
  {
    Subscription: "12-07-2021",
    DeviceName: "Polomall_01",
    Value: "200GB",
    Used: "20GB",
    Remaining: "180GB",
    Validity: "180GB",
    ExpiryDate: "12-09-2021",
  },
];

const columnTracker = createColumnHelper<Tracker>();

export const columnsTracker = [
  columnTracker.accessor("Subscription", {
    header: "Subscription Date",
  }),
  columnTracker.accessor("DeviceName", {
    header: "Hub Name",
  }),
  columnTracker.accessor("Value", {
    header: "Data Value",
  }),

  columnTracker.accessor("Used", {
    header: "Data Used",
  }),
  columnTracker.accessor("Remaining", {
    header: "Data Remaining",
  }),
  columnTracker.accessor("Validity", {
    header: "Validity",
  }),
  columnTracker.accessor("ExpiryDate", {
    header: "Expiry Date",
  }),
];

// Session History
const sessionHistoryColumns = createColumnHelper<Session>();

export const sessionHistoryColumn = [
  sessionHistoryColumns.accessor("sessionStartDateTimestamp", {
    header: "Date",
    cell: (val) => {
      const time = val.getValue();
      return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
    },
  }),
  sessionHistoryColumns.accessor("deviceName", {
    header: "Hubs",
  }),
  sessionHistoryColumns.accessor("clientEmail", {
    header: "User Email",
  }),

  sessionHistoryColumns.accessor("sessionDurationInMinutes", {
    header: "Duration",
    cell: (val) => {
      const duration = val.getValue();
      return <p> {duration} mins</p>;
    },
  }),
  sessionHistoryColumns.accessor("dataUsedInBytes", {
    header: "Data Used",
    cell: (val) => {
      const totalUsage = val.getValue();
      return <p> {filesize(totalUsage || 0)}</p>;
    },
  }),
];

// Crypto
type Crypto = {
  transactionTimeStamp: string;
  transactionType: string;
  reference: string;
  amount: number;
  status: string;
  currencyCode: string;
};

const columnCrypto = createColumnHelper<Crypto>();

export const columnsCrypto = [
  columnCrypto.accessor("transactionTimeStamp", {
    header: "Time",
    cell: (val) => {
      const time = val.getValue();
      return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
    },
  }),
  columnCrypto.accessor("transactionType", {
    header: "Type",
  }),

  columnCrypto.accessor("reference", {
    header: "Reference",
  }),

  columnCrypto.accessor("amount", {
    header: "Amount",
    cell: ({ row }) => {
      return (
        <p>
          {" "}
          {numberWithCommas(row?.original.amount, 2)}{" "}
          {row?.original.currencyCode}
        </p>
      );
    },
  }),
  columnCrypto.accessor("status", {
    header: "Status",
    cell: (info) => {
      const status = info.getValue();

      let color = "";
      let updatedStatus = "";

      if (status === "CONFIRMED") {
        color = "#00C087";
        updatedStatus = "Confirmed";
      } else if (status === "FAILED") {
        color = "#DB5F5F";
        updatedStatus = "Failed";
      } else {
        color = "#E5B910";
        updatedStatus = "Pending";
      }

      return (
        <p
          style={{
            color,
          }}
        >
          {updatedStatus}
        </p>
      );
    },
  }),
];

// P2P
type P2P = {
  transactionTimeStamp: string;
  p2POrderType: string;
  buyCurrency: string;
  sellCurrency: string;
  buyAmount: number;
  sellAmount: number;
  reference: string;
  status: string;
};

const columnP2P = createColumnHelper<P2P>();

export const columnsP2P = [
  columnP2P.accessor("transactionTimeStamp", {
    header: "Time",
    cell: (val) => {
      const time = val.getValue();
      return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
    },
  }),
  columnP2P.accessor("p2POrderType", {
    header: "Type",
  }),

  columnP2P.accessor("buyCurrency", {
    header: "Buy Currency",
  }),
  columnP2P.accessor("sellCurrency", {
    header: "Sell Currency",
  }),
  columnP2P.accessor("buyAmount", {
    header: "Buy Amount",
    cell: (info) => {
      let buyAmount = info.getValue()
      return (
        <p> {numberWithCommas(buyAmount || 0)}</p>

      );
    },
  }),
  columnP2P.accessor("sellAmount", {
    header: "Sell Amount",
    cell: (info) => {
      let sellAmount = info.getValue()
      return (
        <p> {numberWithCommas(sellAmount || 0)}</p>

      );
    },
  }),
  columnP2P.accessor("reference", {
    header: "Reference",
    cell: (val) => {
      const reference = val.getValue();
      return <p> {reference.slice(0, 6) + "xxx"}</p>;
    },
  }),

  columnP2P.accessor("status", {
    header: "Status",
    cell: (info) => {
      const status = info.getValue();

      let color = "";
      let updatedStatus = "";

      if (status === "CONFIRMED") {
        color = "#00C087";
        updatedStatus = "Confirmed";
      } else if (status === "FAILED") {
        color = "#DB5F5F";
        updatedStatus = "Failed";
      } else {
        color = "#E5B910";
        updatedStatus = "Pending";
      }

      return (
        <p
          style={{
            color,
          }}
        >
          {updatedStatus}
        </p>
      );
    },
  }),
];

// Swap
type Swap = {
  transactionTimeStamp: number;
  buyAmount: number;
  buyCurrency: string;
  sellAmount: number;
  sellCurrency: string;
  status: string;
  reference: string;
};

const columnSwap = createColumnHelper<Swap>();

export const columnsSwap = [
  columnSwap.accessor("transactionTimeStamp", {
    header: "Date",
    cell: (val) => {
      const time = val.getValue();
      return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
    },
  }),
  columnSwap.accessor("sellAmount", {
    header: "Amount Sent",
    cell: ({ row }) => {
      return (
        <p> {numberWithCommas(row?.original.sellAmount || 0) + " " + row?.original.sellCurrency}</p>

      );
    },
  }),

  columnSwap.accessor("buyAmount", {
    header: "Amount Received",
    cell: ({ row }) => {
      return (
        <p> {numberWithCommas(row?.original.buyAmount || 0) + " " + row?.original.buyCurrency}</p>
      );
    },
  }),

  columnSwap.accessor("status", {
    header: "Status",
    cell: (info) => {
      const status = info.getValue();

      let color = "";
      let updatedStatus = "";

      if (status === "CONFIRMED") {
        color = "#00C087";
        updatedStatus = "Confirmed";
      } else if (status === "FAILED") {
        color = "#DB5F5F";
        updatedStatus = "Failed";
      } else {
        color = "#E5B910";
        updatedStatus = "Pending";
      }

      return (
        <p
          style={{
            color,
          }}
        >
          {updatedStatus}
        </p>
      );
    },
  }),

  columnSwap.accessor("reference", {
    header: "Reference",
  }),
];

// Transfer
type Transfer = {
  transactionTimeStamp: string;
  transactionType: string;
  from: string;
  to: string;
  amount: number;
  reference: string;
  status: string;
  currency: string

};

const columnTransfer = createColumnHelper<Transfer>();

export const columnsTransfer = [
  columnTransfer.accessor("transactionTimeStamp", {
    header: "Time",
    cell: (val) => {
      const time = val.getValue();
      return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
    },
  }),
  columnTransfer.accessor("transactionType", {
    header: "Type",
  }),
  columnTransfer.accessor("to", {
    header: "Recipient",
  }),
  columnTransfer.accessor("amount", {
    header: "Amount",
    cell: ({ row }) => {
      return (
        <p> {row?.original.currency + " " + numberWithCommas(row?.original.amount)}</p>
      )
    }
  }),
  columnTransfer.accessor("reference", {
    header: "Reference",
    cell: (val) => {
      const reference = val.getValue();
      return <p> {reference.slice(0, 6) + "xxx"}</p>;
    },
  }),
  columnTransfer.accessor("status", {
    header: "Status",
    cell: (info) => {
      const status = info.getValue();

      let color = "";
      let updatedStatus = "";

      if (status === "CONFIRMED") {
        color = "#00C087";
        updatedStatus = "Confirmed";
      } else if (status === "FAILED") {
        color = "#DB5F5F";
        updatedStatus = "Failed";
      } else {
        color = "#E5B910";
        updatedStatus = "Pending";
      }

      return (
        <p
          style={{
            color,
          }}
        >
          {updatedStatus}
        </p>
      );
    },
  }),
];

// Swap
type Stakes = {
  transactionTimeStamp: string;
  action: string;
  type: string;
  amount: number;
};

const columnStakes = createColumnHelper<Stakes>();

export const columnsStakes = [
  columnStakes.accessor("transactionTimeStamp", {
    header: "Date",
    cell: (val) => {
      const time = val.getValue();
      return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
    },
  }),
  columnStakes.accessor("action", {
    header: "Action",
  }),

  columnStakes.accessor("type", {
    header: "Type",
  }),

  columnStakes.accessor("amount", {
    header: "Amount",
    cell: (val) => {
      const amount = val.getValue();
      return <p> {numberWithCommas(amount)}</p>;
    },
  }),
];

// Claim history
type ClaimHistory = {
  createdAt: string;
  walletName: string;
  walletAddress: string;
  amount: number;
  paymentStatus: string;
};

const columnClaimHistory = createColumnHelper<ClaimHistory>();

export const columnsClaimHistory = [
  columnClaimHistory.accessor("createdAt", {
    header: "Date",
    cell: (val) => {
      const time = val.getValue();
      return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
    },
  }),
  columnClaimHistory.accessor("walletName", {
    header: "Wallet",
  }),

  columnClaimHistory.accessor("walletAddress", {
    header: "Address",
  }),

  columnClaimHistory.accessor("amount", {
    header: "Amount Claimed",
    cell: (val) => {
      const amount = val.getValue();
      return (
        <p className="font-medium md:font-normal text-clrGreen2 md:text-inherit">
          {" "}
          {numberWithCommas(amount)}WNT
        </p>
      );
    },
  }),
  columnClaimHistory.accessor("paymentStatus", {
    header: "Status",
    cell: (info) => {
      const status = info?.getValue()?.toLowerCase();
      let color = "";
      if (status === "success") {
        color = "#00C087";
      } else if (status === "failed") {
        color = "#DB5F5F";
      }
      return (
        <p
          style={{
            color,
            fontWeight: "600",
          }}
        >
          {status}
        </p>
      );
    },
  }),
];


const AdToggle = ({ id, isAdsEnabled }: { id: string, isAdsEnabled: boolean }) => {
  const [adStatus, setAdStatus] = useState<boolean>(isAdsEnabled || false);
  const [toggleAdSupport] = useToggleAdSupportMutation()


  const handleToggleAdSupport = () => {
    let data = {
      hubId: id,
      isAdSupportEnabled: !adStatus
    };
    setAdStatus(!adStatus)
    toggleAdSupport(data)
      .unwrap()
      .then((res) => {
        if (!res.success) {
          setAdStatus(false)
        }
      })
      .catch((err) => {
        setAdStatus(false)
      })
  };


  return (
    <div className="flex items-center gap-4">
      <div className="mb-0" > {adStatus ? "Enabled" : "Disabled"}</div>
      <label className="switch">
        <input type="checkbox" checked={adStatus} onChange={handleToggleAdSupport} />
        <span className="slider round"></span>
      </label>
    </div>
  )
}



const MiningReward = () => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  return (
    <p className="flex gap-1 items-center cursor-pointer">
      Mining Reward               
      <span 
        className="relative"
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <InfoIcon
          width={"15px"}
          height={"15px"}
          className="w-[13px] h-[13px] md:w-[15px] md:h-[15px]"
        /> 
        {isTooltipVisible && (
          <div className="md:block absolute top-[-20px] left-0 w-32 bg-black text-white p-2 rounded z-10">
            {/* Add tooltip content */}
            Reward is only available after 7 days.
            NB: If date selected is less than 7 days, mining reward might be 0.
          </div>
        )}
      </span>
    </p>
  );
};

 
const TotalMiningReward = () => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  return (
    <p className="flex gap-1 items-center cursor-pointer">
      Total Mining Reward               
      <span 
        className="relative"
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <InfoIcon
          width={"15px"}
          height={"15px"}
          className="w-[13px] h-[13px] md:w-[15px] md:h-[15px]"
        /> 
        {isTooltipVisible && (
          <div className="md:block absolute top-[-20px] left-0 w-32 bg-black text-white p-2 rounded z-10">
            {/* Add tooltip content */}
            This is the total reward mined by this device
          </div>
        )}
      </span>
    </p>
  );
};

const ResellRevenue = () => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  return (
    <p className="flex gap-1 items-center cursor-pointer">
      Data Resell Revenue (USD)             
      <span 
        className="relative"
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <InfoIcon
          width={"15px"}
          height={"15px"}
          className="w-[13px] h-[13px] md:w-[15px] md:h-[15px]"
        /> 
        {isTooltipVisible && (
          <div className="md:block absolute top-[-20px] left-0 w-32 bg-black text-white p-2 rounded z-10">
            {/* Add tooltip content */}
            This represents the overall revenue generated from the sale of your data, calculated based on the billing rate you've set.
          </div>
        )}
      </span>
    </p>
  );
};


export const adViewsColum = [
  columnHelper.accessor('id', {
    header: 'Date',
    cell: (row) => {
      return <p>{Number(row?.row.id) + 1}</p>;
    },
  }),
  columnHelper.accessor('name', {
    header: 'Hotspot Hub',
  }),
  columnHelper.accessor('deviceMode', {
    header: 'Advert name',
  }),
  columnHelper.accessor('deviceMode', {
    header: 'Amount Earned',
  }),
];

export const adOverviewColumn = [
  columnHelper.accessor('device', {
    header: 'Hotspot Hub',
    cell: (val) => {
      const deviceDetails = val.getValue();
      return deviceDetails.name;
    },
  }),
  columnHelper.accessor('device', {
    header: 'BSSID',
    cell: (val) => {
      const deviceDetails = val.getValue();
      return deviceDetails.bss_id;
    },
  }),
  columnHelper.accessor('viewcount', {
    header: 'View Count',
  }),
];