import { createSlice} from '@reduxjs/toolkit'
import { RootState } from '../../app/store';
import { UserData } from '../api/authSliceTypes'

const initialState : UserData = {
  id: "",
  usage: {
    bought: 0,
    sold: 0,
  },
  verificationStatus: "",
  token: "",
  roles: [],
  jwt: "",
  profileImageUrl: "",
  reward: {
    id: "",
    type: "",
    amount: 0,
    confirmedAt: "",
    jwt: "",
  },
  refCode: "",
  promoBalance: 0,
  balance: 0,
  businessBalance: 0,
  cryptoAssetBalance: 0,
  expires: 0,
  email: "",
  name: "",
  rate: 0,
  connectionId: "",
  businessId: "",
  defaultCurrency: "",
  connectWalletDetails: {
    walletAddress: "",
    walletName: "",
    walletSignature: ""
  },
  appModal: {id: "", detail: ""}
}

const authReducerSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      return action.payload;
    },
    logOut: () =>{
      window.location.replace("/");
      localStorage.clear();
    },
    saveWalletDetails: (state, action) =>{
      localStorage.setItem('walletDetails', JSON.stringify(action.payload));
      state.connectWalletDetails = action.payload
    },
    toggleAppModal: (state, action) =>{
      state.appModal = action.payload
    }
  }
})

export const userAuth = (state: RootState) => state.auth;

export const { setUser, logOut, saveWalletDetails, toggleAppModal } = authReducerSlice.actions

// Export the slice reducer as the default export
export default authReducerSlice.reducer