import React from "react";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-Yellow.svg";

export const Filter: React.FC<DropdownFilterProps> = ({
  options,
  toggleFilter,
  selectedOption,
  modalRef,
  selectFilterOption,
  selectedFilterOption,
  currentFilterOption,
  label,
}) => {
  return (
    <div className="relative mb-4 md:mb-0">
      <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder">
        {label}:
      </h4>
      <button
        onClick={() => toggleFilter(currentFilterOption)}
        className="flex items-center justify-between relative h-8 px-4 w-full md:w-40 border-[1px] border-solid border-clrBorder dark:border-clrGray  rounded-3xl"
      >
        <p className="text-[10px] font-normal text-clrPlaceholder">
          {selectedOption}
        </p>
        <ArrowDown width={20} height={20} />
      </button>
      {selectedFilterOption === currentFilterOption && (
        <DropDownOptions
          list={options}
          modalRef={modalRef}
          closeDropdown={toggleFilter}
          handleSelect={selectFilterOption}
        />
      )}
    </div>
  );
};

//TODO: Replace this with the updated dropdown
//dropdown options
export const DropDownOptions: React.FC<DropDownProps> = ({
  list,
  modalRef,
  handleSelect,
  closeDropdown,
  widthFull,
}) => {
  return (
    <div>
      <div className={`absolute z-30 p-3 pb-4 ${widthFull ? "w-full" : "w-48"} overflow-hidden bg-white rounded dark:bg-clrDarkBg whitespace-nowrap text-ellipsis`}>
        <ul className="space-y-6 ">
          {list.map((each) => {
            return (
              <li
                key={each}
                onClick={() => {
                  handleSelect(each);
                  closeDropdown("");
                }}
                className="text-xs font-normal transition duration-150 cursor-pointer text-clrGray dark:text-clrPlaceholder hover:text-clrYellow dark:hover:text-clrYellow"
              >
                {each}
              </li>
            );
          })}
        </ul>
      </div>
      <div
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
        ref={modalRef}
      ></div>
    </div>
  );
};

export const DropDownContainer: React.FC<DropDownContainerProps> = ({
  list,
  modalRef,
  handleSelect,
  closeDropdown,
}) => {
  return (
    <div>
      <div className="absolute z-30 w-40 p-3 pb-4 overflow-auto bg-white rounded dark:bg-clrDarkBg max-h-40">
        <ul className="space-y-6 text-xs font-normal text-[#6B6B6B] dark:text-clrPlaceholder">
          {list.map((each) => {
            return (
              <li
                key={each.id}
                onClick={() => {
                  handleSelect(each.enum);
                  closeDropdown("");
                }}
                className="cursor-pointer"
              >
                {each.value}
              </li>
            );
          })}
        </ul>
      </div>
      <div
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
        ref={modalRef}
      ></div>
    </div>
  );
};
