import { useState } from "react";
import { useGetDevicesQuery } from "../../features/api/deviceSlice";
import { Button, ErrorMsg, TableLoader } from "../reusables";
import { Modal } from "../reusables/Modal";

interface CheckboxState {
  [id: string]: boolean;
}

export const AssignDevice: React.FC<AssignAgentProps> = ({
  handleModal,
  handleSubmit,
  isLoading,
  errMsg,
}) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<CheckboxState>({
    id: true,
  });

  const { data: allHubs, isLoading: gettingHubs } = useGetDevicesQuery({
    deviceName: "",
    deviceStatus: "",
    page: 1,
    pageSize: 100
  });

  const handleChecked = (id: string) => {
    setIsCheckboxChecked((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleSelectAll = () => {
    setIsCheckboxChecked({});
    if (allHubs) {
      for (let i = 0; i < allHubs?.data?.records.length; i++) {
        handleChecked(allHubs?.data?.records[i].id);
      }
    }
  };

  const handleSave = () => {
    const selectedIds = Object.keys(isCheckboxChecked).filter(
      (id) => isCheckboxChecked[id]
    );
    handleSubmit(selectedIds);
  };

  return (
    <Modal
      close={true}
      title={"Assign new Hubs"}
      description={"Assign new hubs to this user"}
      handleCloseModal={handleModal}
    >
      <section>
        <div className="bg-white dark:bg-clrDarkBg rounded-[20px] pb-5">
          <div className="py-5 flex justify-between border-b-[0.0625rem] border-solid border-clrBorder3 ">
            <h4 className="pl-6 font-semibold text-[#212121] dark:text-white text-xs ">
              Select Hubs
            </h4>
            {allHubs?.data && allHubs?.data?.records?.length > 0 && (
              <button
                className="pr-6 text-xs font-semibold text-clrDarkYellow "
                onClick={() => handleSelectAll()}
              >
                Select all
              </button>
            )}
          </div>
          <div className="px-6 pt-5 space-y-6">
            {gettingHubs ? (
              <TableLoader />
            ) : (
              allHubs?.data?.records?.map((hubs) => (
                <label
                  key={hubs?.id}
                  className="material-checkbox text-[10px] font-normal text-black"
                >
                  {hubs?.name}
                  <input
                    checked={isCheckboxChecked[hubs?.id]}
                    type="checkbox"
                    onChange={() => handleChecked(hubs?.id)}
                  />
                  <span className="checkmark"></span>
                </label>
              ))
            )}
          </div>
          <ErrorMsg errMsg={errMsg} />
        </div>
        {!isLoading && (allHubs?.data?.records?.length ?? 0) > 0 && (
          <div className="w-4/5 mx-auto mt-6 [&>*]:tracking-wider">
            <Button
              type="button"
              text="Save"
              handleClick={handleSave}
              textSize="text-x10"
              loading={isLoading}
              py="py-2"
              fontWeight="font-semibold"
            />
          </div>
        )}
      </section>
    </Modal>
  );
};

export const InviteStaff: React.FC<InviteStaffProps> = ({
  handleModal,
  isLoading,
  errMsg,
  handleSubmit,
}) => {
  const [agentEmail, setAgentEmail] = useState<string>("");

  return (
    <Modal
      close={true}
      handleCloseModal={handleModal}
      title={"Create Agent"}
      description={
        "Send an email invitation to a new admin to enable them sign up"
      }
    >
      <div className="">
        <div className="p-6 bg-white rounded dark:bg-clrDarkBg">
          <div>
            <label
              htmlFor="email"
              className="block font-semibold text-black dark:text-white text-x10"
            >
              Email Address
            </label>
            <input
              type="text"
              id="email"
              onChange={(e) => setAgentEmail(e.target.value)}
              value={agentEmail}
              placeholder="example@useremail.com"
              className="w-full dark:bg-transparent font-semibold text-x10 text-clrGray43 placeholder:text-x8 dark:placeholder:!text-clrPlaceholder placeholder:font-medium placeholder:!text-[#cecece] opacity-80 border-b-[0.7px] border-solid border-clrBorder5 outline-none"
            />
            <p className="mt-1 font-medium text-[#707070] dark:text-clrPlaceholder text-x9">
              Email address of the new agent
            </p>
          </div>
          <ErrorMsg errMsg={errMsg} />
        </div>
        <div className="w-4/5 mx-auto mt-8 [&>*]:tracking-wider">
          <Button
            type="button"
            text="Send Invite"
            loading={isLoading}
            handleClick={() => handleSubmit({ email: agentEmail })}
            textSize="text-x10"
            py="py-2.5"
            fontWeight="font-semibold"
          />
        </div>
      </div>
    </Modal>
  );
};

export const DeleteModal: React.FC<DeleteModalProps> = ({
  handleModal,
  title,
  description,
  message,
  handleSubmit,
  isLoading,
  errMsg,
}) => {
  return (
    <Modal
      close={true}
      handleCloseModal={handleModal}
      title={title}
      description={description}
    >
      <div className="">
        <div className="p-6 bg-white dark:bg-clrDarkBg rounded">
          <h3 className="mb-8 text-xs font-semibold dark:font-medium text-center text-clrTextBlue dark:text-white w-[50%] mx-auto">
            {message}
          </h3>

          <ErrorMsg errMsg={errMsg} />
        </div>
        <div className="w-4/5 mx-auto mt-8 [&>*]:tracking-wider">
          <Button
            type="button"
            text={title}
            loading={isLoading}
            handleClick={handleSubmit}
            textSize="text-x10"
            py="py-2.5"
            fontWeight="font-semibold"
          />
        </div>
      </div>
    </Modal>
  );
};
