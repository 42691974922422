import TokenHistory from "./TokenHistory";

const Token = () => {
  return (
    <section className="w-[96%] mx-auto pb-6 mt-3 overflow-y-auto">
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:py-8">
        <div className=" md:pl-4">
          <h2 className="mb-5 text-xs font-semibold md:mb-6 md:text-sm text-clrTextGray dark:text-white">
            Generated Tokens
          </h2>
        </div>
        <TokenHistory />
      </div>
    </section>
  );
};

export default Token;
