import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { numberWithCommas } from "../../Helper";


type Referrals = {
  createdAt: string;
  name: string;
  email: string;
  bonus: number;
  status: string;
  isClaimed: boolean;
};

const referral = createColumnHelper<Referrals>();

export const referralColumn = [
  referral.accessor("createdAt", {
    header: "#",
    cell: (row) => {
      return <p>{Number(row?.row.id) + 1}</p>;
    },

  }),
  referral.accessor("createdAt", {
    header: "Date",
    cell: (val) => {
      const time = val.getValue();
      return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
    },
  }),
  referral.accessor("name", {
    header: "Referee Name",
  }),

  referral.accessor("email", {
    header: "Referee Email",
  }),

  referral.accessor("bonus", {
    header: "Account Earned",
    cell: (val) => {
      const bonus = val.getValue();
      return <p> {numberWithCommas(bonus)}</p>;
    },
  }),

  referral.accessor("isClaimed", {
    header: "Claimed",
    cell: (info) => {
      const status = info.getValue();

      let color = "";
      let updatedStatus = "";

      if (status === true) {
        color = "#00C087";
        updatedStatus = "Yes";
      } else {
        color = "#E5B910";
        updatedStatus = "No";
      }

      return (
        <p
          style={{
            color,
          }}
        >
          {updatedStatus}
        </p>
      );
    },
  }),

];
