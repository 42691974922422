import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../app/hooks";
import { ReactComponent as RewardBoosterIcon } from "../../assets/icons/wallet/reward-booster.svg";
import ChevronUpIcon from "../../assets/icons/wallet/chevron-double-up.svg";
import { Button, ButtonOutlineWallet } from "../reusables";
import Graph from "../reusables/Graph";
import { ClaimSummary, ExplorerSuccess } from "./ExplorerModals";
import { useGetBoosterStatusQuery } from "../../features/api/rewardSlice";
import {
  useGetWalletOverviewQuery,
  useClaimRewardsMutation,
  useShowUserOnboardingQuery,
  useLazyGetHolderIdQuery,
  useGetMarketQuoteQuery,
} from "../../features/api/explorerSlice";
import Joyride, { Step } from "react-joyride";
import { Onboarding } from "./Onboarding";
import BoosterFlow from "../booster/BoosterFlow";
import {
  numberWithCommas,
  numberWithCommasWithoutDecimal,
  withdrawablereward,
  withdrawClaims,
} from "../../Helper";
import moment, { duration } from "moment";
import { ScaleLoader } from "react-spinners";
import { ExplorerChooseWallet } from "./ChooseWallet";

const Overview = () => {
  const { id: userId, connectWalletDetails } = useAppSelector(
    (state) => state.auth
  );

  //Check if user should see onboarding screen
  const {
    data: onboarduser,
    isLoading: gettingOnboardingStatus,
    refetch: updateOnboardStatus,
  } = useShowUserOnboardingQuery();

  //Set duration
  const [periodInDays, setPeriodInDays] = useState<string>("");

  //Get the explorer overview
  const { data: explorerData, isLoading: gettingWalletOverview } =
    useGetWalletOverviewQuery({
      address: "",
      period: "",
      lookbackperiodIndays: periodInDays,
    });

  //Set the step for the booster options
  const [boosterOption, setBoosterOption] = useState<number>(0);

  //Save the labels and values for the graph
  const [labels, setGraphLabels] = useState<string[]>([]);

  const [graphData, setGraphValues] = useState<number[]>([]);

  //Get withdrawable balance
  const [withdrawableBalance, setWithdrawableBalance] = useState<number>(0);

  //Set the step for claiming WNT
  const [claimStep, setClaimStep] = useState<number>(0);

  //Check if user has a reward booster active
  const { data: boosterStatus } = useGetBoosterStatusQuery(userId);

  //Claim Reward
  const [claimReward, { isLoading: claimingReward }] =
    useClaimRewardsMutation();

  //Set the step for claiming WNT
  const [claimError, setClaimError] = useState<string>("");

  //Get holderId
  const [trigger] = useLazyGetHolderIdQuery();

  const [claimingWNT, setClaiming] = useState(false);

  const [isJoyrideActive, setIsJoyrideActive] = useState(false);

  // Function to start the tooltip tour
  const startJoyride = () => {
    updateOnboardStatus();
    setIsJoyrideActive(true);
  };

  //Get market quote
  const { data: marketQuote } = useGetMarketQuoteQuery();

  //control opening and closing of the wallet
  const [chooseWallet, setChooseWallet] = useState(false);

  const [{ steps }, setState] = useState<{
    // run: boolean;
    steps: Step[]; // Define the type for the steps array
  }>({
    // run: true,
    steps: [
      {
        content: (
          <p className="text-left text-x10">
            A brief overview of Wicrypt Network Tokens earned by your hubs
          </p>
        ),
        placement: "bottom-start",
        target: "#step-1",
        title: (
          <h3 className="text-sm text-left px-[10px]">Total WNT Balance</h3>
        ),
      },
      {
        content: (
          <p className="text-left text-x10">
            Lock up your weekly rewards to maximize interest over time.
          </p>
        ),
        placement: "bottom",
        target: "#step-2",
        title: <h3 className="text-sm text-left px-[10px]">Boost Reward</h3>,
      },
      {
        content: (
          <p className="text-left text-x10">
            Claim your earned rewards directly to your existing wallet
          </p>
        ),
        placement: "bottom",
        target: "#step-3",
        title: <h3 className="text-sm text-left px-[10px]">Claim Rewards</h3>,
      },
      {
        content: (
          <p className="text-left text-x10">
            A real time view of your earnings
          </p>
        ),
        placement: "bottom",
        target: "#step-4",
        title: <h3 className="text-sm text-left px-[10px]">Earnings</h3>,
      },
      {
        content: (
          <p className="text-left text-x10">
            An overview of total connections on your hubs
          </p>
        ),
        placement: "top",
        target: "#step-5",
        title: <h3 className="text-sm text-left px-[10px]">Hotspot Hubs</h3>,
      },
      {
        content: (
          <p className="text-left text-x10">
            Have a brief overview of total connection your hubs
          </p>
        ),
        placement: "top",
        target: "#step-6",
        title: <h3 className="text-sm text-left px-[10px]">Connections</h3>,
      },
    ],
  });

  const customStyles = {
    options: {
      arrowColor: "#25346A",
      primaryColor: "black",
      color: "white",
      width: 250,
      zIndex: 1000,
      borderRadius: "20px",
      backgroundColor: "#25346A",
    },
    buttonNext: {
      backgroundColor: "white",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#25346A",
      color: "#25346A",
      borderRadius: "40px",
      fontSize: "0.625rem",
      padding: "4px 8px",
    },
    buttonBack: {
      color: "white",
      borderRadius: "40px",
      fontSize: "0.625rem",
    },
    spotlight: {
      backgroundColor: "transparent",
    },
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (explorerData) {
      let dates: string[] = [];
      let pointvalues: number[] = [];
      if (explorerData?.data?.earning?.earningChartPoints?.length !== 0) {
        let data = explorerData?.data?.earning?.earningChartPoints;
        data?.map((each) => {
          dates.push(moment(each.date).format("MMMM Do YYYY"));
          pointvalues.push(each.amount);
        });
      }
      setGraphLabels(dates);
      setGraphValues(pointvalues);
    }
  }, [explorerData]);

  const handleClaimReward = () => {
    setClaimError("");
    let data = {
      walletAddress: connectWalletDetails?.walletAddress,
      walletName: connectWalletDetails?.walletName,
      toInternalWallet: true,
    };
    claimReward(data)
      .unwrap()
      .then((res) => {
        if (res.success) {
          setClaimStep(2);
        } else {
          setClaimError(res.message);
        }
      })
      .catch((err) => {
        setClaimError(err.data.message);
      });
  };

  const handleWithdrawal = async () => {
    setClaiming(true);
    let claim = await withdrawClaims(connectWalletDetails?.walletAddress);
    if (claim) {
      setClaiming(false);
      trigger()
        .unwrap()
        .then((res) => {
          if (res.success) {
            let rewardFunction = withdrawablereward(res?.data?.holderId);
            rewardFunction().then((reward) => {
              setWithdrawableBalance(reward);
            });
          }
        });
    }
  };

  useEffect(() => {
    if (connectWalletDetails?.walletAddress) {
      trigger()
        .unwrap()
        .then((res) => {
          if (res.success) {
            let rewardFunction = withdrawablereward(res?.data?.holderId);
            rewardFunction().then((reward) => {
              setWithdrawableBalance(reward);
            });
          }
        });
    }
  }, [connectWalletDetails?.walletAddress]);

  return (
    <>
      <Joyride
        continuous
        callback={() => {}}
        run={isJoyrideActive}
        steps={steps}
        hideCloseButton
        scrollToFirstStep
        styles={customStyles}
        // tooltipComponent={TourTooltip}
        // stepComponent={CustomStep}
      />
      {!gettingOnboardingStatus && onboarduser?.data?.showIntroductionPage && (
        <Onboarding handleGetStarted={startJoyride} />
      )}
      <section className="w-[96%] mx-auto pt-3 pb-6 h-full overflow-y-auto">
        <div className="grid grid-cols-2 gap-3 px-6 pt-8 pb-20 mb-2 bg-white rounded-lg dark:bg-clrDarkBg xl:px-12 md:grid-cols-2 md:gap-7">
          <div className="flex flex-col gap-2 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-3 md:p-4 shadow-wxl h-fit md:h-[8.758rem] col-start-1 col-end-3 md:col-auto">
            <p className="font-semibold text-x11 text-clrGray66 dark:text-clrPlaceholder">
              Total Mining Balance
            </p>
            <div className="flex items-center justify-between">
              <span
                id="step-1"
                className="text-base font-bold md:text-lg text-clrTextGray dark:text-white"
              >
                {gettingWalletOverview ? (
                  <ScaleLoader
                    loading={gettingWalletOverview}
                    height={12}
                    width={2}
                    color={"#3d5170"}
                  />
                ) : (
                  numberWithCommas(explorerData?.data.balance.wntBalance || 0) +
                  "WNT"
                )}
              </span>

              {boosterStatus?.data.isEnabled ? (
                <button className="flex gap-2 px-2 py-0.5 rounded-lg items-center bg-[#00c0870d] hover:scale-105">
                  <div className="explorer-icon">
                    <RewardBoosterIcon />
                  </div>
                  <p className="italic font-normal text-[7px] text-clrGreen2">
                    Reward Booster: Active
                  </p>
                </button>
              ) : (
                <button className="flex gap-2 px-2 py-0.5 rounded-lg items-center bg-[#ff1e1e08] hover:scale-105">
                  <div className="explorer-icon red">
                    <RewardBoosterIcon />
                  </div>
                  <p className="italic font-normal text-[7px] text-clrRed3">
                    Reward Booster: Inactive
                  </p>
                </button>
              )}
            </div>

            <p className="text-xs font-normal md:text-sm text-[#9c9c9c]">
              {gettingWalletOverview ? (
                <ScaleLoader
                  loading={gettingWalletOverview}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                "$" +
                numberWithCommas(explorerData?.data.balance.dollarValue || 0)
              )}
            </p>
            <div className="flex self-end gap-2">
              {boosterStatus?.data.isEnabled ? (
                <Button
                  handleClick={() => navigate("/home/booster")}
                  text="View Boost Details"
                  type="button"
                  wBtn="w-fit"
                  fontWeight="font-normal"
                  py="py-0.5"
                  textSize="text-x9"
                  id="step-2"
                />
              ) : (
                <Button
                  handleClick={() => setBoosterOption(1)}
                  text="Boost rewards"
                  type="button"
                  wBtn="w-fit"
                  fontWeight="font-normal"
                  py="py-0.5"
                  textSize="text-x9"
                  id="step-2"
                />
              )}
              {(explorerData?.data?.balance?.wntBalance as number) > 0 && (
                <ButtonOutlineWallet
                  handleClick={() => setClaimStep(1)}
                  text="Claim Reward"
                  type="button"
                  textSize="text-x9"
                  id="step-3"
                />
              )}
            </div>
          </div>
          {/* <div className="flex flex-col gap-2 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-3 md:p-4 shadow-wxl h-fit md:h-[8.758rem] col-start-1 col-end-3 md:col-auto">
            <p className="font-semibold text-x11 text-clrGray66 dark:text-clrPlaceholder">
              Claimed Rewards
            </p>
            <div className="flex items-center justify-between">
              <p
                id="step-1"
                className="text-base font-bold md:text-lg text-clrTextGray dark:text-white"
              >
                {!connectWalletDetails?.walletAddress
                  ? "---"
                  : numberWithCommas(withdrawableBalance || 0) + "WNT"}
              </p>
            </div>

            <p className="text-xs font-normal md:text-sm text-[#9c9c9c]">
              {gettingWalletOverview
                ? "---"
                : "$" +
                  numberWithCommas(
                    withdrawableBalance * Number(marketQuote?.data.usdQuote) ||
                      0
                  )}
            </p>
            <div className="flex self-end gap-2">
              {connectWalletDetails?.walletAddress ? (
                withdrawableBalance > 0 && (
                  <ButtonOutlineWallet
                    handleClick={handleWithdrawal}
                    text="Withdraw Balance"
                    type="button"
                    textSize="text-x9"
                    id="step-3"
                    loading={claimingWNT}
                  />
                )
              ) : (
                <ButtonOutlineWallet
                  handleClick={() => setChooseWallet(true)}
                  text="Connect Wallet"
                  type="button"
                  textSize="text-x9"
                  id="step-3"
                />
              )}
            </div>
          </div> */}
          <div className="flex flex-col gap-2 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] shadow-wxl pt-3 md:pt-4 h-fit md:h-[8.758rem] col-start-1 col-end-3 md:col-auto">
            <div className="flex items-start justify-between px-3 md:px-4">
              <div>
                <p
                  id="step-4"
                  className="font-semibold text-x11 text-clrGray66 dark:text-clrPlaceholder"
                >
                  Earnings
                </p>
                <p className="text-base font-bold md:text-lg text-clrTextGray dark:text-white">
                  {gettingWalletOverview ? (
                    <ScaleLoader
                      loading={gettingWalletOverview}
                      height={12}
                      width={2}
                      color={"#3d5170"}
                    />
                  ) : (
                    "$" +
                    numberWithCommas(
                      explorerData?.data?.earning?.totalEarningsUsd || 0
                    )
                  )}
                </p>
              </div>
              <div className="flex items-start h-auto gap-2">
                <button className={`rounded px-1 py-0.5 ${periodInDays === "1" ? "bg-clrYellow2" : "bg-white"}`} onClick={()=> setPeriodInDays("1")}>
                  <p className="font-normal text-x8 text-clrTextBlue">24H</p>
                </button>
                <button className={`rounded px-1 py-0.5 ${periodInDays === "7" ? "bg-clrYellow2" : "bg-white"}`} onClick={()=> setPeriodInDays("7")}>
                  <p className="text-x8 font-normal text-[#2E2E2E]">7D</p>
                </button>
                <button className={`rounded px-1 py-0.5 ${periodInDays === "30" ? "bg-clrYellow2" : "bg-white"}`} onClick={()=> setPeriodInDays("30")}>
                  <p className="text-x8 font-normal text-[#2E2E2E]">30D</p>
                </button>
                <button className={`rounded px-1 py-0.5 ${periodInDays === "" ? "bg-clrYellow2" : "bg-white"}`} onClick={()=> setPeriodInDays("")}>
                  <p className="font-normal text-x8 text-clrTextBlue">All</p>
                </button>
              </div>
            </div>
            <div>
              <Graph
                labels={labels}
                graphData={graphData}
                height={64}
                width={500}
                showXAxis={false}
                showYAxis={false}
                lineTension={0.1}
                borderColor="#25346A"
                bgColor1="rgba(37, 52, 106, 0.20)"
                bgColor2="rgba(196, 196, 196, 0.00)"
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-3 md:p-4 shadow-wxl h-fit md:h-[8.758rem]">
            <p className="font-semibold text-x11 text-clrGray66 dark:text-clrPlaceholder">
              Connected Sessions
            </p>
            <p
              id="step-6"
              className="text-base font-bold md:text-lg text-clrTextGray dark:text-white"
            >
              {gettingWalletOverview ? (
                <ScaleLoader
                  loading={gettingWalletOverview}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                numberWithCommasWithoutDecimal(
                  explorerData?.data?.connections || 0
                )
              )}
            </p>
            <div className="flex items-center gap-1">
              <div className="bg-[#5FBE91] flex items-center justify-center rounded-sm p-0.5">
                <img src={ChevronUpIcon} alt="Increase" width={9} height={9} />
              </div>
              <p className="font-normal text-x11 text-clrGreen2 ">
                {gettingWalletOverview ? (
                  <ScaleLoader
                    loading={gettingWalletOverview}
                    height={12}
                    width={2}
                    color={"#3d5170"}
                  />
                ) : (
                  explorerData?.data?.connectionPercentageChange
                )}{" "}
                {"%"}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-2 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-3 md:p-4 shadow-wxl h-24 md:h-[8.758rem] ">
            <p className="font-semibold text-x11 text-clrGray66 dark:text-clrPlaceholder">
              Hotspot Hubs
            </p>
            <p
              id="step-5"
              className="text-base font-bold md:text-lg text-clrTextGray dark:text-white"
            >
              {gettingWalletOverview ? (
                <ScaleLoader
                  loading={gettingWalletOverview}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                explorerData?.data.deviceCount || 0
              )}
            </p>
          </div>
        </div>
        <BoosterFlow
          boosterOption={boosterOption}
          setIsBoosterOption={setBoosterOption}
        />
        {claimStep === 1 && (
          <ClaimSummary
            handleModal={() => setClaimStep(0)}
            claimWNT={handleClaimReward}
            wntBalance={explorerData?.data.balance.wntBalance as number}
            claimingReward={claimingReward}
            errMsg={claimError}
          />
        )}
        {claimStep === 2 && (
          <ExplorerSuccess
            handleModal={() => {
              navigate("/home/explorer/claim-history");
            }}
            handleCloseModal={() => setClaimStep(0)}
            btnText="Close"
            btnText2="View Claim History"
            title="Request Submitted"
            description="You can close or view to see your history"
          />
        )}
        {chooseWallet && (
          <ExplorerChooseWallet
            handleCloseModal={() => setChooseWallet(false)}
          />
        )}
      </section>
    </>
  );
};

export default Overview;
