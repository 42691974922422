import { NavLink, Outlet, useLocation } from "react-router-dom";

interface SecondaryNavProps {
  paths: { path: string; title: string }[];
}

export const SecondaryNav: React.FC<SecondaryNavProps> = ({ paths }) => {
  const location = useLocation();
  return (
    <section className="grid grid-rows-[41.33px_1fr] h-full">
      <div className="">
        <ul className="relative flex gap-7 px-6 py-3 text-xs font-medium bg-white dark:bg-clrDarkBg border-[1px] border-solid dark:border-none md:justify-start md:px-16 md:gap-14 active:text-clrTextBlue dark:active:text-clrTextBlue border-clrBorder2 dark:border-clrGray">
          {paths.map((each) => {
            return (
              <li className="relative" key={each.path}>
                <NavLink
                  to={each.path}
                  className={
                    location.pathname === each.path
                      ? "text-clrTextBlue dark:text-clrDarkYellow font-semibold after:content[' '] after:absolute after:w-12 after:h-[0.185rem] after:bg-clrTextBlue dark:after:bg-clrDarkYellow after:rounded after:bottom-[-0.875rem] after:left-1/2 after:-translate-x-1/2 after:transition-all after:duration-300"
                      : "text-clrGray dark:text-clrPlaceholder"
                  }
                >
                  {each.title}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="overflow-y-auto">
        <Outlet />
      </div>
    </section>
  );
};

export default SecondaryNav;
