import MintBag from "../../assets/icons/hotspot-hubs/bag.svg";
import { useRef, useState } from "react";
import { EmptyTable, MobileFilterSearch } from "./OptionsStatus";
import useOutsideClick from "../../Hooks/useOutsideClick";
import TableMain from "../reusables/ReactDataTable";
import { myDeviceColum } from "./ReactTableDataList";
import { Filter, TableLoader } from "../reusables";
import {
  useGetDevicesQuery,
  useGetDeviceSummaryQuery,
} from "../../features/api/deviceSlice";
import { ScaleLoader } from "react-spinners";

import { ReactComponent as DeviceMain } from "../../assets/icons/hotspot-hubs/device-with-bg.svg";
import { ReactComponent as OnlineIcon } from "../../assets/icons/hotspot-hubs/online-with-bg.svg";
import { ReactComponent as OfflineIcon } from "../../assets/icons/hotspot-hubs/offline-with-bg.svg";
import { ReactComponent as BlockedIcon } from "../../assets/icons/hotspot-hubs/blocked-with-bg.svg";

//TODO: Filter by status
//PS: Need Backend to add that option

//Component that handles showing all a user's devices
const MyHubs = () => {
  const [option, toggleOption] = useState<string>("");
  const [filterStatus, setFilterStatus] = useState<string>("All");
  const [deviceToSearch, setDeviceSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    toggleOption("");
  };

  useOutsideClick(modalRef, handleCloseModal);

  const { data, isLoading, isFetching } = useGetDevicesQuery({
    deviceName: deviceToSearch,
    deviceStatus: filterStatus,
    page: page,
    pageSize: 30,
  });
  const { data: deviceSummary, isLoading: loadingSummary } =
    useGetDeviceSummaryQuery();

  return (
    <section className="w-[96%] mx-auto pb-6 overflow-y-auto">
      <div className="grid grid-cols-2 gap-2 px-3 py-8 my-2 bg-white rounded-lg dark:bg-clrDarkBg xl:grid-cols-4 md:gap-4">
        <div className="flex items-center gap-2 md:gap-4 bg-white dark:bg-clrDarkBg dark:border-[#C4C4C44D] rounded-[10px] p-3  border-[1px] border-solid border-">
          <DeviceMain width={35} height={35} />
          <div className="mr-auto">
            <p className="text-xs font-semibold text-clrTextLightGray dark:text-clrPlaceholder">
              Total Hubs
            </p>
            <p className="mt-4 text-xl font-semibold md:text-2xl text-clrTextGray dark:text-white">
              {loadingSummary ? (
                <ScaleLoader
                  loading={isLoading}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                deviceSummary?.data?.totalHubs
              )}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2 md:gap-4 bg-white dark:bg-clrDarkBg dark:border-[#C4C4C44D] rounded-[10px] p-3  border-[1px] border-solid border-">
          <OnlineIcon width={35} height={35} />
          <div className="mr-auto">
            <p className="text-xs font-semibold text-clrTextLightGray dark:text-clrPlaceholder">
              Online Hubs
            </p>
            <p className="mt-4 text-xl font-semibold md:text-2xl text-clrTextGray dark:text-white">
              {loadingSummary ? (
                <ScaleLoader
                  loading={isLoading}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                deviceSummary?.data?.onlineHubs
              )}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2 md:gap-4 bg-white dark:bg-clrDarkBg dark:border-[#C4C4C44D] rounded-[10px] p-3  border-[1px] border-solid border-">
          <OfflineIcon width={35} height={35} />
          <div className="mr-auto">
            <p className="text-xs font-semibold text-clrTextLightGray dark:text-clrPlaceholder">
              Offline Hubs
            </p>
            <p className="mt-4 text-xl font-semibold md:text-2xl text-clrTextGray dark:text-white">
              {loadingSummary ? (
                <ScaleLoader
                  loading={isLoading}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                deviceSummary?.data?.offlineHubs
              )}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2 md:gap-4 bg-white dark:bg-clrDarkBg dark:border-[#C4C4C44D] rounded-[10px] p-3  border-[1px] border-solid border-">
          <BlockedIcon width={35} height={35} />
          <div className="mr-auto">
            <p className="text-xs font-semibold text-clrTextLightGray dark:text-clrPlaceholder">
              Blocked Hubs
            </p>
            <p className="mt-4 text-xl font-semibold md:text-2xl text-clrTextGray dark:text-white">
              {loadingSummary ? (
                <ScaleLoader
                  loading={isLoading}
                  height={12}
                  width={2}
                  color={"#3d5170"}
                />
              ) : (
                deviceSummary?.data?.blacklistedHubs
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-6">
        <div className="flex flex-wrap items-end gap-3 mb-8 md:flex-nowrap">
          <div className="flex w-full gap-3 md:w-auto">
            <MobileFilterSearch>
              <Filter
                options={["All", "Online", "Offline", "Blacklisted"]}
                toggleFilter={toggleOption}
                selectedFilterOption={option}
                currentFilterOption={"STATUS"}
                selectFilterOption={setFilterStatus}
                selectedOption={filterStatus}
                modalRef={modalRef}
                label={"Status"}
              />
            </MobileFilterSearch>
            <div className="flex-1 md:flex-auto ">
              <label
                htmlFor=""
                className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
              >
                Search
              </label>
              <input
                type="text"
                name="Hub Name"
                onChange={(e) => setDeviceSearch(e.target.value)}
                value={deviceToSearch}
                placeholder="Hub Name"
                className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
              />
            </div>
          </div>
          <a
            href="https://nft.wicrypt.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-between items-center w-fit h-8 mt-3 px-4 gap-3 bg-clrDarkYellow rounded-[28px] transition duration-500 ease-in-out transform hover:bg-[#e3be27] hover:scale-[0.95]"
          >
            <img src={MintBag} alt="Mint Hub" width={18} height={18} />
            <p className="text-xs font-medium text-white">{"Mint Hub"}</p>
          </a>
        </div>
        <div>
          {isLoading ? (
            <TableLoader />
          ) : (
            <TableMain
              defaultData={data?.data?.records || []}
              columns={myDeviceColum}
              totalPages={data?.data.totalPages || 0}
              pageSize={data?.data.pageSize || 0}
              currentPage={data?.data.currentPage || 0}
              setPage={setPage}
            />
          )}
        </div>
        {!isLoading && !isFetching && !data?.data?.records?.length && (
          <EmptyTable />
        )}
      </div>
    </section>
  );
};

export default MyHubs;
